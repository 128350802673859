import { Component, OnInit } from '@angular/core';
import { Survey } from '../Models/survey';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { SurveyDataService } from '../Services/SurveyDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { StateDataService } from '../Services/StateDataService';
import { State } from '../Models/state';
import {MatDatepickerModule, MatInputModule,MatNativeDateModule} from '@angular/material';
import { CalendarModule } from 'primeng/calendar';
import { IDropdownSettings  } from 'ng-multiselect-dropdown';

@Component({
  selector: 'survey-manage',
  templateUrl: 'survey-manage.component.html',
  })

export class SurveyManageComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  selectStates : any[];
  selectedstateIds:string;
  count :number;
  dropdownSettings:IDropdownSettings;
  survey: Survey;
  title: string;
  public states: State[];
  id:number;
  SurveyId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  surveyStDate = new Date();
  surveyEnDate = new Date();
  surveyDatestring :string;  
  userName:number;
  userType:string;
  surveyTypeddl : any[];
  dynamicTypeddl : any[];
  dynamicType:string;
  


  constructor(public bsModalRef: BsModalRef, 
    public stateDataService : StateDataService,
    public surveyDataService : SurveyDataService,
    public calendarModule : CalendarModule,
    ){
      //this.loadstates();
      this.fillUserType();
    //  this.fillDynamicType();
     }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();
   // this.loadstates();
    if(this.SurveyId == 0)
    {
      this.survey = new Survey();
      this.survey.SurveyName = "";
      this.survey.StateIds="";
      this.survey.SurveyType="0";
      this.selectedItems = [];
    }
    else{
      this.fillSurveyById();
     
    }
  }
  
  /*fillDynamicType()
  {
    this.dynamicTypeddl = [];
    
    let dynamicType = JSON.parse(localStorage.getItem('dynamicType'));
    
  }*/
  fillUserType()
  {
    this.surveyTypeddl = [];
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;

    if(this.userType=="U")
    {
      this.surveyTypeddl.push({'Type': "U",'TypeName' :"Urban"});
    }
    if(this.userType=="R")
    {
      this.surveyTypeddl.push({'Type': "R",'TypeName' :"Rural"});
    }
    if(this.userType=="RO")
    {
      this.surveyTypeddl.push({'Type': "RO",'TypeName' :"Others"});
    }
    if(this.userType=="B")
    {
      this.surveyTypeddl.push({'Type': "U",'TypeName' :"Urban"});
      this.surveyTypeddl.push({'Type': "R",'TypeName' :"Rural"});
      this.surveyTypeddl.push({'Type': "RO",'TypeName' :"Others"});
    }
    

  }

  Clear() : void{
    this.message = "";
    this.onClose = new Subject();
    
      this.survey = new Survey();
      this.survey.SurveyName = "";
      this.survey.StateIds="";
      this.survey.SurveyType="0";
      this.selectedItems = [];
      this.survey.StartDate =new Date();
      this.survey.EndDate =new Date();
      this.surveyStDate  = new Date(this.survey.StartDate);
      this.surveyEnDate  = new Date(this.survey.EndDate);  
  }

  fillSurveyById() {
    this.isLoading = true;
    this.survey = new Survey();
    this.surveyStDate = new Date();
    this.surveyEnDate = new Date();
    this.survey.StateIds="";
   // this.selectedItems = [];
    this.surveyDataService.getSurveyById(this.SurveyId).subscribe(res => {
      this.survey = res;  

     
      this.surveyStDate  = new Date(this.survey.StartDate);
      this.surveyEnDate  = new Date(this.survey.EndDate);   
      this.isLoading = false;
    }
    
    );

      /*this.surveyDataService.getSurveyStates(this.SurveyId).subscribe(res => {
      this.selectStates = res;  
      this.selectedItems = [];
      for(let result of this.selectStates){
         this.selectedItems.push({'StateId' :result.StateId,'StateName':result.StateName});  
       }
      this.isLoading = false;


    });*/

  }
  
  /*onItemSelect(item: any) {
   console.log(this.selectedItems);    
  }

  onSelectAll(items: any) {
    this.selectedItems =[];
    this.selectedItems=items;
  }*/

  /*loadstates(): void {
    this.isLoading = true;
    this.states = [];
     this.stateDataService.getStates().subscribe(res => {
      this.states = res;
      this.isLoading = false;      
      this.states.sort(function (a, b) {
        var A = a.StateName.toUpperCase();
        var B = b.StateName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }        
      });
      //this.dropdownList = this.states;
    this.dropdownList = [];
    for(let result of this.states){
       this.dropdownList.push({'StateId' :result.StateId,'StateName':result.StateName});   
   }
   this.dropdownSettings  = {
   singleSelection: false,
   idField: 'StateId',
   textField: 'StateName',
   selectAllText: 'Select All',
   unSelectAllText: 'UnSelect All',
   itemsShowLimit: 3,
   allowSearchFilter: true
 };
    }       
    );
  }*/

  
  validate(): string {
    this.message = "";
    //alert(this.survey.StateIds);
      
    if (this.survey.SurveyName.trim() == "") {
      this.message = "Please enter Name.";
      return this.message ;
    }

    if (this.survey.SurveyType.trim() == "0") {
      this.message = "Please select SurveyType.";
      return this.message ;
    }       
      
    /*if (this.survey.StateIds.toString().trim() == "") {      
     this.message = "Please Select State.";
      return this.message ;
    }*/
     
    return this.message;
  }

  onSelectMethod(event) {
    let d = new Date(Date.parse(event));
    this.survey.StartDate = d;//`${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
    this.surveyStDate=d;
  }

  onSelectMethodDate(event) {
    let d = new Date(Date.parse(event));
    this.survey.EndDate = d;
    this.surveyEnDate=d;
  }

  Save(): void{
    this.message = "";
    this.selectedstateIds="";
      this.count=0;
      this.survey.StateIds  ="";
      
    this.message = this.validate();
 
    if (this.message.length <= 0) {
      this.isSaving = true;
      this.survey.StartDate = this.surveyStDate;
      this.survey.EndDate = this.surveyEnDate;
      this.survey.CreatedBy=this.userName;   
      this.survey.ModifiedBy=this.userName;   

      this.surveyDataService.Save(this.survey).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving Survey`;
        },
      );
    }
    
  }
}
