import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { UserComponent } from "./user.component";
import { UserRoutingModule } from "./user-routing.module";
import { UserManageComponent } from "./user-manage.component";
import {MatDatepickerModule, MatInputModule,MatNativeDateModule} from '@angular/material';
import { GalleriaModule } from 'primeng/galleria';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    DataTablesModule,
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    GalleriaModule,
    CalendarModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    UserRoutingModule
  ],
  declarations: [
    UserComponent, 
    UserManageComponent
  ],
  exports: [
    UserComponent, 
    RouterModule
  ],
  providers: [MessageService],
  entryComponents: [UserManageComponent]
})
export class UserModule {}
