export class Block {
    BlockId:number;
    DistrictId:number;
    DistrictName : string;
    StateId:number;
    StateName : string;
    BlockName : string;
    Comments: string;
    BEOContactNo: string;
    BEOName: string;
    ExtensionOfficerName: string;
    ExtensionOfficerContactNo: string;
    CreatedBy:number;
    ModifiedBy:number;
  }