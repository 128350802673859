import { StringifyOptions } from 'querystring';
import{SchoolContactPersons} from '../Models/schoolContactPersons'
import {SchoolMappingSurvey} from '../Models/schoolMappingSurvey'
export class School {
SchoolId : number;
SchoolUID : string;
SchoolName : string;
SchoolAuthorityName : string;
StateId : number;
DistrictId : number;
BlockId : number;
ClusterId : number;
StateName :string;
DistrictName :string;
BlockName :string;
ClusterName :string;
Village : string;
SCHMGT_DESC : string;
SCHCAT_DESC : string;
CompleteAddress : string;
Pincode : string;
Email1 : string;
Email2 : string;
STDCodePhone1 : string;
Phone1 : string;
STDCodePhone2 : string;
Phone2 : string;
Mobile1 : string;
Mobile2 : string;
Comments : string;
CreatedBy : number;
ModifiedBy : number;
Status : string ;
SchoolType : string;
SchoolTypeDes :string;
SchoolSubType:string;
SchoolSubTypeDes :string;
TotalStaff :number;
NoOfMale :number;
NoOfFemale:number;
TypeOfRegistration:string;
SchoolContactPersons:SchoolContactPersons[];
SchoolMappingSurvey:SchoolMappingSurvey[];
SchoolSurveyIds : string; 
TotalCount:number;
ResponsiblePerson:string;
Designation:string;
}