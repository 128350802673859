import { Component, Injectable, OnInit } from "@angular/core";
import { Block } from '../Models/block';
import { SchoolDataService } from "../Services/SchoolDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SchoolManageComponent } from './school-manage.component';
import { MessageService } from 'primeng/api';
import { School } from '../Models/school';
import { parse } from 'path';
import {  CollapseModule,  PaginationModule} from 'ngx-bootstrap';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import{ PaginationService } from '../pagination.service'


@Component({
  selector: "school",
  templateUrl: "./school.component.html",
  styleUrls:["school.css"]
})

@Injectable()
export class SchoolComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  
  listTable;
  listFormatTableExists: boolean = false;
  public schools: School[];
  bsModalRef: BsModalRef;
  userName:string;
  userType:string;
 
  minCount:number;
  maxCount:number;

  pageSizeSelected:number;
  totalCount:number;
  pageIndex:number;
  numPages:number;

  //public PageIndex = 1;
public PageCount = 10;
pager: any = {};

pageStart:number;

  sortColumn:string;
  sortOrder:string;
  searchValue:string;
  schoolUID:string
  menuList:any[];
  displayFlag:string ="";
  filterStateId:number=0;

  constructor(
    private paginationModule:PaginationModule,
    public paginationService :PaginationService,
    private modalService: BsModalService,
    private messageService: MessageService,
    public schoolDataService: SchoolDataService
  ) { 
      this.maxCount=0;
      this.minCount=0;
      this.pageIndex = 1;
      this.pageSizeSelected=12;
      this.pageStart = 0;
      this.sortColumn='SchoolName';
      this.sortOrder ='ASC';
      this.searchValue='';
      this.schoolUID='';
   }

  ngOnInit() {

    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='School'); //("Block");  
     this.displayFlag =menul.Menuname;
     if(this.displayFlag =='School')
     {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.userName = currentUser.UserId;
        this.userType = currentUser.UserType;
        this.filterStateId = currentUser.StateId;

      // this.totalCount=36000;
        this.setPage(this.pageIndex);
        this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex , this.sortColumn, this.sortOrder,this.searchValue);
     }
  }

  refresh() {
    this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);
  }

  loadSchools(schType : string,pageSizeSelected:number,pageIndex:number,sortColumn:string,sortOrder:string,searchValue:string): void {
    this.isLoading = true;
    this.schools = [];
     
    if (this.listFormatTableExists) {
      this.schools = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    //alert(this.pageSizeSelected);
    this.schoolDataService.getSchools(schType,pageSizeSelected,pageIndex,sortColumn,sortOrder,searchValue).subscribe(res => 
      {
      this.schools = res;
     // debugger;    
     if(this.schools.length>0)
     { 
        this.totalCount=this.schools[0].TotalCount; 
        this.setPage(this.pageIndex);
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          ordering:false,
          searching:false,
          paging:false,
          info:false

        });
       // this.setNextPage(pageIndex);
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    }
      this.isLoading = false;
    });
  }

  NextData() {
    this.pageIndex = this.pageIndex +1;
    this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex,this.sortColumn, this.sortOrder,this.searchValue);           // Get the next 100 records
  }

  prevData() {
    this.pageIndex = this.pageIndex -1;
    this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);         // Get the previous 100 records
  }

  Search(){
    this.pageIndex=1;
    this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex,this.sortColumn, this.sortOrder,this.searchValue);         // Get the next 100 records
    
  }

  sortData(columnName:string,sortOrdertype:string){
    /*debugger;
    if(this.sortOrder=='' || this.sortOrder=='DESC')
    {
      this.sortOrder='ASC';
    }
    else{
      this.sortOrder='DESC';
    }*/
    this.sortOrder=sortOrdertype;
    this.sortColumn=columnName;
    this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);         // Get the previous 100 records
  } 

  onAdd(){
    const initialState = {
      title: "Add a new School",
      SchoolId:0
    };

    this.bsModalRef = this.modalService.show(SchoolManageComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit School",
      SchoolId: id
    };
    this.bsModalRef = this.modalService.show(SchoolManageComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.schoolDataService.Delete(id).subscribe(
      data => {
        this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "School deleted successfully!"
        });
        this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);
      },
      (error: any) => {       
        if (error.status === 409) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `State cannot be deleted as it is referenced in some task parameter; Please remove the referance to delete it.`
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `Error while deleting the School`
          });
        }
      }
    );
  }

  //////////////////////////////////////////////////////////////////////////////////

  setPage(page: number) {
    this.pager = this.paginationService.getPager(this.totalCount, page, this.pageSizeSelected);    
    this.pageIndex = page;    
    this.maxCount=(this.pageSizeSelected)*page;
    this.minCount= ((this.pager.currentPage - 1) * (this.pageSizeSelected))+1;
    }   
    
    setNextPage(page: number) {
     // this.pageIndex = this.pageIndex +1;
    this.pager = this.paginationService.getPager(this.totalCount, page, this.pageSizeSelected);   
     this.pageIndex =page;
    this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);   
   
  }


   onPageSelected(event:any){
      if(event.target.value!=0){
        this.pageSizeSelected=event.target.value;
        this.pageIndex=1;
        this.loadSchools(this.userType, this.pageSizeSelected,this.pageIndex, this.sortColumn, this.sortOrder,this.searchValue);   
        }

    }
    
    }


//////////////////////////////////////////////////////////////////////////////////////

//}
