export class Cluster {
    ClusterId:number;
    BlockId:number;
    BlockName : string;
    DistrictId:number;
    DistrictName : string;
    StateId:number;
    StateName :string;
    ClusterName : string;
    Comments: string;
    ClusterCoordinatorName: string;
    ClusterCoordinatorContactNo: string;
    CreatedBy:number;
    ModifiedBy:number;
  }