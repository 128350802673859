import { Observable } from 'rxjs';
import { Component,OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/AuthenticationService';
import { UserDataService } from '../Services/UserDataService';
import { Menu } from '../Models/menu'


@Component({
    selector:"app-menu",
    templateUrl:"./menu.component.html"
})

export class MenuComponent implements OnInit{

    isLoggedIn$: Observable<boolean>;
    userName : string;
    public menuList : Menu[];
    public parentMenuList : Menu[];
    roleIdfromLogin :number;

    constructor(
        private authService: AuthenticationService,
        public userDataService : UserDataService,
        private router: Router
        ){
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.userName = currentUser.firstName;
            this.roleIdfromLogin =1;//currentUser.RoleId;
            this.loadMenuList(this.roleIdfromLogin);
      }

    ngOnInit(): void{
        //this.isLoggedIn$ = this.authService.isLoggedIn;
       // this.loadMenuList(this.roleIdfromLogin);
    }

    loadMenuList(roleId : number): void {
        this.menuList=[];
        this.parentMenuList=[];

        this.userDataService.getParentMenuListFromRoleId(roleId).subscribe(res => {
          console.log('res', res)
            this.parentMenuList = res;
          });

        this.userDataService.getMenuListFromRoleId(roleId).subscribe(res => {
            this.menuList = res;
            localStorage.setItem('menuList', JSON.stringify(this.menuList));

        });
      }


    onLogout() {
        //this.authService.logout();
        this.authService.logout();
      }

    createJob(){
        this.router.navigate(['/createjob']);
    }
}
