import { Component, Injectable, OnInit } from "@angular/core";
import { District } from '../Models/district';
import { DistrictDataService } from "../Services/DistrictDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DistrictManageComponent } from './district-manage.component';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: "district",
  templateUrl: "./district.component.html"
})
@Injectable()
export class DistrictComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public districts: District[];
  bsModalRef: BsModalRef;
  message: string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public districtDataService: DistrictDataService
  ) { }

  ngOnInit() {
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='District'); //("Block");  
     this.displayFlag =menul.Menuname;
     if(this.displayFlag =='District')
     {
       this.loadDistricts();
     }
  }

  refresh() {
    this.loadDistricts();
  }

  loadDistricts(): void {
    this.isLoading = true;
    this.districts = [];
    if (this.listFormatTableExists) {
      this.districts = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.districtDataService.getDistricts().subscribe(res => {
      this.districts = res;
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[0, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onAdd(){
    const initialState = {
      title: "Add a new District",
      DistrictId:0
    };
    this.bsModalRef = this.modalService.show(DistrictManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadDistricts();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit District",
      DistrictId: id
    };
    this.bsModalRef = this.modalService.show(DistrictManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadDistricts();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.districtDataService.Delete(id).subscribe(
      data => {
        let stateSaveResult1: StateSaveResult = data;
        if (stateSaveResult1.IsSuccessFull) {
          this.message = "";
          this.loadDistricts();
        }
        else {
          this.message = stateSaveResult1.ErrorMessage;
        }

      //   this.messageService.add({
      //     severity: "success",
      //     summary: "Deleted",
      //     detail: "District deleted successfully!"
      //   });
      //   this.loadDistricts();
      // },
      // (error: any) => {
      //   if (error.status === 409) {
      //     this.messageService.add({
      //       severity: "error",
      //       summary: "Error",
      //       detail: `State cannot be deleted as it is referenced in some task parameter; Please remove the referance to delete it.`
      //     });
      //   } else {
      //     this.messageService.add({
      //       severity: "error",
      //       summary: "Error",
      //       detail: `Error while deleting the State`
      //     });
      //   }
      }
    );
  }
}
