import { Component, Injectable, OnInit } from "@angular/core";
import { Block } from '../Models/block';
import { BlockDataService } from "../Services/BlockDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BlockManageComponent } from './block-manage.component';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: "block",
  templateUrl: "./block.component.html"
})
@Injectable()
export class BlockComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public blocks: Block[];
  bsModalRef: BsModalRef;
  message: string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public blockDataService: BlockDataService
  ) { }

  ngOnInit() {
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='Block'); //("Block");  
     this.displayFlag =menul.Menuname;
   
    if(this.displayFlag =='Block')
    {
       this.loadBlocks();
    }
    else{
     // this.message ="Unauthorise Access";    
      alert("Unauthorise Access");    
    }
  }

  refresh() {
    this.loadBlocks();
  }

  loadBlocks(): void {
    this.isLoading = true;
    this.blocks = [];
    if (this.listFormatTableExists) {
      this.blocks = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.blockDataService.getBlocks().subscribe(res => {
      this.blocks = res;
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[0, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onAdd(){
    const initialState = {
      title: "Add a new Block",
      BlockId:0
    };
    this.bsModalRef = this.modalService.show(BlockManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadBlocks();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit Block",
      BlockId: id
    };
    this.bsModalRef = this.modalService.show(BlockManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadBlocks();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.blockDataService.Delete(id).subscribe(
      data => {
        let stateSaveResult1: StateSaveResult = data;
        if (stateSaveResult1.IsSuccessFull) {
          this.message = "";
          this.loadBlocks();
        }
        else {
          this.message = stateSaveResult1.ErrorMessage;
        }

      //   this.messageService.add({
      //     severity: "success",
      //     summary: "Deleted",
      //     detail: "Block deleted successfully!"
      //   });
      //   this.loadBlocks();
      // },
      // (error: any) => {       
      //   if (error.status === 409) {
      //     this.messageService.add({
      //       severity: "error",
      //       summary: "Error",
      //       detail: `Block cannot be deleted as it is referenced in some task parameter; Please remove the referance to delete it.`
      //     });
      //   } else {
      //     this.messageService.add({
      //       severity: "error",
      //       summary: "Error",
      //       detail: `Error while deleting the Block`
      //     });
      //   }
      }
    );
  }
}
