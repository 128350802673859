export class Survey {
    SurveyId:number;
    SurveyName:string;
    StartDate:Date;
    EndDate: Date;
    StateIds : string;
    Comments: string;
    SurveyType:string;
    StartDate1 :string;
    EndDate1 : string;
    CreatedBy:number;
    ModifiedBy:number;
    IsSurveyDynamic:string;
  }