import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthGuard } from './Helper/auth.guard';
//Services

//Modules
import { HeaderModule } from "./Header/header.module";
import { HomeModule } from "./Home/home.module";
import { LoginModule } from "./Login/login.module";

import { HomeLayoutComponent } from './Layout/home-layout.component';
import { LoginLayoutComponent } from './Layout/login-layout.component';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { JwtInterceptor } from './Helper/jwt.interceptor';
import { ErrorInterceptor } from './Helper/error.interceptor';
import { fakeBackendProvider } from './Helper/fake-backend';
import { StateModule } from './State/state.module';
import { StateDataService } from './Services/StateDataService';

import { DistrictModule } from './District/district.module';
import { DistrictDataService } from './Services/DistrictDataService';
import { BlockDataService } from './Services/BlockDataService';
import { BlockModule } from './Block/block.module';

import { ClusterDataService } from './Services/ClusterDataService';
import { ClusterModule } from './Cluster/cluster.module';
import { SurveyModule } from './Survey/survey.module';
import { SurveyDataService } from './Services/SurveyDataService';
import { QuestionModule } from './Question/question.module';
import { QuestionDataService } from './Services/QuestionDataService';
import { SchoolModule } from './School/school.module';
import { StudentModule } from './Student/student.module';
import { SchoolDataService } from './Services/SchoolDataService';
import { CoordinateapprovalModule } from './CoordinateApproval/coordinateapproval.module';
import { QuestionAnsDataService } from './Services/QuestionAnsDataService';
import { GalleriaModule } from 'primeng/galleria';
import { SchoolreportModule } from './Reports/schoolreport.module'
import { AdminapprovalModule } from './AdminApproval/adminapproval.module';
import {CalendarModule} from 'primeng/calendar';
import { MenuModule } from './Menu/menu.module';
import {ExcelService} from './Services/ExcelService';
import { UserModule } from './User/user.module';
import { UserDataService } from './Services/UserDataService';
import { MasterDataService } from './Services/MasterDataService';
import { RolemappingModule } from './RoleMapping/rolemapping.module';
import{GraphsreportModule } from './GraphsReports/graphsreport.module'
import { SchoolreportWeightageModule } from './ReportWeightage/schoolreportweightage.module'
import { CoordinateTransportapprovalModule } from './CordinateTransportApproval/coordinateTransportapproval.module';
import { AdminTransportapprovalModule } from './AdminTransportApproval/adminTransportapproval.module';
import { ScoolsdetailsModule } from './ScoolsDetails/scoolsdetails.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // AngularFontAwesomeModule,
    FormsModule,
    HeaderModule,
    MenuModule,
    HomeModule,
    LoginModule,
    StateModule,
    DistrictModule,
    BlockModule,
    ClusterModule,
    SurveyModule,
    QuestionModule,
    SchoolModule,
    StudentModule,
    CoordinateapprovalModule,
    AdminapprovalModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    GalleriaModule,
    CalendarModule,
    SchoolreportModule,
    SchoolreportWeightageModule,
    UserModule,
    RolemappingModule,
    GraphsreportModule,
    CoordinateTransportapprovalModule,
    AdminTransportapprovalModule,
    ScoolsdetailsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    AuthGuard,
    StateDataService,
    DistrictDataService,
    BlockDataService,
    ClusterDataService,
    SurveyDataService,
    QuestionDataService,
    SchoolDataService,
    CoordinateapprovalModule,
    QuestionAnsDataService,
    UserDataService,
    MasterDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
