import { Component, Injectable, OnInit } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CoordinateTransportApprovalManageComponent } from './coordinateTransportapproval-manage.component';
import { MessageService } from 'primeng/api';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import {Router ,ActivatedRoute} from "@angular/router";
import { stringify } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { SurveyDataService } from '../Services/SurveyDataService';
import { ThrowStmt } from '@angular/compiler';
import { ReportParameters } from '../Models/reportParameters';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import {MatDatepickerModule, MatInputModule,MatNativeDateModule} from '@angular/material';

@Component({
  selector: "coordinateTransportapproval",
  templateUrl: "./coordinateTransportapproval.component.html",
  styleUrls: ['./coordinateTransportapproval.component.css']
})
@Injectable()
export class CoordinateTransportapprovalComponent implements OnInit {
 // public galleriaModule: GalleriaModule;
 
  public blocks: Block[];
  public states: State[];
  public clusters: Cluster[];
  public districts: District[];
  listTable;
  listFormatTableExists: boolean = false;
  stateId:number;
  districtId:number;
  blockId:number;
  clusterId:number;
  status:string;
  SchoolId :number;
  SurveyId:number;
  public data: any;
  //bsModalRef: BsModalRef;
  surveys = [];
  selectedstatus:string; selectedstateid:number; selectedDistrictId :number;
  selectedBlockId :number;
    selectedClusterId:number;SelectedSurveyId :number;
    stateIdq :string;
    distQ :string;
    clusterIDQ:string;
    blockIDQ:string;
    surveyIDQ:string;
    statusIDQ:string;
    isBackcnt : number;
    isLoading = false;   
    userName:string;
    userType:string;
    totalCount:number;
    selectedtoDate :Date;
    selectedfrmDate : Date;
    setBackFlag:string;
    fromDate:string;
    toDate :string;   
    public reportParameters:ReportParameters;
    menuList:any[];
  displayFlag:string ="";

  constructor(
   // private modalService: BsModalService,
   private datePipe: DatePipe,
    private messageService: MessageService,
    public galleriaModule: GalleriaModule,
    public blockDataService : BlockDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService,
    public clusterDataService : ClusterDataService,
    public questionAnsDataService : QuestionAnsDataService,
    public surveyDataService :SurveyDataService,
    public activatedRoute :ActivatedRoute,
    public calendarModule : CalendarModule,
    private router: Router,
    
  ) {this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks=[];
    
    this.selectedstatus ='0';
    this.selectedstateid  =0;
    this.selectedDistrictId=0;
    this.selectedBlockId=0;
    this.selectedClusterId=0;
    this.SelectedSurveyId=0;
    this.totalCount =0;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;

    this.loadstates();
    this.loadsurveys(); 
   }

  ngOnInit() {
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks=[];    
   // this.selectedfrmDate = new Date();
   // this.selectedtoDate = new Date(); 
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='Co-ordinator'); //("Block");  
     this.displayFlag =menul.Menuname;
   
    let repParameter = JSON.parse(localStorage.getItem('repParameterCordinate'));
    this.setBackFlag="";
    this.setBackFlag = this.activatedRoute.snapshot.queryParamMap.get("setBackFlag");
    if(this.displayFlag =='Co-ordinator')
    {
     if(this.setBackFlag=='Back')
     {
       //alert('HI')
          this.selectedstatus =repParameter.status;
          this.selectedstateid=repParameter.stateid;
          this.loaddistricts(this.selectedstateid.toString());
          this.selectedDistrictId=repParameter.DistrictId;
          this.loadblocks(repParameter.DistrictId.toString());
          this.selectedBlockId=repParameter.BlockId;
          this.loadclusters(repParameter.BlockId.toString());  
          this.selectedClusterId=repParameter.ClusterId;
          this.SelectedSurveyId=repParameter.SurveyId;
          if(repParameter.frmDate!=null)
          {let frmdate = new Date(Date.parse(repParameter.frmDate));
          this.selectedfrmDate=frmdate;}
          if(repParameter.toDate!=null)
            { let todate = new Date(Date.parse(repParameter.toDate));
          this.selectedtoDate=todate;}

          this.loadQuestionAnsList(this.selectedstatus,this.selectedstateid,this.selectedDistrictId,this.selectedBlockId,
          this.selectedClusterId,this.SelectedSurveyId,this.selectedfrmDate,this.selectedtoDate);
   }
  }
  else{
   // this.message ="Unauthorise Access";    
    alert("Unauthorise Access");    
  }


  }

  ClearDateFields()
  {
    this.selectedfrmDate=null;
    this.selectedtoDate=null;
  }

  onSelectMethod(event) {
    this.selectedfrmDate=null;
    let d = new Date(Date.parse(event));
    this.selectedfrmDate=d;
    event=null;
  }  
  onSelectMethodDate(event) {
    this.selectedtoDate=null;
    let d = new Date(Date.parse(event));
    this.selectedtoDate=d;
    event=null;
   // alert(d);
  }
  

  getTheColor(statusClr) {
    if(statusClr=="CR" || statusClr=="AR") 
    return "red";
    else if(statusClr=="AA" || statusClr=="CA")
    return "#006400";
    
    // could be better written, but you get the idea
}

  loadsurveys(): void {
     this.isLoading = true;
    this.surveys = [];
     this.surveyDataService.getSurveys(this.userType,'T').subscribe(res => {
      this.surveys = res;
     this.isLoading = false;      
    });
  }

  loadstates(): void {
    this.isLoading = true;
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks=[];
    this.selectedDistrictId=0;
    this.selectedBlockId=0;
    this.selectedClusterId=0;
    
   this.stateDataService.getStates().subscribe(res => {
    this.states = res;
    this.states.sort(function (a, b) {
      var A = a.StateName.toUpperCase();
      var B = b.StateName.toUpperCase();
      let comparison = 0;
      if (A > B) {
        return  comparison = 1;
     } else if (A < B) {
        return comparison = -1;
      }
      
    });
    this.isLoading = false;      
  });
}

loaddistricts(value:string): void {   
  this.isLoading = true; 
    this.districts = []
    this.data=[];
    this.stateId = parseInt(value);
    this.selectedDistrictId=0;
    this.selectedBlockId=0;
    this.selectedClusterId=0;
    this.clusters = [];
    this.blocks=[];
    this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
    this.districts = res;
    this.districts.sort(function (a, b) {
      var A = a.DistrictName.toUpperCase();
      var B = b.DistrictName.toUpperCase();
      let comparison = 0;
      if (A > B) {
        return  comparison = 1;
     } else if (A < B) {
        return comparison = -1;
      }
      
    });
    this.isLoading = false;    
  });
}

loadQuestionAnsList(selectedstatus :string,selectedstateid :number,selectedDistrictId :number,selectedBlockId:number,
  selectedClusterId:number,SelectedSurveyId:number,selectedFrmDate:Date,selectedToDate:Date): void {
    this.isLoading = true;    
    this.data = [];
    this.totalCount =0;
    
    this.reportParameters = new ReportParameters();
    this.reportParameters.status = selectedstatus;
    this.reportParameters.stateid = selectedstateid;
    this.reportParameters.DistrictId = selectedDistrictId;
    this.reportParameters.BlockId = selectedBlockId;
    this.reportParameters.ClusterId = selectedClusterId;
    this.reportParameters.SurveyId = SelectedSurveyId;
    this.reportParameters.frmDate=selectedFrmDate;
    this.reportParameters.toDate=selectedToDate;     
    localStorage.setItem('repParameterCordinate', JSON.stringify(this.reportParameters));

    if(selectedFrmDate==null)
    {

      selectedFrmDate= new Date("1900-01-01");
    }
    if(selectedToDate==null)
    {

      selectedToDate =new Date();
    }
    this.fromDate= this.datePipe.transform(selectedFrmDate,"yyyy-MM-dd");
    this.toDate=this.datePipe.transform(selectedToDate,"yyyy-MM-dd");


    this.fromDate= this.datePipe.transform(selectedFrmDate,"yyyy-MM-dd");
    this.toDate=this.datePipe.transform(selectedToDate,"yyyy-MM-dd");
    if (this.listFormatTableExists) {
      this.districts = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.questionAnsDataService.getquestionListTransport(selectedstatus,selectedstateid,selectedDistrictId,selectedBlockId,
    selectedClusterId,SelectedSurveyId,'C',this.userType,this.fromDate,this.toDate).subscribe(res => {
    this.data = res;
    setTimeout(() => {
      this.listTable = $("#districtTable").DataTable({
        lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
        pageLength: 12,
        order: [[0, "asc"]]
      });
      this.listFormatTableExists = true;
      this.isLoading = false;
    }, 0);
    this.totalCount =this.data.length;
    this.isLoading = false;      
  });
}


  onOptionsSelected(event:any){
    this.loaddistricts(event.target.value);
    }

onDistrictSelected(event:any){
this.loadblocks(event.target.value);

}

onBlockSelected(event:any){
this.loadclusters(event.target.value);
}

loadblocks(value:string): void {
  this.isLoading = true;    
  this.data = [];
 this.selectedBlockId=0;
 this.selectedClusterId=0; 
    this.blocks = [];
    this.clusters = [];
    this.districtId = parseInt(value);
    this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
    this.blocks = res;
    this.blocks.sort(function (a, b) {
      var A = a.BlockName.toUpperCase();
      var B = b.BlockName.toUpperCase();
      let comparison = 0;
      if (A > B) {
        return  comparison = 1;
     } else if (A < B) {
        return comparison = -1;
      }
      
    });
     this.isLoading=false;   
  });
}

loadclusters(value:string): void {
  this.isLoading = true;    
  this.data = []; 
  this.selectedClusterId=0; 
   this.clusters = [];
    this.blockId= parseInt(value);
   this.clusterDataService.getClusterByblockId(this.blockId).subscribe(res => {
    this.clusters = res;
    this.clusters.sort(function (a, b) {
      var A = a.ClusterName.toUpperCase();
      var B = b.ClusterName.toUpperCase();
      let comparison = 0;
      if (A > B) {
        return  comparison = 1;
     } else if (A < B) {
        return comparison = -1;
      }
      
    });
    this.isLoading = false;     
  });
}


public gotoProductDetailsV2(url :string ,SchlId:number,surveyId:number) {
  const initialState = {
    title: "Approve / Reject",
    SchlId: SchlId,
    surveyId : surveyId,
    };
  
    this.router.navigate(['/coordinateTransportapproval-manage'], { queryParams: { SchlId: SchlId , surveyId :surveyId} });

//  this.router.navigate(['/coordinateapproval-manage'], { queryParams: { SchlId: SchlId ,surveyId :surveyId,
  //  stateIdFP:this.selectedstateid,districtID:this.selectedDistrictId,blockID:this.selectedBlockId,
    //clusterID:this.selectedClusterId,statusselected:this.selectedstatus,SelectedsurveyID:this.SelectedSurveyId} });
}

}
