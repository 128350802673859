import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { CoordinateTransportapprovalRoutingModule } from "./coordinateTransportapproval-routing.module";
import { CoordinateTransportApprovalManageComponent } from "./coordinateTransportapproval-manage.component";
import { CoordinateTransportapprovalManageQuComponent } from "./coordinateTransportapproval-manage-qu.component"
import{ CoordinateTransportapprovalComponent } from "./coordinateTransportapproval.component"
import { GalleriaModule } from 'primeng/galleria';
import { ImageViewerModule } from "ngx-image-viewer";
import { DatePipe } from '@angular/common';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    CalendarModule,
    MessageModule,
    ConfirmationPopoverModule.forRoot({
    confirmButtonType: 'danger' // set defaults here
    }),
    CoordinateTransportapprovalRoutingModule,
    GalleriaModule,
    ImageViewerModule.forRoot()
      ],
  declarations: [
    CoordinateTransportapprovalComponent,
    CoordinateTransportApprovalManageComponent,
    CoordinateTransportapprovalManageQuComponent
  ],
  exports: [
   // CoordinateapprovalComponent,
   CoordinateTransportApprovalManageComponent,
    RouterModule
  ],
  providers: [MessageService,DatePipe]
})
export class CoordinateTransportapprovalModule {}
