import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { District } from "../Models/district";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";

@Injectable()
export class DistrictDataService {
    constructor(private http: HttpClient) {
        district: District;
    }
    public getDistricts(): Observable<District[]> {
        return this.http.get<District[]>(config.appUrl + 'Master/GetDistrict');
    }
    public getDistrictById(id: number): Observable<District> {
        return this.http.get<District>(config.appUrl + '/district/' + id);
    }

    public getDistrictByStateId(stateId: number): Observable<District[]> {
        return this.http.get<District[]>(config.appUrl + '/District/DistrictByStateId/' + stateId);
    }
    

    public Save(district: District): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/district',
            JSON.stringify(district),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<StateSaveResult> {
        return this.http.delete<StateSaveResult>(config.appUrl + '/district/' + id);


    }
}