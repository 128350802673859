import { Component, Injectable, OnInit } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CoordinateApprovalManageComponent } from './coordinateapproval-manage.component';
import { MessageService } from 'primeng/api';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { Router, ActivatedRoute } from "@angular/router";
import { stringify } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { SurveyDataService } from '../Services/SurveyDataService';
import { ThrowStmt } from '@angular/compiler';
import { ReportParameters } from '../Models/reportParameters';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';

import { CollapseModule, PaginationModule } from 'ngx-bootstrap';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PaginationService } from '../pagination.service';
@Component({
  selector: "coordinateapproval",
  templateUrl: "./coordinateapproval.component.html",
  styleUrls: ['./coordinateapproval.component.css']
})
@Injectable()
export class CoordinateapprovalComponent implements OnInit {
  // public galleriaModule: GalleriaModule;

  public blocks: Block[];
  public states: State[];
  public clusters: Cluster[];
  public districts: District[];
  listTable;
  listFormatTableExists: boolean = false;
  stateId: number;
  districtId: number;
  blockId: number;
  clusterId: number;
  status: string;
  SchoolId: number;
  SurveyId: number;
  public data: any;
  //bsModalRef: BsModalRef;
  surveys = [];
  selectedstatus: string; selectedstateid: number; selectedDistrictId: number;
  selectedBlockId: number;
  selectedClusterId: number; SelectedSurveyId: number;
  stateIdq: string;
  distQ: string;
  clusterIDQ: string;
  blockIDQ: string;
  surveyIDQ: string;
  statusIDQ: string;
  isBackcnt: number;
  isLoading = false;
  userName: string;
  userType: string;
  totalCount: number;
  selectedtoDate: Date;
  selectedfrmDate: Date;
  setBackFlag: string;
  fromDate: string;
  toDate: string;
  public reportParameters: ReportParameters;
  menuList: any[];
  displayFlag: string = "";
  filterStateId: number = 0;
  displayFlagSearch: string = "";

  noData = false;
  minCount: number;
  maxCount: number;
  pageStart: number;
  sortColumn: string;
  sortOrder: string;
  searchValue: string;
  pageSizeSelected: number;
  pageIndex: number;
  numPages: number;
  public PageCount = 10;
  pager: any = {};


  constructor(
    // private modalService: BsModalService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    public galleriaModule: GalleriaModule,
    public blockDataService: BlockDataService,
    public districtDataService: DistrictDataService,
    public stateDataService: StateDataService,
    public clusterDataService: ClusterDataService,
    public questionAnsDataService: QuestionAnsDataService,
    public surveyDataService: SurveyDataService,
    public activatedRoute: ActivatedRoute,
    public calendarModule: CalendarModule,
    private router: Router,
    public paginationService: PaginationService,


  ) {
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];

    this.selectedstatus = '0';
    this.selectedstateid = 0;
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.SelectedSurveyId = 0;
    this.totalCount = 0;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;
    this.filterStateId = currentUser.StateId;

    this.maxCount = 0;
    this.minCount = 0;
    this.pageIndex = 1;
    this.pageSizeSelected = 12;
    this.pageStart = 0;
    this.sortColumn = 'SchoolName';
    this.sortOrder = 'ASC';
    this.searchValue = '';

    this.loadstates();
    this.loadsurveys();
  }

  ngOnInit() {
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    // this.selectedfrmDate = new Date();
    // this.selectedtoDate = new Date(); 
    this.displayFlag = "";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e => e.Menuname === 'Co-ordinator'); //("Block");  
    this.displayFlag = menul.Menuname;

    let repParameter = JSON.parse(localStorage.getItem('repParameterCordinate'));
    this.setBackFlag = "";
    this.setBackFlag = this.activatedRoute.snapshot.queryParamMap.get("setBackFlag");
    if (this.displayFlag == 'Co-ordinator') {
      if (this.setBackFlag == 'Back') {
        //alert('HI')
        this.selectedstatus = repParameter.status;
        this.selectedstateid = repParameter.stateid;
        this.loaddistricts(this.selectedstateid.toString());
        this.selectedDistrictId = repParameter.DistrictId;
        this.loadblocks(repParameter.DistrictId.toString());
        this.selectedBlockId = repParameter.BlockId;
        this.loadclusters(repParameter.BlockId.toString());
        this.selectedClusterId = repParameter.ClusterId;
        this.SelectedSurveyId = repParameter.SurveyId;
        if (repParameter.frmDate != null) {
          let frmdate = new Date(Date.parse(repParameter.frmDate));
          this.selectedfrmDate = frmdate;
        }
        if (repParameter.toDate != null) {
          let todate = new Date(Date.parse(repParameter.toDate));
          this.selectedtoDate = todate;
        }


        this.setPage(this.pageIndex);
        this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
          this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
          this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
      }
    }
    else {
      // this.message ="Unauthorise Access";    
      alert("Unauthorise Access");
    }


  }
  NextData() {
    this.pageIndex = this.pageIndex + 1;
    this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
      this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
      this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
  }

  prevData() {
    this.pageIndex = this.pageIndex - 1;
    this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
      this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
      this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
  }

  Search() {
    this.pageIndex = 1;
    this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
      this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
      this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
  }

  sortData(columnName: string, sortOrdertype: string) {

    this.sortOrder = sortOrdertype;
    this.sortColumn = columnName;
    this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
      this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
      this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
  }

  setPage(page: number) {
    this.pager = this.paginationService.getPager(this.totalCount, page, this.pageSizeSelected);
    this.pageIndex = page;
    this.maxCount = (this.pageSizeSelected) * page;
    this.minCount = ((this.pager.currentPage - 1) * (this.pageSizeSelected)) + 1;
  }

  setNextPage(page: number) {
    // this.pageIndex = this.pageIndex +1;
    this.pager = this.paginationService.getPager(this.totalCount, page, this.pageSizeSelected);
    this.pageIndex = page;
    this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
      this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
      this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
  }


  onPageSelected(event: any) {
    if (event.target.value != 0) {
      this.pageSizeSelected = event.target.value;
      this.pageIndex = 1;
      this.loadQuestionAnsList(this.selectedstatus, this.selectedstateid, this.selectedDistrictId, this.selectedBlockId,
        this.selectedClusterId, this.SelectedSurveyId, this.selectedfrmDate, this.selectedtoDate,
        this.pageSizeSelected, this.pageIndex, this.sortColumn, this.sortOrder, this.searchValue);
    }

  }

  ClearDateFields() {
    this.selectedfrmDate = null;
    this.selectedtoDate = null;
  }

  onSelectMethod(event) {
    this.selectedfrmDate = null;
    let d = new Date(Date.parse(event));
    this.selectedfrmDate = d;
    event = null;
  }
  onSelectMethodDate(event) {
    this.selectedtoDate = null;
    let d = new Date(Date.parse(event));
    this.selectedtoDate = d;
    event = null;
    // alert(d);
  }


  getTheColor(statusClr) {
    if (statusClr == "CR" || statusClr == "AR")
      return "red";
    else if (statusClr == "AA" || statusClr == "CA")
      return "#006400";

    // could be better written, but you get the idea
  }

  loadsurveys(): void {
    this.isLoading = true;
    this.surveys = [];
    this.surveyDataService.getSurveys(this.userType, 'S').subscribe(res => {
      this.surveys = res;
      this.isLoading = false;
    });
  }

  loadstates(): void {
    this.isLoading = true;
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    let statesList: State[];

    this.stateDataService.getStates().subscribe(res => {
      statesList = res;
      if (this.filterStateId > 0) {
        this.states = statesList.filter(e => e.StateId == this.filterStateId);
        this.selectedstateid = this.filterStateId;
        this.loaddistricts(this.filterStateId.toString());
      }
      else {
        this.states = statesList;
      }

      this.states.sort(function (a, b) {
        var A = a.StateName.toUpperCase();
        var B = b.StateName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  loaddistricts(value: string): void {
    this.isLoading = true;
    this.districts = []
    // this.data=[];
    this.stateId = parseInt(value);
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.clusters = [];
    this.blocks = [];
    this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
      this.districts = res;
      this.districts.sort(function (a, b) {
        var A = a.DistrictName.toUpperCase();
        var B = b.DistrictName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  loadQuestionAnsList(selectedstatus: string, selectedstateid: number, selectedDistrictId: number, selectedBlockId: number,
    selectedClusterId: number, SelectedSurveyId: number, selectedFrmDate: Date, selectedToDate: Date,
    pageSizeSelected: number, pageIndex: number, sortColumn: string, sortOrder: string, searchValue: string
  ): void {
    this.isLoading = true;
    this.data = [];
    this.totalCount = 0;

    this.reportParameters = new ReportParameters();
    this.reportParameters.status = selectedstatus;
    this.reportParameters.stateid = selectedstateid;
    this.reportParameters.DistrictId = selectedDistrictId;
    this.reportParameters.BlockId = selectedBlockId;
    this.reportParameters.ClusterId = selectedClusterId;
    this.reportParameters.SurveyId = SelectedSurveyId;
    this.reportParameters.frmDate = selectedFrmDate;
    this.reportParameters.toDate = selectedToDate;
    localStorage.setItem('repParameterCordinate', JSON.stringify(this.reportParameters));

    if (selectedFrmDate == null) {

      selectedFrmDate = new Date("1900-01-01");
    }
    if (selectedToDate == null) {

      selectedToDate = new Date();
    }
    this.fromDate = this.datePipe.transform(selectedFrmDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(selectedToDate, "yyyy-MM-dd");


    this.fromDate = this.datePipe.transform(selectedFrmDate, "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(selectedToDate, "yyyy-MM-dd");
    if (this.listFormatTableExists) {
      this.data = [];
      this.listTable.destroy();
      this.listTable.clear();
    }

    if (this.filterStateId > 0 && selectedstateid == 0) {
      selectedstateid = this.filterStateId;
    }

    this.questionAnsDataService.getquestionList().subscribe(res => {
      this.data = res;

      if (this.data.length == 0) {

        
        this.noData = true;
        this.isLoading = false;

      }
      else {
        this.displayFlagSearch = this.data[0].TotalCount;
      
      this.totalCount = this.data[0].TotalCount;
      this.setPage(this.pageIndex);
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          
          ordering: false,
          searching: false,
          paging: false,
          info: false
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    }
      // this.totalCount =200; //this.data.length;
     
    });
  
  }


  onOptionsSelected(event: any) {
    this.loaddistricts(event.target.value);
  }

  onDistrictSelected(event: any) {
    this.loadblocks(event.target.value);

  }

  onBlockSelected(event: any) {
    this.loadclusters(event.target.value);
  }

  loadblocks(value: string): void {
    this.isLoading = true;
    // this.data = [];
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.blocks = [];
    this.clusters = [];
    this.districtId = parseInt(value);
    this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
      this.blocks = res;
      this.blocks.sort(function (a, b) {
        var A = a.BlockName.toUpperCase();
        var B = b.BlockName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  loadclusters(value: string): void {
    this.isLoading = true;
    // this.data = []; 
    this.selectedClusterId = 0;
    this.clusters = [];
    this.blockId = parseInt(value);
    this.clusterDataService.getClusterByblockId(this.blockId).subscribe(res => {
      this.clusters = res;
      this.clusters.sort(function (a, b) {
        var A = a.ClusterName.toUpperCase();
        var B = b.ClusterName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }


  public gotoProductDetailsV2(url: string, SchlId: number, surveyId: number) {
    const initialState = {
      title: "Approve / Reject",
      SchlId: SchlId,
      surveyId: surveyId,
    };

    this.router.navigate(['/coordinateapproval-manage'], { queryParams: { SchlId: SchlId, surveyId: surveyId } });

    //  this.router.navigate(['/coordinateapproval-manage'], { queryParams: { SchlId: SchlId ,surveyId :surveyId,
    //  stateIdFP:this.selectedstateid,districtID:this.selectedDistrictId,blockID:this.selectedBlockId,
    //clusterID:this.selectedClusterId,statusselected:this.selectedstatus,SelectedsurveyID:this.SelectedSurveyId} });
  }

}
