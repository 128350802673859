import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { QuestionDataService } from '../Services/QuestionDataService';
import { SurveyDataService } from '../Services/SurveyDataService';
import { Survey } from '../Models/survey';
import { Question } from '../Models/question';
import { StateSaveResult } from '../Models/stateSaveResult';
import { isNumeric } from 'rxjs/util/isNumeric';
import { initialiseAgGridWithAngular1 } from 'ag-grid-community';
import { QuestionTypeAnsOption } from '../Models/questionTypeAnsOption';

@Component({
  selector: 'question-manage',
  templateUrl: 'question-manage.component.html'
})

export class QuestionManageComponent implements OnInit {
  question: Question;
  title: string;
  public surveys: Survey[];
  id:number;
  QuestionId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  qOrder : string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  a:number;
  userName:string;
  userType:string;
  ManualType:string='R';
  graphsSelections : any[];
  readioSelected:string
  questionTypeOptionInactive:any[];

  constructor(public bsModalRef: BsModalRef, 
    public questionDataService : QuestionDataService,
    public surveyDataService : SurveyDataService,
    ){
      
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;
      this.loadsurveys();
      this.graphsSelections=[];
       this.graphsSelections.push({value:'S' ,Text : "Single Choice"});
       this.graphsSelections.push({value:'M' ,Text : "Multiple Choice"});
       this.graphsSelections.push({value:'T' ,Text : "Text"});
       this.readioSelected='S';
       this.questionTypeOptionInactive =[];
     }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();

    if(this.QuestionId == 0)
    {
      this.question = new Question();
      this.question.Question = "";
      this.question.QMarathi="";
      this.question.QHindi="";
      this.question.SurveyId=0;
      this.question.QuestionOrder=0;
      this.question.Weightage=0;
      this.question.ManualType=this.ManualType;
      this.question.questionTypeAnsOption=[{
        QuestionTypeAnsOptionId:0,
        QuestionId :0,
        AnsOption : "",
        Status:"A"
           }];
    }
    else{
      this.fillQuestionById();
    }
    
  }

  Clear() : void{
    this.message = "";
    this.onClose = new Subject(); 
    this.question = new Question();
      this.question.Question = "";
      this.question.QMarathi="";
      this.question.QHindi="";
      this.question.SurveyId=0;
      this.question.QuestionOrder=0;
      this.question.Weightage=0;
      this.question.questionTypeAnsOption=[{
        QuestionTypeAnsOptionId:0,
        QuestionId :0,
        AnsOption : "",
        Status:""
           }];
  }

  fillQuestionById() {
    this.isLoading = true;
    this.question = new Question();
    this.questionDataService.getQuestionById(this.QuestionId,0).subscribe(res => {
      this.question = res;
      this.isLoading = false;
      this.ManualType =this.question.ManualType;
      this.readioSelected = this.question.QuestionType;
    });
  }
  
  loadsurveys(): void {
    this.isLoading = true;  

    this.surveys = [];
     this.surveyDataService.getSurveys(this.userType,'A').subscribe(res => {
      this.surveys = res;
      this.isLoading = false;      
    });
  }

  onReportSelected(event:any){ 
    this.ManualType=event.target.value;
    this.question.ManualType=event.target.value;
   }
   
   addResorces() {
    //console.log(this.district.OtherResources);
     this.question.questionTypeAnsOption.push({
      QuestionTypeAnsOptionId:0,
      QuestionId :0,
      AnsOption : "",
      Status:"A"
    });
  }

  
  removeResorces(i: number) {   
   // this.addInactiveResorces(i); 
    this.questionTypeOptionInactive.push(
      {'QuestionTypeAnsOptionId':this.question.questionTypeAnsOption[i].QuestionTypeAnsOptionId}) 
    this.question.questionTypeAnsOption.splice(i, 1);
   // alert(this.questionTypeOptionInactive);
  }
  
  validate(): string {
    this.message = "";

    if (this.question.SurveyId == 0) {
      this.message = "Please select Survey Name";
      return this.message ;
    }
    if (this.question.Question.trim() == "") {
      this.message = "Please enter Question";
      return this.message ;
    }
    if (this.question.QMarathi.trim() == "") {
      this.message = "Please enter Question in Marathi";
      return this.message ;
    }
    if (this.question.QHindi.trim() == "") {
      this.message = "Please enter Question in Hindi";
      return this.message ;
    }
    if (this.question.QuestionOrder.toString().trim() == "0" || !(isNumeric(this.question.QuestionOrder))) {
      this.message = "Please enter numeric value for Question Order";
      return this.message ;
    }   
    
    if (!(isNumeric(this.question.Weightage))) {
      this.message = "Please enter numeric value for Weightage";
      return this.message ;
    }

     return this.message;
  }

  Save(): void{
    this.message = "";
    this.message = this.validate();
    if (this.message.length <= 0) {
      this.isSaving = true;
      this.question.CreatedBy=parseInt(this.userName);
      this.question.ModifiedBy=parseInt(this.userName);
      if(this.question.ManualType=='R')
      {
      this.questionDataService.Save(this.question).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving Question`;
        },
      );
      }
      else if(this.question.ManualType=='D')
      {   
        
        for(let result of this.questionTypeOptionInactive){
          this.question.questionTypeAnsOption.push({
            QuestionTypeAnsOptionId:result.QuestionTypeAnsOptionId,
            QuestionId :0,
            AnsOption : '',
            Status:'I'
          }); 
        }
        
        this.questionDataService.SaveDynamic(this.question).subscribe(
         data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving Question`;
        },
      );
      }  
    }
    
  }
}
