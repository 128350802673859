import { Component, OnInit } from '@angular/core';
import { State } from '../Models/state';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { StateDataService } from '../Services/StateDataService';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: 'state-manage',
  templateUrl: 'state-manage.component.html'
})

export class StateManageComponent implements OnInit {
  state: State;
  title: string;
  id:number;
  StateId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;

  constructor(public bsModalRef: BsModalRef, public stateDataService : StateDataService) { }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();
    if(this.StateId == 0)
    {
      this.state = new State();
      this.state.StateName = "";
    }
    else{
      this.fillStateById();
    }
    
  }

  fillStateById() {
    this.isLoading = true;
    this.state = new State();
    this.stateDataService.getStateById(this.StateId).subscribe(res => {
      this.state = res;
      this.isLoading = false;
    });
  }
  
 

  validate(): string {
    this.message = "";
    if (this.state.StateName.trim() == "") {
      this.message = "Please enter Name";
      return this.message ;
    }
   
    return this.message;
  }
  
  Clear() : void{
    this.message = "";
    this.state.StateName = "";
  }
  Save(): void{
    this.message = "";
    this.message = this.validate();
 
    if (this.message.length <= 0) {
      this.isSaving = true;
      this.stateDataService.Save(this.state).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving state`;
        },
      );
    }
    
  }
}
