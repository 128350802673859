import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from "./Home/home.component";
import { LoginComponent } from "./Login/login.component";
import { LoginLayoutComponent } from './Layout/login-layout.component';
import { HomeLayoutComponent } from './Layout/home-layout.component';
import { AuthGuard } from './Helper/auth.guard';
import { StateComponent } from './State/state.component';
import { DistrictComponent } from './District/district.component';
import { BlockComponent } from './Block/block.component';
import { ClusterComponent } from './Cluster/cluster.component';
import { SurveyComponent } from './Survey/survey.component';
import { QuestionComponent } from './Question/question.component';
import { SchoolComponent } from './School/school.component';
import { CoordinateapprovalComponent } from './CoordinateApproval/coordinateapproval.component'
import { CoordinateApprovalManageComponent } from './CoordinateApproval/coordinateapproval-manage.component'
import { CoordinateapprovalManageQuComponent } from './CoordinateApproval/coordinateapproval-manage-qu.component';
import { SchoolreportComponent } from './Reports/schoolreport.component';
import { SchoolreportManageComponent } from './Reports/schoolreport-manage.component';
import { SchoolManageComponent } from './School/school-manage.component';
import { AdminapprovalComponent } from './AdminApproval/adminapproval.component'
import { AdminApprovalManageComponent } from './AdminApproval/adminapproval-manage.component'
import { AdminapprovalManageQuComponent } from './AdminApproval/adminapproval-manage-qu.component';
import { UserComponent } from './User/user.component';
import { RolemappingComponent } from './RoleMapping/rolemapping.component';
import{ GraphsreportComponent} from './GraphsReports/graphsreport.component'
import { SchoolreportWeightageComponent } from './ReportWeightage/schoolreportweightage.component';
import { SchoolreportWeightageManageComponent } from './ReportWeightage/schoolreportweightage-manage.component';
import { CoordinateTransportapprovalComponent } from './CordinateTransportApproval/coordinateTransportapproval.component'
import { CoordinateTransportApprovalManageComponent } from './CordinateTransportApproval/coordinateTransportapproval-manage.component'
import { CoordinateTransportapprovalManageQuComponent } from './CordinateTransportApproval/coordinateTransportapproval-manage-qu.component';
import { AdminTransportapprovalComponent } from './AdminTransportApproval/adminTransportapproval.component'
import { AdminTransportApprovalManageComponent } from './AdminTransportApproval/adminTransportapproval-manage.component'
import { AdminTransportapprovalManageQuComponent } from './AdminTransportApproval/adminTransportapproval-manage-qu.component';
import { ScoolsDetailsComponent } from './ScoolsDetails/scoolsdetails.component';
import { StudentComponent } from './Student/student.component';
import { StudentNewComponent } from './Student/student-new/student-new.component';
import { StudentEditComponent } from './Student/student-edit/student-edit.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        //loadChildren: './Home/home.module#HomeComponent'
        component: HomeComponent
      },
      {
        path: 'home',
        //loadChildren: './Home/home.module#HomeComponent'
        component: HomeComponent
      },
      {
        path: 'state',
        //loadChildren: './State/state.module#StateComponent'
        component: StateComponent
      },
      {
        path: 'district',
        component: DistrictComponent
        //loadChildren: () => import("./District/district.module").then(m => m.DistrictModule)
      },
      {
        path: 'block',
        component: BlockComponent
      },
      {
        path: 'cluster',
        component: ClusterComponent
      },
      {
        path: 'survey',
        //loadChildren: () => import("./Survey/survey.module").then(m => m.SurveyModule)
        component: SurveyComponent

      },
      {
        path: 'question',
       // loadChildren: () => import("./Question/question.module").then(m => m.QuestionModule)
       component: QuestionComponent
      },
      {
        path: 'school',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: SchoolComponent
      },
      {
        path: 'student',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: StudentComponent
      },
      {
        path: 'studentAdd',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: StudentNewComponent
      },
      {
        path: 'studentEdit/:id',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: StudentEditComponent
      },
      {
        path: 'coordinateApproval',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: CoordinateapprovalComponent
      },
      {
        path: 'coordinateapproval-manage',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: CoordinateApprovalManageComponent
      },
      {
        path: 'coordinateapproval-manageQu',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: CoordinateapprovalManageQuComponent
      },
      {
        path: 'adminApproval',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: AdminapprovalComponent
      },
      {
        path: 'admineapproval-manage',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: AdminApprovalManageComponent
      },
      {
        path: 'adminapproval-manageQu',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component:AdminapprovalManageQuComponent
      },

      {
      path: 'coordinateTransportApproval',
      //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
      component: CoordinateTransportapprovalComponent
    },
    {
      path: 'coordinateTransportapproval-manage',
      //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
      component: CoordinateTransportApprovalManageComponent
    },
    {
      path: 'coordinateTransportapproval-manageQu',
      //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
      component: CoordinateTransportapprovalManageQuComponent
    },

    {
      path: 'adminTransportApproval',
      //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
      component: AdminTransportapprovalComponent
    },
    {
      path: 'adminTransportapproval-manage',
      //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
      component: AdminTransportApprovalManageComponent
    },
    {
      path: 'adminTransportapproval-manageQu',
      //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
      component: AdminTransportapprovalManageQuComponent
    },

      {
        path: 'schoolreports',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: SchoolreportComponent
      },
      {
        path: 'schoolreport-manage',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: SchoolreportManageComponent
      },

      {
        path: 'schoolWeightagereports',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: SchoolreportWeightageComponent
      },
      {
        path: 'schoolreportWeightagemanage',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: SchoolreportWeightageManageComponent
      },


      {
        path: 'user',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: UserComponent
      },
      {
        path: 'usersetting',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: RolemappingComponent
      },
      {
        path: 'schooldetails',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: ScoolsDetailsComponent
      },
      {
        path: 'graphs',
        //loadChildren: () => import("./School/school.module").then(m => m.SchoolModule)
        component: GraphsreportComponent
      },


    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        //loadChildren: './Login/login.module#LoginComponent'
        component: LoginComponent
      }
    ]
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '', loadChildren: './Home/home.module#HomeComponent' }
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
