
export class QuestionAns {
QuesAnsId :number;
SchoolId :number;
SchoolName :number
SurveyId :number;
QuestionId:number;
Question:string;
QuestionOrder :number;
Answer :string;
AnsDateString : string;
lat : string;
long : string;
Coordinator_ApproveRejectby :number;
Coordinator_ApproveRejectDate:string;
Coordinator_RejectionId :number;
Admin_ApproveRejectby:number;
Admin_ApproveRejectDate :string;
Admin_RejectionId :number;
Status :string;
RejeText :string;
RejectionId :number;
StatusCode:string;
OStatus :string;
RejectionText:string;
}