import { Component, Injectable, OnInit } from "@angular/core";
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import{ QuestionAnsDataService } from '../Services/QuestionAnsDataService'
import{ MasterDataService } from '../Services/MasterDataService'
import{ SurveyDataService } from '../Services/SurveyDataService'
import { Survey } from '../Models/survey';
import {ExcelService} from '../Services/ExcelService';
import { UserDataService } from '../Services/UserDataService'
import { CalendarModule } from 'primeng/calendar';
import { DatePipe } from '@angular/common';

@Component({
  selector: "home",
  templateUrl: "./home.component.html"
})
@Injectable()
export class HomeComponent implements OnInit {

  public blocks: Block[];
  public states: State[];
  public clusters: Cluster[];
  public districts: District[];
  public surveys : Survey[];
  stateId:number;
  districtId:number;
  blockId:number;
  clusterId:number;
  selectedstateid : number;
  selectedDistrictId: number;
  selectedBlockId: number;
  selectedClusterId: number;
  selectedsurvey:number;
  dashBoardCount :any[];
  YetToStart:number;
  TotalAllCount:number;
  TotalschoolRegisterOnApp:number;
  UncheckCount:number;
  SurveyNotCompletedbyschool:number;
  TfsRejected:number;
  TfsApproved:number;
  TotalschoolDB:number;
  public data: any;
  public report: any;
   SelectedStateText :string;
   selectedDistrictText :string;
   selectedBlockText :string;
   selectedClusterText :string;
   selectedsurveyText :string;
   selectedsurveyTextval:string;
   selectedStatusText :string;
  selectedstatus:string;
  selectedtoDate :Date;
  selectedfrmDate : Date;
  fromDate:string;
  toDate :string;
  public menuList: any[];
  userName:string;
  userType:string;
  filterStateId:number=0;
  public inputs: any[] = [{

    Serveyname:'',
    Status:'',
    StateName:'',
    DistrictName:'',
    BlockName:'',
    ClusterName:''
   }];

   percRegistersonApp:number;
   cities: any[];
   grades: any[];


  constructor(
    private excelService:ExcelService,
    public blockDataService : BlockDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService,
    public clusterDataService : ClusterDataService,
    public questionAnsDataService : QuestionAnsDataService,
    public surveyDataService : SurveyDataService,
    public userDataService : UserDataService,
    public calendarModule : CalendarModule,
    private datePipe: DatePipe,
    private masterDataService: MasterDataService
  ) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;
    this.filterStateId = currentUser.StateId;


    this.loadstates();
    this.loadsurveys();
    // this.loadCity(1);
    this.loadGrades();
    //this.selectedsurvey=1;
    this.selectedstatus="0";

    this.inputs.push({
      Serveyname :'',
      Status :'',
      StateName :'',
      DistrictName :'',
      BlockName :'',
      ClusterName :'',
    });
    this.selectedfrmDate = null;
    this.selectedtoDate = null;

   //this.selectedSurveyValue(1);
   // alert(this.selectedsurvey);
  }

  ngOnInit() {
    //this.loadMenuList(1);
    this.percRegistersonApp = 0;

    this.SelectedStateText ="All";
    this.selectedDistrictText ="All";;
    this.selectedBlockText ="All";
    this.selectedClusterText ="All";
    this.selectedsurveyText ='  ';
    this.selectedStatusText ="All";

  }

  ClearDateFields()
  {
    this.selectedfrmDate=null;
    this.selectedtoDate=null;
  }
  onSelectMethod(event) {
    let d = new Date(Date.parse(event));
    this.selectedfrmDate=d;
    }

  onSelectMethodDate(event) {
    let d = new Date(Date.parse(event));
    this.selectedtoDate=d;
   }


  loadsurveys():void{
    this.selectedsurvey=0;
    this.surveys =[];

    this.surveyDataService.getSurveys(this.userType,'A').subscribe(res=>{
      this.surveys=res;
      this.surveys.sort(function (a, b) {
        var A = a.SurveyName.toUpperCase();
        var B = b.SurveyName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }

      });

      for(let result of this.surveys){
       // this.selectedsurvey = result.SurveyId;
       // alert(this.selectedsurvey);
       this.getDashboardCount(0,0,0,0,this.selectedsurvey,null,null);

        return;
      }
     // alert(this.selectedsurvey);
      this.surveyDataService.getSurveyById(this.selectedsurvey).subscribe(res=>{
      this.selectedsurveyText=res.SurveyName;
      });
    });

  }

  loadGrades():void {
    this.masterDataService.getGrades().subscribe(res => {
      this.grades = res
      console.log('this.grades', this.grades);

    })
  }

  loadCity(stateId: any):void {
    this.masterDataService.getDistrict(stateId).subscribe(res => {
      console.log('loadCity(stateId', res);
      this.cities = res;
      console.log('cities', this.cities);
    })
  }

  loadstates(): void {
      this.states = [];
      this.selectedstateid=0;
      this.selectedDistrictId = 0;
      this.selectedBlockId = 0;
      this.selectedClusterId  = 0;
      let statesList: State[];

    this.masterDataService.getStates().subscribe(res => {
      this.states = res;
      console.log('this.states', this.states);

    })

    //  this.stateDataService.getStates().subscribe(res => {
    //   statesList = res;


    //   if(this.filterStateId>0)
    //   {
    //     this.states = statesList.filter(e=>e.StateId==this.filterStateId);
	  //     this.selectedstateid =this.filterStateId;
    //     this.loaddistricts(this.filterStateId.toString());
    //   }
    //   else{
    //     this.states = statesList;
    //   }
    //   this.states.sort(function (a, b) {
    //     var A = a.StateName.toUpperCase();
    //     var B = b.StateName.toUpperCase();
    //     let comparison = 0;
    //     if (A > B) {
    //       return  comparison = 1;
    //    }
    //    else if (A < B) {
    //       return comparison = -1;
    //     }

    //   });
    // });
  }

  loaddistricts(value:string): void {
    this.districts = [];

    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId  = 0;
    this.stateId = parseInt(value);
      this.clusters = [];
      this.blocks=[];
     this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
      this.districts = res;
      this.districts.sort(function (a, b) {
        var A = a.DistrictName.toUpperCase();
        var B = b.DistrictName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }

      });
    });
  }

    onOptionsSelected(event:any){
      // this.loaddistricts(event.target.value);
      this.loadCity(event.target.value);
      this.SelectedStateText = event.target.options[event.target.options.selectedIndex].text;
      }

 onDistrictSelected(event:any){
  this.loadblocks(event.target.value);
  this.selectedDistrictText = event.target.options[event.target.options.selectedIndex].text;
}

onBlockSelected(event:any){
  this.loadclusters(event.target.value);
  this.selectedBlockText = event.target.options[event.target.options.selectedIndex].text;
}

selectedSurveyValue(event:any) {
  //this.selectedsurveyText = "";
  if(event.target.value!=0){
  this.selectedsurveyText = event.target.options[event.target.options.selectedIndex].text;
  }
}

selectedStatusValue(event: any) {
  //this.selectedStatusText ="";
  if(event.target.value!=0){
  this.selectedStatusText = event.target.options[event.target.options.selectedIndex].text;
 // alert(this.selectedStatusText);
}}

selectedClusterValue(event: any) {
 // this.selectedClusterText ="";
  if(event.target.value!=0){
  this.selectedClusterText = event.target.options[event.target.options.selectedIndex].text;

}}

  loadblocks(value:string): void {
      this.blocks = [];

    this.selectedBlockId = 0;
    this.selectedClusterId  = 0;
      this.districtId = parseInt(value);
      this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
      this.blocks = res;
      this.blocks.sort(function (a, b) {
        var A = a.BlockName.toUpperCase();
        var B = b.BlockName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }

      });
    });
  }

  loadclusters(value:string): void {
     this.clusters = [];
     this.selectedClusterId  = 0;
      this.blockId= parseInt(value);
     this.clusterDataService.getClusterByblockId(this.blockId).subscribe(res => {
      this.clusters = res;
      this.clusters.sort(function (a, b) {
        var A = a.ClusterName.toUpperCase();
        var B = b.ClusterName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }

      });
    });
  }

  // getDashboardCount(selectedStateId:number,selectedDistrictId:number,selectedBlockId:number,selectedClusterId:number,
  //   selectedsurvey:number,selectedFrmDate:Date,selectedToDate:Date){
  //   this.dashBoardCount = [] ;
  //   if(selectedFrmDate==null)
  //   {
  //     selectedFrmDate= new Date("2001-01-01");
  //   }
  //   if(selectedToDate==null)
  //   {
  //     selectedToDate =new Date();
  //   }
  //   this.fromDate= this.datePipe.transform(selectedFrmDate,"yyyy-MM-dd");
  //   this.toDate=this.datePipe.transform(selectedToDate,"yyyy-MM-dd");

  //   if(this.filterStateId>0 && selectedStateId==0)
  //   {
  //     selectedStateId = this.filterStateId;
  //   }


  //   this.questionAnsDataService.getDashboardCount(selectedStateId,selectedDistrictId,selectedBlockId,selectedClusterId,
  //     selectedsurvey,this.userType,this.fromDate,this.toDate).subscribe(
  //   res=>{this.dashBoardCount=res;
  //     for(let result of this.dashBoardCount){
  //        this.YetToStart = result.YetToStart;
  //        this.TotalAllCount = result.TotalAllCount;
  //        this.TotalschoolRegisterOnApp = result.TotalschoolRegisterOnApp;
  //        this.UncheckCount = result.UncheckCount;
  //        this.SurveyNotCompletedbyschool = result.SurveyNotCompletedbyschool;
  //        this.TfsRejected = result.TfsRejected;
  //        this.TfsApproved = result.TfsApproved;
  //        this.TotalschoolDB = result.TotalschoolDB;

  //        this.percRegistersonApp = ((this.TotalschoolRegisterOnApp / this.TotalschoolDB) * 100);
  //     }
  //   }
  //   );
  // }

  getDashboardCount(selectedStateId:number,selectedDistrictId:number,selectedBlockId:number,selectedClusterId:number,
    selectedsurvey:number,selectedFrmDate:Date,selectedToDate:Date){
    this.dashBoardCount = [] ;
    if(selectedFrmDate==null)
    {
      selectedFrmDate= new Date("2001-01-01");
    }
    if(selectedToDate==null)
    {
      selectedToDate =new Date();
    }
    this.fromDate= this.datePipe.transform(selectedFrmDate,"yyyy-MM-dd");
    this.toDate=this.datePipe.transform(selectedToDate,"yyyy-MM-dd");

    if(this.filterStateId>0 && selectedStateId==0)
    {
      selectedStateId = this.filterStateId;
    }

    let dashboardData = {
      "SurveyId": selectedsurvey,
      "Stateid": selectedStateId,
      "DistrictId": selectedDistrictId,
      "Gender": selectedBlockId,
      "GradeId": selectedClusterId,
      "FromDate": this.fromDate,
      "ToDate": this.toDate
    }

    this.masterDataService.getDashboardCount(dashboardData).subscribe(res=>{
      this.dashBoardCount=res;
      console.log('-home this.dashBoardCount', this.dashBoardCount)
      console.log('-home this.dashBoardCount', typeof this.dashBoardCount)
      for(let result of Object.keys(this.dashBoardCount)){
        console.log('result', result)
        console.log('this.dashBoardCount[result]', this.dashBoardCount[result])
        if (this.dashBoardCount.hasOwnProperty(result)) {
          console.log(result + " -> " + this.dashBoardCount[result]);
        }
        switch(result) {
          case 'YetToStart': this.YetToStart = this.dashBoardCount[result]; break;
          case 'TotalAllCount': this.TotalAllCount = this.dashBoardCount[result]; break;
          case 'TotalstudentRegisterOnApp': this.TotalschoolRegisterOnApp = this.dashBoardCount[result]; break;
          case 'UncheckCount': this.UncheckCount = this.dashBoardCount[result]; break;
          case 'SurveyNotCompletedbyschool': this.SurveyNotCompletedbyschool = this.dashBoardCount[result]; break;
          case 'TfsRejected': this.TfsRejected = this.dashBoardCount[result]; break;
          case 'TfsApproved': this.TfsApproved = this.dashBoardCount[result]; break;
          case 'TotalstudentDB': this.TotalschoolDB = this.dashBoardCount[result]; break;
          default: break;
        }
        this.percRegistersonApp = ((this.TotalschoolRegisterOnApp / this.TotalschoolDB) * 100);
        // if(result == 'YetToStart') {
        //   this.YetToStart = this.dashBoardCount[result]
        // }
        //  this.YetToStart = result.YetToStart;
        //  this.TotalAllCount = result.TotalAllCount;
        //  this.TotalschoolRegisterOnApp = result.TotalschoolRegisterOnApp;
        //  this.UncheckCount = result.UncheckCount;
        //  this.SurveyNotCompletedbyschool = result.SurveyNotCompletedbyschool;
        //  this.TfsRejected = result.TfsRejected;
        //  this.TfsApproved = result.TfsApproved;
        //  this.TotalschoolDB = result.TotalschoolDB;

        //  this.percRegistersonApp = ((this.TotalschoolRegisterOnApp / this.TotalschoolDB) * 100);
      }
    }
    );
  }

  loadQuestionAnsList(selectedstatus :string,selectedstateid :number,selectedDistrictId :number,
    selectedBlockId:number,selectedClusterId:number,SelectedSurveyId:number): void {
      this.data = [];

      this.inputs=[];
      this.inputs.push({
        Serveyname :'',
        Status :'',
        StateName :'',
        DistrictName :'',
        BlockName :'',
        ClusterName :'',
      });
      //console.log(this.SelectedDis)
      this.inputs.push({
        Serveyname :this.selectedsurveyText,
        Status : this.selectedStatusText,
        StateName :this.SelectedStateText,
        DistrictName :this.selectedDistrictText,
        BlockName :this.selectedBlockText,
        ClusterName :this.selectedClusterText,
      });

      let selectedfrmDatecon ;
      if(this.selectedfrmDate==null)
      {
       selectedfrmDatecon = new Date("1900-01-01");
      }
      let selectedTodatecon ;
      if(this.selectedtoDate==null)
      {
        selectedTodatecon =new Date();
      }
      this.fromDate= this.datePipe.transform(selectedfrmDatecon,"yyyy-MM-dd");
      this.toDate=this.datePipe.transform(selectedTodatecon,"yyyy-MM-dd");

    this.questionAnsDataService.getquestionList().subscribe(res => {
      this.data = res;
      this.report = [];
      this.report.push({
        Question:'',
        Answer :'',
        AnsDateString : '',
        Status :'',
        RejeText :''
       });
      if(this.data.length>0){
       for(let i=0;i<this.data.length;i++){
         this.report.push({
             Question:this.data[i].SchoolUID,
             Answer :this.data[i].SchoolName,
             AnsDateString : this.data[i].Status,
             Status :this.data[i].RejectedQues,
             RejeText :this.data[i].RejeText,
            });
          }
          this.excelService.exportAsExcelFile(this.report,this.inputs,'School Report',[	'School UID',	'School Name',	'Status',	'Rejected Questions' ],['Survey Name',	'Status','State Name',	'District Name',	'Block Name',	'Cluster Name'] );

        }

    });

  }

}
