import { Component, Injectable, OnInit } from "@angular/core";
import { State } from '../Models/state';
import { StateDataService } from "../Services/StateDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateManageComponent } from './state-manage.component';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: "state",
  templateUrl: "./state.component.html"
})
@Injectable()
export class StateComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public states: State[];
  bsModalRef: BsModalRef;
  message: string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public stateDataService: StateDataService
  ) { }

  ngOnInit() {

    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e=>e.Menuname==='State'); //("Block");  
    this.displayFlag =menul.Menuname;
    if(this.displayFlag =='State')
    {
      this.loadStates();
    }
    
  }

  refresh() {
    this.loadStates();
  }


  loadStates(): void {
    this.isLoading = true;
    this.states = [];
    if (this.listFormatTableExists) {
      this.states = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.stateDataService.getStates().subscribe(res => {
      this.states = res;
     
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[0, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
   
  }

  onAdd(){
    const initialState = {
      title: "Add a new State",
      StateId:0
    };
    this.bsModalRef = this.modalService.show(StateManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadStates();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit State",
      StateId: id
    };
    this.bsModalRef = this.modalService.show(StateManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadStates();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.message="";
    this.stateDataService.Delete(id).subscribe(
      data => {
        let stateSaveResult1: StateSaveResult = data;
        if (stateSaveResult1.IsSuccessFull) {
          this.message = "";
          this.loadStates();
        }
        else {
          this.message = stateSaveResult1.ErrorMessage;
        }
        

      }
  );
  }
}
