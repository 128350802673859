import { Component, OnInit } from '@angular/core';
import { School } from '../Models/school';
import { QuestionAns } from '../Models/questionAns';
import { QuestionAnsImageList } from '../Models/questionAnsImageList';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { StateSaveResult } from '../Models/stateSaveResult';
import { SchoolDataService } from '../Services/SchoolDataService'
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService'
import { AdminapprovalManageQuComponent } from './adminapproval-manage-qu.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { parse } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { request } from 'http';


@Component({
  selector: 'adminapproval-manage',
  templateUrl: 'adminapproval-manage.component.html'
})

export class AdminApprovalManageComponent implements OnInit {
  public quesAns: QuestionAns[];
  public questionAnsImageList: QuestionAnsImageList[];
  //public galleriaModule :GalleriaModule;
  public scholDetails: School;
  title: string;
  surveyId: number;
  StudentId: number;
  surveyIdstr: string;
  quesAnsId1: number;
  schoolId: number;
  StudentIdstr: string;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  //public onClose: Subject<boolean>;
  isSaving: boolean = false;
  isLoading = false;
  listTable;
  listFormatTableExists: boolean = false;
  bsModalRef: BsModalRef;
  questionAnsImageList1: QuestionAnsImageList[];
  stateIdFP: number;
  ClusterId: number;
  blockId: number;
  districtID: number;
  stateIdq: string;
  districtIdQ: string;
  clusterIdQ: string;
  clusterIdFP: number;
  blockIdQ: string;
  blockIdFP: number;
  statusIdQ: string;
  selectedSurveyIDQ: string;
  selectedSurveyID: number;
  //orStatus :string;
  menuList: any[];
  displayFlag: string = "";
  studentName: string = "";

  constructor(private modalService: BsModalService,
    public schooldataservices: SchoolDataService,
    public questionAnsDataServices: QuestionAnsDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public galleriaModule: GalleriaModule, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      this.studentName = params['Name'];

    });

    //alert(this.studentName);

  }

  ngOnInit() {
    this.message = "";



    this.displayFlag = "";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e => e.Menuname === 'Admin'); //("Block");
    this.displayFlag = menul.Menuname;
    if (this.displayFlag == 'Admin') {

      this.fillQuestionList();
    }

  }

  getTheColor(statusClr) {
    if (statusClr == "CR" || statusClr == "AR")
      return "red";
    else if (statusClr == "AA" || statusClr == "CA")
      return "#006400";
  }

  getDisplay() {
    if (this.surveyId == 2)
      return "table-cell";
    else return "none";
  }

  fillQuestionList() {
    this.surveyIdstr = this.activatedRoute.snapshot.queryParamMap.get("SurveyId");
    this.StudentIdstr = this.activatedRoute.snapshot.queryParamMap.get("StudentId");
    this.schoolId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("SchoolId"));

    this.StudentId = parseInt(this.StudentIdstr);
    this.surveyId = parseInt(this.surveyIdstr);

    this.isLoading = true;
    //this.sh = new Cluster();
    this.isLoading = true;
    this.quesAns = [];
    if (this.listFormatTableExists) {
      this.quesAns = [];
      this.listTable.destroy();
      this.listTable.clear();
    }


    this.questionAnsDataServices.getquestionAnsByStudentId(this.StudentId, this.surveyId).subscribe(res => {
      this.quesAns = res;
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[0, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });

    this.schooldataservices.getSchoolById(this.schoolId).subscribe(res => {
      this.scholDetails = res;
      this.isLoading = false;
    });

  }

  onBack() {
    this.router.navigate(['/adminApproval'], { queryParams: { setBackFlag: 'Back' } });
  }

}
