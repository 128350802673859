import { Component, Injectable, OnInit } from "@angular/core";
import { Survey } from '../Models/survey';
import { SurveyDataService } from "../Services/SurveyDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SurveyManageComponent } from './survey-manage.component';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: "survey",
  templateUrl: "./survey.component.html"
})
@Injectable()
export class SurveyComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public surveys: Survey[];
  bsModalRef: BsModalRef;
  message: string;
  userName:string;
  userType:string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public surveyDataService: SurveyDataService
  ) { }

  ngOnInit() {

    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e=>e.Menuname==='Survey'); //("Block");  
    this.displayFlag =menul.Menuname;
   
    if(this.displayFlag =='Survey')
    {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.userName = currentUser.UserId;
        this.userType = currentUser.UserType;
        this.loadSurveys(this.userType);
     }
  }

  refresh() {
    this.loadSurveys(this.userType);
  }

  loadSurveys(surveyType :string): void {
    this.isLoading = true;
    this.surveys = [];
    if (this.listFormatTableExists) {
      this.surveys = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    //alert(surveyType);

    this.surveyDataService.getSurveys(this.userType,'A').subscribe(res => {
      this.surveys = res;
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[2, "desc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onAdd(){
    const initialState = {
      title: "Add a new Survey",
      SurveyId:0
    };
    this.bsModalRef = this.modalService.show(SurveyManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadSurveys(this.userType);
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit Survey",
      SurveyId: id
    };
    this.bsModalRef = this.modalService.show(SurveyManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadSurveys(this.userType);
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.surveyDataService.Delete(id).subscribe(
      data => {
        let stateSaveResult1: StateSaveResult = data;
        if (stateSaveResult1.IsSuccessFull) {
          this.message = "";
          this.loadSurveys(this.userType);
        }
        else {
          this.message = stateSaveResult1.ErrorMessage;
        }

      }
    );
  }
}
