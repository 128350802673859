import { Component, OnInit } from '@angular/core';
import { Survey } from '../Models/survey';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { UserDataService } from '../Services/UserDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { UserApp } from '../Models/userApp';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { District } from '../Models/district';
import { State } from '../Models/state';

@Component({
  selector: 'user-manage',
  templateUrl: 'user-manage.component.html',
  })

export class UserManageComponent implements OnInit {
  userApp: UserApp;
  title: string;
  public states: State[];
  public districts: District[];
  id:number;
  UserId:number;
  closeBtnName: string;
  userIdModify: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  surveyStDate = new Date();
  surveyEnDate = new Date();
  surveyDatestring :string;
  Mode : string;
  roleList : any;
  stateId :number;
  SelectedRole: string;

  constructor(public bsModalRef: BsModalRef, 
    public userDataService : UserDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService
    ){
      this.loadstates();
      this.SelectedRole = "0";
     }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userIdModify = currentUser.UserId;
    
    if(this.UserId == 0)
    {
      this.userApp = new UserApp();
      this.userApp.UserId =0;
      this.userApp.FirstName="";
      this.userApp.LastName="";
      this.userApp.Email="";
      this.userApp.Password="";
      this.userApp.RoleId=0;
      this.userApp.Status="";
      this.userApp.UserType="0";
      this.userApp.StateId=0;
      this.userApp.DistrictId=0;
    }
    else{
      this.fillUserdetails();
    }
    
  }

  Clear() : void{
    this.message = "";
    this.onClose = new Subject();
    
      this.userApp = new UserApp();
      this.userApp.UserId =0;
      this.userApp.FirstName="";
      this.userApp.LastName="";
      this.userApp.Email="";
      this.userApp.Password="";
      this.userApp.RoleId=0;
      this.userApp.Status="";
      this.userApp.CreatedBy=0;
      this.userApp.ModifiedBy=0;
      this.userApp.UserType="0"; 
      this.userApp.StateId=0;
      this.userApp.DistrictId=0;
  }

  fillUserdetails() {
    this.isLoading = true;
    this.userApp = new UserApp();
      this.userApp.UserId =0;
      this.userApp.FirstName="";
      this.userApp.LastName="";
      this.userApp.Email="";
      this.userApp.Password="";
      this.userApp.RoleId=0;
      this.userApp.Status="";
      this.userApp.UserType="0";
      this.userApp.StateId=0;
      this.userApp.DistrictId=0;
      this.SelectedRole="0";
    this.userDataService.getUserById(this.UserId).subscribe(res => {
      this.userApp = res;  
      this.getRoleList(this.userApp.UserType);
     // alert(this.userApp.RoleName);
      this.loaddistricts(this.userApp.StateId.toString());
      // alert(this.userApp.RoleName);
      this.SelectedRole=this.userApp.RoleName;
      // alert(this.SelectedRole);
    //  alert(this.userApp.RoleName);
      this.isLoading = false;
    });
  }

  
  
  onOptionsSelected(event:any){
    this.getRoleList(event.target.value);
    }

    loadstates(): void {
      this.isLoading = true;
      this.states = [];
       this.stateDataService.getStates().subscribe(res => {
        this.states = res;
        this.isLoading = false;    
        this.states.sort(function (a, b) {
          var A = a.StateName.toUpperCase();
          var B = b.StateName.toUpperCase();
          let comparison = 0;
          if (A > B) {
            return  comparison = 1;
         } else if (A < B) {
            return comparison = -1;
          }
          
        });  
      });
    }

    onOptionsRoleSelected(event:any){
           this.SelectedRole = "0";
        if(event.target.value!="0"){
          this.SelectedRole = event.target.options[event.target.options.selectedIndex].text;
       }
      // alert(this.SelectedRole);
      }
  
    onOptionsStateSelected(event:any){
      this.loaddistricts(event.target.value);
      }
    loaddistricts(value:string): void {
      this.isLoading = true;
      this.districts = [];
      this.stateId = parseInt(value);
       this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
        this.districts = res;
        this.districts.sort(function (a, b) {
          var A = a.DistrictName.toUpperCase();
          var B = b.DistrictName.toUpperCase();
          let comparison = 0;
          if (A > B) {
            return  comparison = 1;
         } else if (A < B) {
            return comparison = -1;
          }
          
        });
        this.isLoading = false;      
      });
    }
  
  
    
  validate(): string {
    this.message = "";
    //alert(this.survey.StateIds);
      
    if (this.userApp.FirstName.trim() == "") {
      this.message = "Please enter First Name.";
      return this.message ;
    }

    if (this.userApp.LastName.trim() == "") {
      this.message = "Please enter Last Name.";
      return this.message ;
    }       
         
    if (this.Mode == "I") {   
      if (this.userApp.Email.trim() == ""){
     this.message = "Please Select Email.";
      return this.message ;
      }
    }

    if (this.Mode == "I") {   
      if (this.userApp.Password.trim() == ""){
     this.message = "Please Select Password.";
      return this.message ;
      }
    }

    
    if (this.userApp.RoleId == 0) {
      this.message = "Please Select RoleId.";
      return this.message ;
    } 

    if (this.userApp.UserType.trim() == "0") {
      this.message = "Please Select User Type.";
      return this.message ;
    }
     
    return this.message;
  }

  getRoleList(value:string) : void {   
   // alert(value);
    this.roleList = [];
    //this.SelectedRole ="0";
    // this.userApp.RoleId=0;
     this.userDataService.getRoleListByType(value).subscribe(res => {
      this.roleList = res;      
    });
  }

  Save(): void{
    this.message = "";
    this.message = this.validate();
 
    if (this.message.length <= 0) {
      this.isSaving = true;
      this.userApp.CreatedBy=parseInt(this.userIdModify);
      this.userApp.ModifiedBy=parseInt(this.userIdModify);

      this.userDataService.Save(this.userApp).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving Survey`;
        },
      );
    }
    
  }
}
