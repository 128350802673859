import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Survey } from "../Models/survey";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";

@Injectable()
export class SurveyDataService {
    constructor(private http: HttpClient) {
        survey: Survey;
    }
    public getSurveys(SurveyType:string , Typeofregistration:string ): Observable<Survey[]> {
       // return this.http.get<Survey[]>(config.appUrl + '/Survey/GetAllSurvey/' + SurveyType  +'/' +Typeofregistration);
       return this.http.get<Survey[]>(config.appUrl + '/Survey/GetAllSurveyBackend');
    }
    public getSurveyById(id: number): Observable<Survey> {
        return this.http.get<Survey>(config.appUrl + '/Survey/' + id);
    }

    public getSurveyStates(id:number): Observable<any[]> {
        return this.http.get<any[]>(config.appUrl + '/Survey/GetSurveyStatesById/' + id);
    }
    
    public Save(survey: Survey): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/survey',
            JSON.stringify(survey),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<StateSaveResult> {
        return this.http.delete<StateSaveResult>(config.appUrl + '/survey/' + id);


    }
}