import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Survey } from "../Models/survey";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { Student } from "../Models/student";

@Injectable()
export class StudentDataService {
    constructor(private http: HttpClient) {
        student: Student;
    }
    public getStudents(): Observable<any[]> {
       return this.http.get<any[]>(config.appUrl + '/Student/GetStudents');
    }
    public getStudentByStudentIdveyById(id: number): Observable<any> {
        return this.http.get<any>(config.appUrl + '/Student/GetStudentByStudentId?studentId=' + id);
    }

    // public getSurveyStates(id:number): Observable<any[]> {
    //     return this.http.get<any[]>(config.appUrl + '/Survey/GetSurveyStatesById/' + id);
    // }

    public Save(student: any): Observable<any> {
        let resp: Observable<any> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<any>(
            config.appUrl+ '/Student/SaveStudent',
            JSON.stringify(student),
            httpOptions
        );
        return resp;
    }

    public updateStudent(student: any): Observable<any> {
      let resp: Observable<any> = null;
      const httpOptions = {
          headers: new HttpHeaders({
              "Content-Type": "application/json"
          })
      };
      resp = this.http.post<any>(
          config.appUrl+ '/Student/UpdateStudent',
          JSON.stringify(student),
          httpOptions
      );
      return resp;
    }

    // public Delete(id: number): Observable<StateSaveResult> {
    //     return this.http.delete<StateSaveResult>(config.appUrl + '/survey/' + id);


    // }
}
