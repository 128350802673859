import { environment } from "src/environments/environment";

const host = {
  //local: "http://lvicbitalendd01.ingramcontent.com:8099"
  //local: "http://localhost:63014"
  local: environment.URL
};

const config = {
  appUrl: host.local + "/api/",
  AuthUrl: host.local + "/api/auth",
  AuthUrlApp: host.local + "/api/Auth",
};

export default config;
