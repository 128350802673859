import { Component, OnInit } from '@angular/core';
import { School } from '../Models/school';
import { QuestionAns } from '../Models/questionAns';
import { QuestionAnsImageList } from '../Models/questionAnsImageList';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { StateSaveResult } from '../Models/stateSaveResult';
import { SchoolDataService } from '../Services/SchoolDataService'
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService'
import { CoordinateapprovalManageQuComponent } from './coordinateapproval-manage-qu.component';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { parse } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';


@Component({
  selector: 'coordinateapproval-manage',
  templateUrl: 'coordinateapproval-manage.component.html'
})

export class CoordinateApprovalManageComponent implements OnInit {
  public quesAns: QuestionAns[];
  public questionAnsImageList : QuestionAnsImageList[];
  //public galleriaModule :GalleriaModule;
  public scholDetails: School;
   title: string;
  surveyId:number;
  SchlId:number;
  surveyIdstr:string;
  quesAnsId1:number;
  SchlIdstr:string;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  //public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  listTable;
  listFormatTableExists: boolean = false;  
  bsModalRef: BsModalRef;
    questionAnsImageList1: QuestionAnsImageList[];  
    ClusterId :number;
    blockId : number;
    districtID : number;
    stateIdq :string;
    districtIdQ :string;
    stateIdFP :number;
    clusterIdQ:string;
    clusterIdFP:number;
    blockIdQ:string;
    blockIdFP:number;
    statusIdQ:string; 
    selectedSurveyIDQ : string;
    selectedSurveyID:number;    
    menuList:any[];
    displayFlag:string ="";
  

  constructor(private modalService: BsModalService,
    public schooldataservices : SchoolDataService,
    public questionAnsDataServices : QuestionAnsDataService,
    private activatedRoute:ActivatedRoute,
    private router :Router,
    public galleriaModule: GalleriaModule,) { }

  ngOnInit() {
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='Co-ordinator'); //("Block");  
     this.displayFlag =menul.Menuname;
   
     if(this.displayFlag =='Co-ordinator')
     {
        this.message = "";
        this.fillQuestionList();
     }
   
  }

  getTheColor(statusClr) {
    if(statusClr=="CR" || statusClr=="AR") 
    return "red";
    else if(statusClr=="AA" || statusClr=="CA")
    return "#006400";
    
    // could be better written, but you get the idea
}

getDisplay()
  {
    if(this.surveyId==2)
    return "table-cell";
    else return "none";
  }

  fillQuestionList()
  {
    this.surveyIdstr = this.activatedRoute.snapshot.queryParamMap.get("surveyId");  
    this.SchlIdstr = this.activatedRoute.snapshot.queryParamMap.get("SchlId");
    this.SchlId = parseInt(this.SchlIdstr);
    this.surveyId = parseInt(this.surveyIdstr);
        this.isLoading = true;
    //this.sh = new Cluster();
    this.isLoading = true;
    this.quesAns = [];
    if (this.listFormatTableExists) {
      this.quesAns = [];
      this.listTable.destroy();
      this.listTable.clear();
    }   

    this.questionAnsDataServices.getquestionAnsBySchoolId(this.SchlId,this.surveyId,'C').subscribe(res => {
      this.quesAns = res;
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[2, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });

     this.schooldataservices.getSchoolById(this.SchlId).subscribe(res => {
      this.scholDetails = res;
      this.isLoading = false;
    });
    
  }

  onBack()
  {
      this.router.navigate(['/coordinateApproval'], { queryParams: { setBackFlag: 'Back' } });
  
  }
  
}
