import { Component, OnInit } from '@angular/core';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { School } from '../Models/school';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { isNumeric } from 'rxjs/util/isNumeric';
import { strictEqual } from 'assert';
import { stringify } from 'querystring';
import { IDropdownSettings  } from 'ng-multiselect-dropdown';
import {SurveyDataService} from '../Services/SurveyDataService'
import { Survey } from '../Models/survey';

@Component({
  selector: 'school-manage',
  templateUrl: 'school-manage.component.html',
  styleUrls:["school.css"]
})

export class SchoolManageComponent implements OnInit {
  school: School;
  public blocks: Block[];
  public states: State[];
  public clusters: Cluster[];
  public districts: District[];
  public surveys: Survey[];
  selectedItems = [];
  selectStates : any[];
  selectedSurveyIds:string;
  selectedsurveyMapping :any[];
  title: string;
  id:number;
  stateId:number;
  SchoolId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  districtId:number;
  blockId:number;
  userName:string;
  userType:string;
  schoolTpe : any[];
  dropdownSettings:IDropdownSettings;
  dropdownList = [];
  count :number;
  filterStateId:number=0;

  constructor(public bsModalRef: BsModalRef, 
    public blockDataService : BlockDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService,
    public clusterDataService : ClusterDataService,
    public schoolDataService : SchoolDataService,
    public surveyDataService : SurveyDataService,
    ){ 
    
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;
    this.filterStateId = currentUser.StateId;

    this.fillUserType(); 
    this.loadstates();  
    this.loadSurvey(currentUser.UserType);
  
     }

  ngOnInit() {    
    this.message = "";
    this.onClose = new Subject();  
    
    //this.selectedItems.push({'SurveyId' :9,'SurveyName':"Survey2"});
   // this.selectedItems.push({'SurveyId' :10,'SurveyName':"survey"});
    if(this.SchoolId==0)
    {
      this.selectedItems=[];
      this.school = new School();
      this.school.SchoolUID = "";
      this.school.SchoolName="";
      this.school.StateId=0;
      this.school.DistrictId=0;
      this.school.BlockId=0;
      this.school.ClusterId=0;
      this.school.SCHCAT_DESC="";
      this.school.SchoolType="0";
      this.school.SchoolSubType="0";
      this.school.Pincode="";
      this.school.STDCodePhone1="";
      this.school.Phone1="";
      this.school.Mobile1="";
      this.school.STDCodePhone2="";
      this.school.Phone2="";
      this.school.Mobile2="";
      this.school.Email1="";
      this.school.TotalStaff=0;
      this.school.NoOfFemale=0;      
      this.school.NoOfMale=0;
      this.school.TypeOfRegistration="S";
      this.school.ResponsiblePerson="";
      this.school.Designation="";
      this.school.SchoolContactPersons = [{
        SchoolContactPersonsId: 0,
        SchoolId: 0,
        FirstName: '',
        MiddleName: '',
        LastName:'',
        Email:'',
        Mobile:'',
        WhatsAppNo:'',
        CreatedBy:0
      }];
      this.school.SchoolMappingSurvey = [{
        SchoolMappingSurveyId: 0,
        SchoolId: 0,
        SurveyId:0,
        SurveyName:'',
        CreatedBy:0        
      }];

    }
    else{
      this.fillSchoolById();
    }  
         
  }

  fillUserType()
  {
    this.schoolTpe = [];
    
    if(this.userType=="U")
    {
      this.schoolTpe.push({'Type': "U",'TypeName' :"Urban"});
    }
    if(this.userType=="R")
    {
      this.schoolTpe.push({'Type': "R",'TypeName' :"Rural"});
    }
    if(this.userType=="RO")
    {
      this.schoolTpe.push({'Type': "RO",'TypeName' :"Other"});
    }    
    if(this.userType=="B")
    {
      this.schoolTpe.push({'Type': "U",'TypeName' :"Urban"});
      this.schoolTpe.push({'Type': "R",'TypeName' :"Rural"});
      this.schoolTpe.push({'Type': "RO",'TypeName' :"Other"});
    }

  }

  fillSchoolById() {
    //this.isLoading = true;
    this.school = new School();
    this.schoolDataService.getSchoolById(this.SchoolId).subscribe(res => {
      this.school = res;
     // this.isLoading = false;
     
      this.loaddistricts(this.school.StateId.toString());
      this.loadblocks(this.school.DistrictId.toString());
      this.loadclusters(this.school.BlockId.toString());
      this.school.DistrictId=this.school.DistrictId;
      
     // this.isLoading = false;  
      this.selectedItems = [];
      for(let result of this.school.SchoolMappingSurvey){
        this.selectedItems.push({'SurveyId' :result.SurveyId,'SurveyName':result.SurveyName});            
      }     
    });
  }
  
  loadstates(): void {
    //this.isLoading = true;
    this.states = [];
    let statesList: State[];

     this.stateDataService.getStates().subscribe(res => {
      statesList = res;
    if(this.filterStateId>0)
    {
      this.states = statesList.filter(e=>e.StateId==this.filterStateId);
    }
    else
    {
      this.states = statesList;
    }
      //this.isLoading = false;      
    });
  }

  loaddistricts(value:string): void {
   // this.isLoading = true;
    this.districts = [];
    this.stateId = parseInt(value);
    if(this.school.SchoolId<=0)
    {
      this.school.DistrictId=0;
      this.school.BlockId=0;
      this.school.ClusterId=0;
      this.clusters = [];
      this.blocks=[];
     }
     this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
      this.districts = res;
      //this.isLoading = false;      
    });
  }

    onOptionsSelected(event:any){
      this.loaddistricts(event.target.value);
      }

 onDistrictSelected(event:any){
  this.loadblocks(event.target.value);
  
}

onBlockSelected(event:any){
  this.loadclusters(event.target.value);
}

  loadblocks(value:string): void {
    //this.isLoading = true;
    this.blocks = [];
    if(this.school.SchoolId<=0)
    {
      this.school.BlockId=0;
      this.school.ClusterId=0;
    }
    this.districtId = parseInt(value);
     this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
      this.blocks = res;
     // this.isLoading = false;      
    });
  }

  loadclusters(value:string): void {
   // this.isLoading = true;
    this.clusters = [];
    if(this.school.SchoolId<=0)
    {
       this.school.ClusterId=0;
    }
    this.blockId= parseInt(value);
     this.clusterDataService.getClusterByblockId(this.blockId).subscribe(res => {
      this.clusters = res;
     // this.isLoading = false;      
    });
  }
 
  validate(): string {
    this.message = "";
    if (this.school.SchoolType.trim() == "0") {
      this.message = "Please select Type.";
            return this.message ;
    }
    if (this.school.SchoolSubType.trim() == "0") {
      this.message = "Please select Sub Type.";
            return this.message ;
    }

     if (this.selectedItems.length<=0) {
      this.message = "Please select Survey.";
            return this.message ;
    }    

    if (this.school.SchoolUID.trim() == "") {
      this.message = "Please enter SchoolUID.";
            return this.message ;
    }

    if (this.school.SchoolName.trim() == "") {
      this.message = "Please enter School Name.";
            return this.message ;
    }
    
    if (this.school.StateId.toString().trim() == "0") {
      this.message = "Please select State.";
            return this.message ;
    }
    
    if (this.school.DistrictId.toString().trim() == "0") {
      this.message = "Please select District.";
            return this.message ;
    }

    if (this.school.Pincode.toString().trim() != "") {
      if(!isNumeric(this.school.Pincode))
      {this.message = "Please enter valid numeric Pincode.";
            return this.message ;}
    }

    if (this.school.STDCodePhone1.toString().trim() != "") {
      if(!isNumeric(this.school.STDCodePhone1))
      {this.message = "Please enter valid numeric STDCode1.";
            return this.message ;}
    }

    if (this.school.Phone1.toString().trim() != "") {
      if(!isNumeric(this.school.Phone1))
      {this.message = "Please enter valid numeric Phone1.";
            return this.message ;}
    }

    if (this.school.Mobile1.toString().trim() != "") {
      if(!isNumeric(this.school.Mobile1))
      {this.message = "Please enter valid numeric Mobile1.";
            return this.message ;}
    }
    if (this.school.STDCodePhone2.toString().trim() != "") {
      if(!isNumeric(this.school.STDCodePhone2))
      {this.message = "Please enter valid numeric STDCode2.";
            return this.message ;}
    }

    if (this.school.Phone2.toString().trim() != "") {
      if(!isNumeric(this.school.Phone2))
      {this.message = "Please enter valid numeric Phone2.";
            return this.message ;}
    }

    if (this.school.Mobile2.toString().trim() != "") {
      if(!isNumeric(this.school.Mobile2))
      {this.message = "Please enter valid numeric Mobile2.";
            return this.message ;}
    }

    if (this.school.TotalStaff.toString().trim() != "") {
      if(!isNumeric(this.school.TotalStaff))
      {this.message = "Please enter valid numeric value for Total Staff.";
            return this.message ;}
    }

    if (this.school.NoOfMale.toString().trim() != "") {
      if(!isNumeric(this.school.NoOfMale))
      {this.message = "Please enter valid numeric value for No Of Male.";
            return this.message ;}
    }

    if (this.school.NoOfFemale.toString().trim() != "") {
      if(!isNumeric(this.school.NoOfFemale))
      {this.message = "Please enter valid numeric value for No Of Female.";
            return this.message ;}
    }

    for(let result of this.school.SchoolContactPersons){
      if(!isNumeric(result.Mobile) && result.Mobile!='')
      {
        this.message = "Please enter valid numeric value for School Contact Persons mobile.";
            return this.message ;
      }
      if(!isNumeric(result.WhatsAppNo) && result.Mobile!='')
            {this.message = "Please enter valid numeric value for School Contact Persons WhatsAppNo.";
                  return this.message ;}

    }

    
    return this.message;
  }

  addResorces() {
    //console.log(this.district.OtherResources);
      this.school.SchoolContactPersons.push({
     SchoolContactPersonsId: 0,
     SchoolId:this.SchoolId,
     FirstName:'',
     MiddleName: '',
     LastName: '',
     Email:'',
     Mobile:'',
     WhatsAppNo:'',
     CreatedBy:0
    });
  }

  removeResorces(i: number) {    
    //alert(this.school.SchoolContactPersons.
    this.school.SchoolContactPersons.splice(i, 1);
  }

  Save(): void{
    this.message = "";
    this.message = this.validate();
    let userId = Number.parseInt(this.userName);
    this.school.CreatedBy = Number.parseInt(this.userName);
    this.selectedSurveyIds="";
    this.count=0;
    for(let result of this.selectedItems){
      if(this.count== 0)
      {
      this.selectedSurveyIds = result.SurveyId;
    }else{
      this.selectedSurveyIds = this.selectedSurveyIds +',' + result.SurveyId;

    }
    this.count=this.count+1;
    }
    this.school.SchoolSurveyIds  = this.selectedSurveyIds; 

      if (this.message.length <= 0) {
       this.isSaving = true;
      this.schoolDataService.Save(this.school).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;      
       },
        (error: any) => {
          this.message = `Error while saving School`;
        },
      );
    }
    
  }

  onSchooltypeSelected(event:any){
    this.loadSurvey(event.target.value);
    }

  loadSurvey(value:string): void {
   // this.isLoading = true;
    this.surveys = [];
    //this.selectedItems=[];
     this.surveyDataService.getSurveys(value,'A').subscribe(res => {
      this.surveys = res;
     // this.isLoading = false;      
      this.surveys.sort(function (a, b) {
        var A = a.SurveyName.toUpperCase();
        var B = b.SurveyName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }        
      });
      //this.dropdownList = this.states;
    this.dropdownList = [];
    for(let result of this.surveys){
       this.dropdownList.push({'SurveyId' :result.SurveyId,'SurveyName':result.SurveyName});   
   }
   this.dropdownSettings  = {
   singleSelection: false,
   idField: 'SurveyId',
   textField: 'SurveyName',
   selectAllText: 'Select All',
   unSelectAllText: 'UnSelect All',
   itemsShowLimit: 3,
   allowSearchFilter: true
 };
    }       
    );
  }

  onItemSelect(item: any) {
    console.log(this.selectedItems);    
   }
 
   onSelectAll(items: any) {
     this.selectedItems =[];
     this.selectedItems=items;

   }

}
