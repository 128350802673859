import { Component, Injectable, OnInit } from "@angular/core";
import { State } from '../Models/state';
import { StateDataService } from "../Services/StateDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';
import { School } from '../Models/school';
import { SchoolDataService } from "../Services/SchoolDataService";
import { AnimationKeyframesSequenceMetadata } from "@angular/animations";

@Component({
  selector: "SchoolDetials",
  templateUrl: "./scoolsdetails.component.html"
})
@Injectable()
export class ScoolsDetailsComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public school: any[];
  bsModalRef: BsModalRef;
  message: string;
  menuList:any[];
  displayFlag:string ="";
 public  schoolUID : string ="";
  public data: any;

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public schoolDataService: SchoolDataService
  ) { }

  ngOnInit() {

    
  }


  getDetails(schoolUID :string): void {
      this.isLoading = true;
    this.school = [];
     
    if (this.listFormatTableExists) {
      this.school = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    //alert(this.pageSizeSelected);
    this.schoolDataService.getSchoolByUid(this.schoolUID).subscribe(res => 
      {
      this.school = res;
     // debugger;    
     if(this.school.length>0)
     { 
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          ordering:false,
          searching:false,
          paging:false,
          info:false

        });
       // this.setNextPage(pageIndex);
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    }
      this.isLoading = false;
    });
  }



}
