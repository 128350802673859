import { Component, OnInit } from '@angular/core';
import { School } from '../Models/school';
import { SchoolDataReport } from '../Models/schoolReport';
import { SchoolQuestionReport} from '../Models/schoolReport';
import { QuestionAns } from '../Models/questionAns';
import { QuestionAnsImageList } from '../Models/questionAnsImageList';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { StateSaveResult } from '../Models/stateSaveResult';
import { SchoolDataService } from '../Services/SchoolDataService'
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService'
import{SchoolManageQuComponent} from '../Reports/school-manage-qu.component'
//import { CoordinateapprovalManageQuComponent } from './coordinateapproval-manage-qu.component';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { parse } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { HttpClient, HttpHeaders,HttpRequest, HttpEvent, HttpResponse,HttpClientModule,HttpEventType } from "@angular/common/Http";
import {ExcelService} from '../Services/ExcelService';
import * as jspdf from 'jspdf'; 
 
import * as html2canvasWrong from "html2canvas"
var html2canvas = html2canvasWrong as any as (element: HTMLElement, options?: Partial<html2canvasWrong.Options>) => Promise<HTMLCanvasElement>

@Component({
  selector: 'schoolreport-manage',
  templateUrl: 'schoolreport-manage.component.html'
})

export class SchoolreportManageComponent implements OnInit {
  public quesAns: QuestionAns[];
 // public schoolReport: SchoolReport[];
  public questionAnsImageList : QuestionAnsImageList[];
  //public galleriaModule :GalleriaModule;
  public scholDetails: School;
   title: string;
  surveyId:number;
  SchlId:number;
  surveyIdstr:string;
  quesAnsId1:number;
  SchlIdstr:string;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  listTable;
  listFormatTableExists: boolean = false;  
  bsModalRef: BsModalRef;
  questionAnsImageList1: QuestionAnsImageList[];

  public schoolReport: SchoolDataReport[] = [{
    SchoolName :'',
    SchoolUID:'',
    StateName:'',
    DistrictName:'',
    BlockName:'',
    ClusterName:'',
    Village:'',
    Pincode:'',
    MobileNo:'',
    PhoneNo:'',
  
    
   }];

   public schoolqueReport: SchoolQuestionReport[] = [{
   
    Question:'',
    Answer :'',
    AnsDateString : '',
   // QuestionOrder :0,
    Status :'',
    RejeText :'',
   }];
    
  constructor(private modalService: BsModalService,
    private excelService:ExcelService,
    private router :Router, 
    public schooldataservices : SchoolDataService,
    public questionAnsDataServices : QuestionAnsDataService,
    private activatedRoute:ActivatedRoute,
    public galleriaModule: GalleriaModule,private http: HttpClient,) {
      this.surveyIdstr = this.activatedRoute.snapshot.queryParamMap.get("surveyId");  
    this.SchlIdstr = this.activatedRoute.snapshot.queryParamMap.get("SchlId");
    this.SchlId = parseInt(this.SchlIdstr);
    this.surveyId = parseInt(this.surveyIdstr);
    }

  ngOnInit() {
    this.message = "";
     //his.onClose = new Subject();  
   /* this.surveyIdstr = this.activatedRoute.snapshot.queryParamMap.get("surveyId");
  this.activatedRoute.queryParamMap.subscribe(queryParams => {
    this.surveyIdstr = queryParams.get("surveyId");
    console.log(this.surveyIdstr);
  }); */

    //if(this.SchlId!=0)
    //{
      //alert(this.surveyIdstr);
      this.onClose = new Subject();
      this.fillQuestionList();
    //}
     this.schoolReport= [{
      SchoolName :'',
      SchoolUID:'',
      StateName:'',
      DistrictName:'',
      BlockName:'',
      ClusterName:'',
      Village:'',
      Pincode:'',
      MobileNo:'',
      PhoneNo:'',
    
     }];
  }

  getTheColor(statusClr) {
    if(statusClr=="CR" || statusClr=="AR") 
    return "red";
    else if(statusClr=="AA" || statusClr=="CA")
    return "#006400";
    
    // could be better written, but you get the idea
}

  fillQuestionList()
  {
    this.surveyIdstr = this.activatedRoute.snapshot.queryParamMap.get("surveyId");  
    this.SchlIdstr = this.activatedRoute.snapshot.queryParamMap.get("SchlId");
    this.SchlId = parseInt(this.SchlIdstr);
    this.surveyId = parseInt(this.surveyIdstr);
    
    this.isLoading = true;
    //this.sh = new Cluster();
    this.isLoading = true;
    this.quesAns = [];
    if (this.listFormatTableExists) {
      this.quesAns = [];
      this.listTable.destroy();
      this.listTable.clear();
    }

    

    this.questionAnsDataServices.getquestionAnsBySchoolId(this.SchlId,this.surveyId,'C').subscribe(res => {
      this.quesAns = res;
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[1, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });

     this.schooldataservices.getSchoolById(this.SchlId).subscribe(res => {
      this.scholDetails = res;
      this.isLoading = false;
    });
    
  }

  onImageView(QuesAnsId : number ,RejectionId:number ,QuestionId:number,StatusCode:string,SchoolId:number,SurveyId:number){
    
    const initialState = {
      title: "Approve / Reject",
      quesAnsId : QuesAnsId,
      rejectionId : RejectionId,
      questionId : QuestionId,
      statusCode :StatusCode,
      SurveyId:SurveyId,
      SchoolId:SchoolId

     };
    this.bsModalRef = this.modalService.show(SchoolManageQuComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.fillQuestionList();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  OnDownload(){
   
    //this.isLoading = true;
     this.questionAnsDataServices.downloadFile(this.SchlId,this.surveyId).subscribe(
      data => {
                 const downloadedFile = (data);
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = 'School.xlsx';
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
    //this.isLoading = false;
        
      },
    );
    
  }

  exportAsXLSX() {
     
    this.schooldataservices.getSchoolById(this.SchlId).subscribe(res => {
      this.scholDetails = res;
      this.isLoading = false;
    });
    this.questionAnsDataServices.getquestionAnsBySchoolId(this.SchlId,this.surveyId,'C').subscribe(res => {
      this.quesAns = res;
      this.schoolReport.push({
        SchoolName :this.scholDetails.SchoolName,
        SchoolUID:this.scholDetails.SchoolUID,
        StateName:this.scholDetails.StateName,
        DistrictName:this.scholDetails.DistrictName,
        BlockName:this.scholDetails.BlockName,
        ClusterName:this.scholDetails.ClusterName,
        Village:this.scholDetails.Village,
        Pincode:this.scholDetails.Pincode,
        MobileNo:this.scholDetails.Mobile1,
        PhoneNo:this.scholDetails.Mobile2,
      
       });
     for(let i=0;i<this.quesAns.length;i++){
    this.schoolqueReport.push({
       
        Question:this.quesAns[i].Question,
        Answer :this.quesAns[i].Answer,
        AnsDateString : this.quesAns[i].AnsDateString,
      //  QuestionOrder :this.quesAns[i].QuestionOrder,
        Status :this.quesAns[i].Status,
        RejeText :this.quesAns[i].RejeText,
       });
     }

   this.excelService.exportAsExcelFile(this.schoolqueReport,this.schoolReport,'Approval / Rejection For Coordinator',[	'Question',	'Answer',	'Ans Date',	'Status',	'RejeText' ],['School Name',	'SchoolUID','State Name',	'District Name',	'Block Name',	'Cluster Name',	'Village',	'Pin code',	'Mobile No',	'Phone No'] );
  });
  }

  exportAsPDF(){
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });  
  }

  onBack()
  {
   this.router.navigate(['/schoolreports'], { queryParams: { setBackFlag: 'Back' } });
  }
  
}
