import { Component, Injectable, OnInit } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdminApprovalManageComponent } from './adminapproval-manage.component';
import { MessageService } from 'primeng/api';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { Router, ActivatedRoute } from "@angular/router";
import { stringify } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { SurveyDataService } from '../Services/SurveyDataService';
import { ThrowStmt } from '@angular/compiler';
import { DatePipe } from '@angular/common';
import { ReportParameters } from '../Models/reportParameters';
import { CalendarModule } from 'primeng/calendar';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import * as FileSaver from 'file-saver';

import { CollapseModule, PaginationModule } from 'ngx-bootstrap';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PaginationService } from '../pagination.service';
import { MasterDataService } from '../Services/MasterDataService'
import { Survey } from '../Models/survey';
import { Student } from "../Models/student";
@Component({
  selector: "adminapproval",
  templateUrl: "./adminapproval.component.html"
})
@Injectable()
export class AdminapprovalComponent implements OnInit {
  // public galleriaModule: GalleriaModule;

  //const FileSaver = require('file-saver');
  public blocks: Block[];
  public states: State[];
  public trainers: Student[];
  public clusters: Cluster[];
  public districts: District[];
  listTable;
  listFormatTableExists: boolean = false;
  stateId: number;
  districtId: number;
  blockId: number;
  clusterId: number;
  status: string;
  SchoolId: number;
  SurveyId: number;
  public data: any;
  //bsModalRef: BsModalRef;

  selectedstatus: string; selectedstateid: number; selectedDistrictId: number;
  selectedtrainerid: number;
  selectedBlockId: number;
  selectedClusterId: number; SelectedSurveyId: number;
  stateIdq: string;
  distQ: string;
  clusterIDQ: string;
  blockIDQ: string;
  surveyIDQ: string;
  statusIDQ: string;
  isBackcnt: number;
  isLoading = false;
  userName: string;
  userType: string;
  totalCount: number;
  selectedtoDate: Date;
  selectedfrmDate: Date;
  setBackFlag: string;
  fromDate: string;
  toDate: string;
  certificateStatus: number;
  viewCertificateFlag: number;
  viewCertificatePath: string;
  certificateData: any[];
  //private datePipe: DatePipe;
  public reportParameters: ReportParameters;
  menuList: any[];
  displayFlag: string = "";
  filterStateId: number = 0;

  displayFlagSearch: string = "";
  noData = false;
  minCount: number;
  maxCount: number;
  pageStart: number;
  sortColumn: string;
  sortOrder: string;
  searchValue: string;
  pageSizeSelected: number;
  pageIndex: number;
  numPages: number;
  SelectedStateText: string;
  selectedDistrictText: string;
  selectedBlockText: string;
  selectedClusterText: string;
  selectedsurveyText: string;
  selectedsurveyTextval: string;
  selectedStatusText: string;
  public surveys: Survey[];
  selectedsurvey: number;

  cities: any[];
  grades: any[];

  public PageCount = 1;
  pager: any = {};

  constructor(
    // private modalService: BsModalService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    public galleriaModule: GalleriaModule,
    public blockDataService: BlockDataService,
    public districtDataService: DistrictDataService,
    public stateDataService: StateDataService,
    public clusterDataService: ClusterDataService,
    public questionAnsDataService: QuestionAnsDataService,
    public surveyDataService: SurveyDataService,
    public activatedRoute: ActivatedRoute,
    public calendarModule: CalendarModule,
    private router: Router,
    public paginationService: PaginationService,
    private masterDataService: MasterDataService

  ) {
    this.states = [];
    this.trainers = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];

    this.selectedstatus = '0';
    this.selectedstateid = 0;
    this.selectedtrainerid = 0;
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.SelectedSurveyId = 0;
    this.totalCount = 0;

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;

    this.maxCount = 0;
    this.minCount = 0;
    this.pageIndex = 1;
    this.pageSizeSelected = 12;
    this.pageStart = 0;
    this.sortColumn = 'SchoolName';
    this.sortOrder = 'ASC';
    this.searchValue = '';

    this.loadstates();
    this.loadsurveys();
    this.loadGrades();
    this.loadtrainers();

    //  this.loadQuestionAnsList(0,0,0,0,0,null,null);
  }

  ngOnInit() {

  

    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    this.SelectedStateText = "All";
    this.selectedfrmDate = null;
    this.selectedtoDate = null;
    this.filterStateId = 0;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.filterStateId = currentUser.StateId;

    this.displayFlag = "";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e => e.Menuname === 'Admin'); //("Block");  
    this.displayFlag = menul.Menuname;

    let repParameter = JSON.parse(localStorage.getItem('repParameterAdmin'));
    this.setBackFlag = "";
    this.setBackFlag = this.activatedRoute.snapshot.queryParamMap.get("setBackFlag");
    if(this.setBackFlag=="Back")
    {
      //alert(localStorage.getItem('dashboardData'));
     // this.loadQuestionAnsListBack();
    }
    // this.loadQuestionAnsList();

  }

  // // loadQuestionAnsListBack(
  // // ): void {
  // //   this.data = [];
  // //   this.totalCount = 0;
  // //   this.isLoading = true;
  // //   //alert(selectedtrainerid);

  // //   let dashboardData = JSON.parse(localStorage.getItem('dashboardData'));
    
  // //   if (this.listFormatTableExists) {
  // //     // this.surveys = [];
  // //     this.data = [];
  // //     this.listTable.destroy();
  // //     this.listTable.clear();
  // //   }
  // //   this.questionAnsDataService.getquestionListSurvey(dashboardData).subscribe(res => {
  // //     this.data = res;
  // //     if (this.data.length == 0) {

  // //       this.noData = true;
  // //       this.isLoading = false;

  // //     }
  // //     else {        
  // //       this.displayFlagSearch = this.data[0].TotalCount;
  // //       this.totalCount = this.data[0].TotalCount;
  // //       //alert(this.totalCount);
  // //       this.setPage(this.pageIndex);
  // //       setTimeout(() => {

  // //         this.listTable = $("#districtTableNew").DataTable({

  // //           lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
  // //           pageLength: 12,
  // //           order: [[0, "asc"]],
  // //           destroy: true,

  // //         });
  // //         this.listFormatTableExists = true;
  // //         this.isLoading = false;
  // //       }, 0);
  // //     }

  // //   });
  // // }



  loadQuestionAnsList(selectedStateId: number, selectedDistrictId: number, selectedBlockId: number, selectedClusterId: number,
    selectedsurvey: number, selectedFrmDate: Date, selectedToDate: Date, selectedstatus: string, selectedtrainerid: string
  ): void {
    this.data = [];
    this.totalCount = 0;
    this.isLoading = true;
    //alert(selectedtrainerid);
    this.noData = false;
    let dashboardData = {
      "SurveyId": selectedsurvey,
      "Stateid": selectedStateId,
      "DistrictId": selectedDistrictId,
      "Gender": selectedBlockId,
      "GradeId": selectedClusterId,
      "FromDate": selectedFrmDate,
      "ToDate": selectedToDate,
      "Status": selectedstatus,
      "Trainer": selectedtrainerid
    }
    //localStorage.setItem('dashboardData', JSON.stringify(dashboardData));

    if (this.listFormatTableExists) {
      // this.surveys = [];
      this.data = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.isLoading = true;
    this.questionAnsDataService.getquestionListSurvey(dashboardData).subscribe(res => {
      this.data = res;
      //alert(this.data.length);
      // // this.noData = false;
      // // this.isLoading = false;

      // // if (this.data.length == 0) {

      // //   this.noData = true;
      // //   this.isLoading = false;
        
      // // }
      ////else {
        
       this.displayFlagSearch ="12"; //this.data[0].TotalCount;
        this.totalCount =12; //this.data[0].TotalCount;
        //alert(this.totalCount);
        ////this.setPage(this.pageIndex);
        setTimeout(() => {

          this.listTable = $("#districtTableNew").DataTable({

            lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
            pageLength: 12,
            order: [[0, "asc"]],
            //stateSave: true,
            destroy: true,
            // ordering: false,
            //searching: false,
            // paging: false,
            //info: false,

          });
          this.listFormatTableExists = true;
          this.isLoading = false;
        }, 0);
        this.isLoading = false;
    ////  }

    });
  }





  NextData() {
    this.pageIndex = this.pageIndex + 1;
    // this.loadQuestionAnsList();
  }

  prevData() {
    this.pageIndex = this.pageIndex - 1;
    // this.loadQuestionAnsList();
  }

  Search() {
    this.pageIndex = 1;
    // this.loadQuestionAnsList();
  }

  sortData(columnName: string, sortOrdertype: string) {

    this.sortOrder = sortOrdertype;
    this.sortColumn = columnName;
    // this.loadQuestionAnsList();
  }

  setPage(page: number) {
    this.pager = this.paginationService.getPager(this.totalCount, page, this.pageSizeSelected);
    this.pageIndex = page;
    this.maxCount = (this.pageSizeSelected) * page;
    this.minCount = ((this.pager.currentPage - 1) * (this.pageSizeSelected)) + 1;
  }

  setNextPage(page: number) {
    // this.pageIndex = this.pageIndex +1;
    this.pager = this.paginationService.getPager(this.totalCount, page, this.pageSizeSelected);
    this.pageIndex = page;
    // this.loadQuestionAnsList();
  }


  onPageSelected(event: any) {
    if (event.target.value != 0) {
      this.pageSizeSelected = event.target.value;
      this.pageIndex = 1;
      // this.loadQuestionAnsList();
    }

  }
  ClearDateFields() {
    this.selectedfrmDate = null;
    this.selectedtoDate = null;
  }
  onSelectMethod(event) {
    let d = new Date(Date.parse(event));
    this.selectedfrmDate = d;
  }

  onSelectMethodDate(event) {
    let d = new Date(Date.parse(event));
    this.selectedtoDate = d;
  }

  getTheColor(statusClr) {
    if (statusClr == "CR" || statusClr == "AR")
      return "red";
    else if (statusClr == "AA" || statusClr == "CA")
      return "#006400";
  }
  getDashboardCount(selectedStateId: number, selectedDistrictId: number, selectedBlockId: number, selectedClusterId: number,
    selectedsurvey: number, selectedFrmDate: Date, selectedToDate: Date) {

  }
  loadsurveys(): void {
    this.surveys = [];

    this.surveyDataService.getSurveys(this.userType, 'A').subscribe(res => {
      this.surveys = res;
      this.surveys.sort(function (a, b) {
        var A = a.SurveyName.toUpperCase();
        var B = b.SurveyName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });

      for (let result of this.surveys) {
        this.selectedsurvey = result.SurveyId;
        // alert(this.selectedsurvey);
        //this.getDashboardCount(0,0,0,0,this.selectedsurvey,null,null);

        return;
      }
      // alert(this.selectedsurvey);
      this.surveyDataService.getSurveyById(this.selectedsurvey).subscribe(res => {
        this.selectedsurveyText = res.SurveyName;
      });
    });

  }

  loadGrades(): void {
    this.masterDataService.getGrades().subscribe(res => {
      this.grades = res
     // console.log('this.grades', this.grades);

    })
  }

  loadtrainers(): void {
    this.data = [];
    this.trainers = [];

    this.isLoading = true;
    let trainerList: Student[];

    this.stateDataService.getTrainers().subscribe(res => {
      trainerList = res;
      this.trainers = trainerList;
      this.isLoading = false;
    });
  }




  loadstates(): void {
    this.data = [];
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.isLoading = true;
    let statesList: State[];

    this.stateDataService.getStates().subscribe(res => {
      statesList = res;
      if (this.filterStateId > 0) {
        this.states = statesList.filter(e => e.StateId == this.filterStateId);
        this.selectedstateid = this.filterStateId;
        this.loaddistricts(this.filterStateId.toString());
      }
      else {
        this.states = statesList;
      }
      this.states.sort(function (a, b) {
        var A = a.StateName.toUpperCase();
        var B = b.StateName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  loaddistricts(value: string): void {
    this.districts = [];
    // this.data = [];
    this.stateId = parseInt(value);
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.clusters = [];
    this.blocks = [];
    this.isLoading = true;
    this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
      this.districts = res;
      this.districts.sort(function (a, b) {
        var A = a.DistrictName.toUpperCase();
        var B = b.DistrictName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  
  onOptionsSelected(event: any) {

    // this.loaddistricts(event.target.value);
    this.loadCity(event.target.value);
    this.SelectedStateText = event.target.options[event.target.options.selectedIndex].text;
  }
  loadCity(stateId: any): void {
    this.masterDataService.getDistrict(stateId).subscribe(res => {
      //console.log('loadCity(stateId', res);
      this.cities = res;
     // console.log('cities', this.cities);
    })
  }
  onDistrictSelected(event: any) {
    this.loadblocks(event.target.value);

  }

  onBlockSelected(event: any) {
    this.loadclusters(event.target.value);
  }

  loadblocks(value: string): void {
    // this.districtId=0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    //this.data = [];
    this.blocks = [];
    this.clusters = [];
    this.districtId = parseInt(value);
    this.isLoading = true;
    this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
      this.blocks = res;
      this.blocks.sort(function (a, b) {
        var A = a.BlockName.toUpperCase();
        var B = b.BlockName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  loadclusters(value: string): void {
    this.selectedClusterId = 0;
    //this.data = [];
    this.isLoading = true;
    this.clusters = [];
    this.blockId = parseInt(value);
    this.clusterDataService.getClusterByblockId(this.blockId).subscribe(res => {
      this.clusters = res;
      this.clusters.sort(function (a, b) {
        var A = a.ClusterName.toUpperCase();
        var B = b.ClusterName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return comparison = 1;
        } else if (A < B) {
          return comparison = -1;
        }

      });
      this.isLoading = false;
    });
  }

  public GenerateCertificate(SchlId: number, surveyId: number) {
    this.isLoading = true;
    this.questionAnsDataService.getCertificategeneration(SchlId, surveyId).subscribe(res => {
      this.certificateStatus = res;
      if (this.certificateStatus > 0) {
        this.ViewCertificate(SchlId);
        alert("Certificate has been generated and notify to the school on Mobile App")
      }
      else
        alert("some issue has been facing to generate certificate")
      this.isLoading = false;
    });
  }

  public ViewCertificate(SchlId: number) {
    //this.isLoading = true;
    // alert('HI')
    this.questionAnsDataService.viewCertificategeneration(SchlId).subscribe(res => {
      this.certificateData = res;

      if (this.certificateData.length > 0) {
        // alert('HI111');
        this.viewCertificatePath = this.certificateData[0].CertificateFileName;
        this.viewCertificateFlag = 1;
      }
      // alert(this.viewCertificatePath);        
      //window.location.href=this.viewCertificatePath;
      FileSaver.saveAs(this.viewCertificatePath, "data.pdf")
      // this.isLoading = false;
    });

  }


  public gotoProductDetailsV2(url: string, SchlId: number, surveyId: number, Status_sh: string) {

    this.router.navigate(['/admineapproval-manage'], { queryParams: { SchlId: SchlId, surveyId: surveyId } });
    /*
     this.router.navigate(['/admineapproval-manage'], { queryParams: { SchlId: SchlId ,surveyId :surveyId,
     stateIdFP:this.selectedstateid,districtID:this.selectedDistrictId,blockID:this.selectedBlockId,
     clusterID:this.selectedClusterId,statusselected:this.selectedstatus,SelectedsurveyID:this.SelectedSurveyId} });
    */
  }

}
