import { Component, OnInit } from '@angular/core';
import { Question } from '../Models/question';
import { QuestionAns } from '../Models/questionAns';
import { QuestionAnsImageList } from '../Models/questionAnsImageList';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { StateSaveResult } from '../Models/stateSaveResult';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { QuestionDataService } from '../Services/QuestionDataService';
import { GalleriaModule } from 'primeng/galleria';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { parse } from 'querystring';
import { isNumeric } from 'rxjs/util/isNumeric';
import {Router ,ActivatedRoute} from "@angular/router";
import { stringify } from 'querystring';


@Component({
    selector: 'school-manageweightage-qu',
    templateUrl: 'school-manageweightage-qu.component.html'
  })

export class SchoolManageWightageQuComponent implements OnInit {
   public rejections: any[];
  public questionAnsImageList: QuestionAnsImageList[];
  title: string;
  questionText : Question;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  QuesAnsId :number;
  questionId:number;
  quesAnsId : number;
  QuestionId :number;
  images :any[];
  questionAns :QuestionAns;
  SurveyId:number;
  SchoolId:number;
  surveyIdstr:string;
  quesAnsId1:number;
  SchlIdstr:string;
  readioSelected:number;
  rejectionId:number;
  statusCode:string;
  //queList :QuestionAnsImageList;

  constructor(public bsModalRef: BsModalRef, 
    public questionAnsDataService : QuestionAnsDataService,
    public questionDataService : QuestionDataService,
    public galleriaModule : GalleriaModule,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    ){}

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();    

    if(this.questionId!=0)
    {
      this.fillImages();
        this.fillData();     
    }
    
  }  
  
  fillData() {     
    this.questionText = new Question();  
    this.rejections=[];
     this.isLoading = true;
     this.questionDataService.getQuestionById(this.questionId,1).subscribe(res => {
       this.questionText = res;       
      this.isLoading = false; 
            
     });    
 
     this.questionAnsDataService.getRejectionList().subscribe(res => {
      this.rejections = res;
     // this.isLoading = false;
     this.readioSelected = this.rejectionId;
     console.log(this.readioSelected)
    });
    this.isLoading = false;
   }

   fillImages()
   {
      this.questionAnsImageList = [];
      this.images = [];
      this.isLoading = true;
     this.questionAnsDataService.getQuestionImageList(this.quesAnsId).subscribe(res => {
       this.questionAnsImageList = res;
       this.images = [];
       for(let result of this.questionAnsImageList){
        // this.images.push(this.result.ImagePath);
       // alert(result.ImagePath);
         // this.images.push({'source' :result.ImagePath,'alt':result.QuesAnsId,'title':result.QuesAnsImageId});   
         //alert(this.images);
         this.images.push(result.ImagePath);
      }
       
     }); 
     this.isLoading = false;
   }

  validate(status : string,rejectionIdform :number): string{
    this.message="";
   if(status=='CR' && (rejectionIdform <=0 || (!isNumeric(rejectionIdform)) ))
    {
      this.message = "Please Select Rejection.";
      return this.message ;
    }
    return this.message;
  }


  onSave(status :string,rejectionIdform : number): void{
    this.message = "";
   
    this.message = this.validate(status,rejectionIdform);
 
    if (this.message.length <= 0) {
      this.isSaving = true;
      this.questionAns = new QuestionAns();
      this.questionAns.Coordinator_ApproveRejectby = 1;
      this.questionAns.Status=status;
      this.questionAns.Coordinator_RejectionId=rejectionIdform;
      this.questionAns.QuesAnsId = this.quesAnsId;
      this.questionAns.SurveyId =this.SurveyId;
      this.questionAns.SchoolId = this.SchoolId;
      this.questionAnsDataService.approveRej(this.questionAns).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving Question`;
        },
      );
    }
    
  }


  
  
 // onCloseModel(){
    //this.onClose.next(true);
   // this.bsModalRef.hide();
    
  //}

  
   
}