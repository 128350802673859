import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterDataService } from 'src/app/Services/MasterDataService';
import { StudentDataService } from 'src/app/Services/StudentDataService';

@Component({
  selector: 'app-student-new',
  templateUrl: './student-new.component.html',
  styleUrls: ['./student-new.component.css'],
  providers: [StudentDataService]
})
export class StudentNewComponent implements OnInit {
  studentId: number;
  firstName: string;
  lastName: string;
  designation: any;
  schoolId: any;
  gradeId: any;
  academicYear: any;
  gender: any;
  mobileNo: string;
  mediumOfSchool: any;
  address1: string;
  address2: string;
  pincode: string;
  isMigrated: any;
  migrationState: string;
  email: string;
  communityAddress: string;

  designations = [
    {value: '0',id:0},
    {value: '1',id:1}
  ];

  schoolIds = [
    {value: '0',id:0},
    {value: '1',id:1}
  ];

  gradeIds = [
    {value: '0',id:0},
    {value: '1',id:1}
  ];

  genders = [
    {value: 'Male',id:"Male"},
    {value: 'Female',id:"Female"},
    {value: 'Others',id:"Others"}
  ];

  academicYears = [
    {value: '2020-2021',id:"2020-2021"},
    {value: '2021-2022',id:"2021-2022"},
    {value: '2022-2023',id:"2022-2023"}
  ];

  mediumsOfSchool = [
    {value: 'English',id:"English"},
    {value: 'Hindi',id:"Hindi"}
  ];

  isMigrateds = [
    {value: 'True',id:true},
    {value: 'False',id:false}
  ];

  constructor(
    private studentDataService: StudentDataService,
    private masterDataService: MasterDataService,
    private router: Router) {
      // this.designation = this.designations[1];
      this.loadDesignationsData();
      // this.schoolId = this.schoolIds[1];
      this.loadSchoolsData();
      // this.gradeId = this.gradeIds[1];
      this.loadGradesData();
      this.academicYear = this.academicYears[1];
      this.gender = this.genders[1];
      this.mediumOfSchool = this.mediumsOfSchool[1];
      this.isMigrated = this.isMigrateds[1];
    }

  ngOnInit() {

  }

  loadSchoolsData() {
    this.masterDataService.getSchools().subscribe(res => {
      this.schoolIds = res;
      this.schoolId = this.schoolIds[0];
      console.log('this.schoolIds', this.schoolIds);

    })
  }

  loadGradesData() {
    this.masterDataService.getGrades().subscribe(res => {
      this.gradeIds = res;
      this.gradeId = this.gradeIds[0];
      console.log('this.gradeIds', this.gradeIds);

    })
  }

  loadDesignationsData() {
    this.masterDataService.getDesignations().subscribe(res => {
      this.designations = res;
      this.designation = this.designations[0];
      console.log('this.designations', this.designations);

    })
  }

  onDesignationSelected(evt) {
    console.log('evt', evt);
  }

  onSchoolIdSelected(evt) {
    this.schoolId = this.schoolIds[0];
    console.log('evt', evt);
  }

  onGradeIdSelected(evt) {
    this.gradeId = this.gradeIds[0]
    console.log('evt', evt);
  }

  onAcademicYearSelected(evt) {
    console.log('evt', evt);
  }

  onGenderSelected(evt) {
    console.log('evt', evt);
  }

  onMediumOfSchoolSelected(evt) {
    console.log('evt', evt);
  }

  onIsMigratedSelected(evt) {
    console.log('evt', evt);
  }

  addStudent(
    studentId: number,
    firstName: string,
    lastName: string,
    designation: any,
    schoolId: any,
    gradeId: any,
    academicYear: any,
    gender: any,
    mobileNo: number,
    mediumOfSchool: any,
    address1: string,
    address2: string,
    pincode: number,
    isMigrated: any,
    migrationState: string,
    email: string,
    communityAddress: string) {
    let savedData = {
      StudentId: studentId || 0,
      FirstName: firstName || 'AAA',
      LastName: lastName || 'LLL',
      DesignationId: designation.id || 1,
      SchoolId: schoolId.id || 1,
      GradeId: gradeId.id || 1,
      AcademicYear: academicYear.id || '2022-2023',
      Gender: gender.id || 'male',
      MobileNo: mobileNo || "9921192734",
      MediumOfSchool: mediumOfSchool.id || "English",
      Address1: address1 || "Address 1",
      Address2: address2 || "address 2",
      PinCode: pincode || "421006",
      IsMigrated: isMigrated.id || true,
      MigrationState: migrationState || "Orissa",
      Email: email || "a@a.com",
      CommunityAddress: communityAddress || "Community Address",
      ImagePath: "null"
    }

    this.studentDataService.Save(savedData).subscribe(res => {
      console.log('this.studentDataService.Save', res);
      this.router.navigate(['/student']);
    });
  }

  clearStudent() {
    this.studentId = 0;
    this.firstName = '';
    this.lastName = '';
    this.designation = this.designations[1];
    this.schoolId = this.schoolIds[1];
    this.gradeId = this.gradeIds[1];
    this.academicYear = this.academicYears[1];
    this.gender = this.genders[1];
    this.mobileNo = '';
    this.mediumOfSchool = this.mediumsOfSchool[1];
    this.address1 = '';
    this.address2 = '';
    this.pincode = '';
    this.isMigrated = this.isMigrateds[1];;
    this.migrationState = '';
    this.email = '';
    this.communityAddress = '';
  }

  addNewStudent(event:NgForm) {
    // let ssId = newStudentForm.controls.n_studentId.value;
    // let n_fName = newStudentForm.controls.n_firstName.value;
    // console.log('addNewStudent(ssId)', ssId);
    // console.log('addNewStudent(n_fName)', n_fName);
    console.log('event', event);

  }

}
