import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoordinateApprovalManageComponent } from "./coordinateapproval-manage.component";
import { CoordinateapprovalComponent } from "./coordinateapproval.component";
//import { CoordinateapprovalManageQuComponent } from "./coordinateapproval-manage-qu.component";


const routes: Routes = [
    { path: "", component: CoordinateApprovalManageComponent }
  //  { path: "", component: CoordinateapprovalComponent }        
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class CoordinateapprovalRoutingModule { }
