import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StudentComponent } from "./student.component";
import { StudentEditComponent } from "./student-edit/student-edit.component";
import { StudentNewComponent } from "./student-new/student-new.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { ConfirmationPopoverModule } from "angular-confirmation-popover";

@NgModule({
  declarations: [StudentComponent, StudentEditComponent, StudentNewComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger", // set defaults here
    }),
  ],
})
export class StudentModule {}
