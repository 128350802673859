import { Observable } from 'rxjs';
import { Component,OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/AuthenticationService';

@Component({
    selector:"app-header",
    templateUrl:"./header.component.html"
})

export class HeaderComponent implements OnInit{

    isLoggedIn$: Observable<boolean>;
    userName : string;
    constructor(
        private authService: AuthenticationService,
        private router: Router
        ){

    }

    ngOnInit(): void{
        //this.isLoggedIn$ = this.authService.isLoggedIn;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.userName = currentUser.FirstName;
    }

    onLogout() {
        //this.authService.logout();
        this.authService.logout();
      }

    createJob(){
        this.router.navigate(['/createjob']);
    }
}
