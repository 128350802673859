import { Component, OnInit } from '@angular/core';
import { District } from '../Models/district';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { State } from '../Models/state';
import { OtherResources } from '../Models/OtherResources';

@Component({
  selector: 'district-manage',
  templateUrl: 'district-manage.component.html'
})

export class DistrictManageComponent implements OnInit {
  district: District;
  public states: State[];
  title: string;
  id:number;
  DistrictId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  //public OtherResourceslist: OtherResources
 public OtherResources: any[] = [{
   ResourceId: 0,
    DistrictId: 0,
    ResourceName: '',
    ResourceContactNo: '',
    ResourceType:''
  }];

  constructor(public bsModalRef: BsModalRef, public districtDataService : DistrictDataService,public stateDataService : StateDataService) {this.loadstates(); }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();    
    if(this.DistrictId==0)
    {
      this.district = new District();
      this.district.DistrictName = "";
      this.district.StateName="";
      this.district.CollectorName= "";
      this.district.CollectorContactNo= "";
      this.district.ZPCEOName= "";
      this.district.ZPCEOContactNo= "";
      this.district.PrimaryDEOName= "";
      this.district.PrimaryDEOContactNo= "";
      this.district.SecondaryDEOName= "";
      this.district.SecondaryDEOContactNo= "";
      this.district.CivilSurgeonName= "";
      this.district.CivilSurgeonContactNo= "";
      this.district.DHOName= "";
      this.district.DHOContactNo= "";
      this.district.NTCPNodelOfficerName= "";
      this.district.NTCPNodalOfficerContactNo= "";
      this.district.RotaryClubName= "";
      this.district.RotaryClubContactNo= "";
      this.district.LionsClubName= "";
      this.district.LionsClubContactNo= "";
      this.district.DistrictId=0;
      this.district.StateId=0;
      this.district.OtherResources = [{
         ResourceId: 0,
        DistrictId: 0,
         ResourceName: '',
         ResourceContactNo: '',
         ResourceType:''
       }];
      //this.loadstates();
    }
    else{
      this.fillDistrictById();
     
    }
    
    
  }

  Clear() : void{
    this.message = "";
    this.onClose = new Subject();    
   
      this.district = new District();
      this.district.DistrictName = "";
      this.district.StateName="";
      this.district.CollectorName= "";
      this.district.CollectorContactNo= "";
      this.district.ZPCEOName= "";
      this.district.ZPCEOContactNo= "";
      this.district.PrimaryDEOName= "";
      this.district.PrimaryDEOContactNo= "";
      this.district.SecondaryDEOName= "";
      this.district.SecondaryDEOContactNo= "";
      this.district.CivilSurgeonName= "";
      this.district.CivilSurgeonContactNo= "";
      this.district.DHOName= "";
      this.district.DHOContactNo= "";
      this.district.NTCPNodelOfficerName= "";
      this.district.NTCPNodalOfficerContactNo= "";
      this.district.RotaryClubName= "";
      this.district.RotaryClubContactNo= "";
      this.district.LionsClubName= "";
      this.district.LionsClubContactNo= "";
      this.district.DistrictId=0;
      this.district.StateId=0;
      this.district.OtherResources = [{
         ResourceId: 0,
        DistrictId: 0,
         ResourceName: '',
         ResourceContactNo: '',
         ResourceType:''
       }];
  }

  fillDistrictById() {
    this.isLoading = true;
    this.district = new District();
    this.districtDataService.getDistrictById(this.DistrictId).subscribe(res => {
      this.district = res;
     // console.log(JSON.stringify(this.district.OtherResources));
      this.isLoading = false;
    });
  }

  
  addResorces() {
    //console.log(this.district.OtherResources);
      this.district.OtherResources.push({
     ResourceId: 0,
   DistrictId: 0,
    ResourceName: '',
    ResourceContactNo: '',
   ResourceType:''
    });
  }

  removeResorces(i: number) {
    
    this.district.OtherResources.splice(i, 1);
  }
  loadstates(): void {
    this.isLoading = true;
    this.states = [];
     this.stateDataService.getStates().subscribe(res => {
      this.states = res;
      this.states.sort(function (a, b) {
        var A = a.StateName.toUpperCase();
        var B = b.StateName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }
        
      });
      this.isLoading = false;      
    });
    
  }

  validate(): string {
    this.message = "";
    if (this.district.DistrictName.trim() == "") {
      this.message = "Please enter Name";
            return this.message ;
    }

    if (this.district.StateId == 0) {
      this.message = "Please select State";
            return this.message ;
    }
   
    return this.message;
  }

  Save(): void{
    this.message = "";
    this.message = this.validate();
 
    if (this.message.length <= 0) {
      this.isSaving = false;
      this.districtDataService.Save(this.district).subscribe(
        data => {
          
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;    
        },
        (error: any) => {
          this.message = `Error while saving District`;
        },
      );
    }
    
  }
}
