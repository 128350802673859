import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
//import { QuestionAns } from "../Models/questionAns";
import { HttpClient, HttpHeaders,HttpRequest, HttpEvent, HttpResponse } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";
import { QuestionAns } from '../Models/questionAns';
import { QuestionAnsImageList } from '../Models/questionAnsImageList';
import { toDate } from '@angular/common/src/i18n/format_date';

@Injectable()
export class QuestionAnsDataService {
    constructor(private http: HttpClient) {
        //questionAns: QuestionAns;
    }
    public getquestionList(): Observable<any[]> {           
            
         return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAns');
 
       
 
     }
    public getquestionListSurvey(dashboardData: any): Observable<any[]> {           
            
       // return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAns');
//alert( JSON.stringify(dashboardData));
       let resp: Observable<any> = null;
       const httpOptions = {
         headers: new HttpHeaders({
             "Content-Type": "application/json"
         })
       };
       resp = this.http.post<any>(
         config.appUrl+ '/QuestionAns/GetQuestionAnsSurvey',
         JSON.stringify(dashboardData),
         httpOptions
     );
     return resp;

    }
    public getquestionListBak(status :string,stateid :number,DistrictId :number,BlockId:number,ClusterId:number,
        SurveyId :number,userType:string,schoolType :string,frmdate:string,toDate :string,
        pageSizeSelected:number,pageIndex:number,sortColumn:string,sortOrder:string,searchValue:string
        ): Observable<any[]> {
            
            if(searchValue=='')
            searchValue='-';
        return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAns/' +status + '/' + stateid + '/'+DistrictId 
        +'/'+BlockId + '/'+ClusterId + '/'+ SurveyId+ '/'+ userType + '/' +schoolType + '/' + frmdate + '/' + toDate
        +'/' + pageSizeSelected +'/'+pageIndex+'/'+sortColumn+'/'+sortOrder+'/'+searchValue+"/");
    }
    public getquestionList1(status :string,stateid :number,DistrictId :number,BlockId:number,ClusterId:number,
        SurveyId :number,userType:string,schoolType :string,frmdate:string,toDate :string
        
        ): Observable<any[]> {

            
        return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAns/' +status + '/' + stateid + '/'+DistrictId 
        +'/'+BlockId + '/'+ClusterId + '/'+ SurveyId+ '/'+ userType + '/' +schoolType + '/' + frmdate + '/' + toDate
        );
    }


    public getquestionListTransport(status :string,stateid :number,DistrictId :number,BlockId:number,ClusterId:number,
        SurveyId :number,userType:string,schoolType :string,frmdate:string,toDate :string): Observable<any[]> {
        return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAnsApprovalTransport/' +status + '/' + stateid + '/'+DistrictId 
        +'/'+BlockId + '/'+ClusterId + '/'+ SurveyId+ '/'+ userType + '/' +schoolType + '/' + frmdate + '/' + toDate);
    }

    public getquestionAnsBySchoolId(schoolId: number,surveyId: number,userType:string): Observable<QuestionAns[]> {
        return this.http.get<QuestionAns[]>(config.appUrl + '/QuestionAns/QuestionAnsfromSchlId/' +schoolId + '/' + surveyId +'/' + userType);
    } 
    public getquestionAnsByStudentId(studentId: number,surveyId: number): Observable<QuestionAns[]> {
        return this.http.get<QuestionAns[]>(config.appUrl + '/QuestionAns/QuestionAnsfromStudentId/' +studentId + '/' + surveyId );
    } 


    public getquestionAnsWeightageBySchoolId(schoolId: number,surveyId: number,userType:string): Observable<QuestionAns[]> {
        return this.http.get<QuestionAns[]>(config.appUrl + '/QuestionAns/QuestionAnsWeightagefromSchlId/' +schoolId + '/' + surveyId +'/' + userType);
    }
    
    public getCertificategeneration(schoolId: number,surveyId: number): Observable<number> {
        return this.http.get<number>(config.appUrl + '/QuestionAns/Certificategeneration/' +schoolId + '/' 
        + surveyId);
    } 
    
    
    public viewCertificategeneration(schoolId: number): Observable<any> {
        return this.http.get<any>(config.appUrl + '/QuestionAns/GetCertificate?schlId=' +schoolId); 
    }

    public GetQuestionAnsReports(status :string,stateid :number,DistrictId :number,BlockId:number,ClusterId:number,
        SurveyId :number,userType:string,frmdate:string,toDate :string): Observable<any[]> {
        return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAnsReports/' +status + '/' + stateid + '/'+DistrictId +'/'+BlockId + '/'+ClusterId + '/'+ SurveyId+ 
        '/'+ userType + '/' + frmdate + '/' + toDate);
    } 

    public GetQuestionAnsWeightageReports(status :string,stateid :number,DistrictId :number,BlockId:number,ClusterId:number,
        SurveyId :number,userType:string,frmdate:string,toDate :string): Observable<any[]> {
        return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetQuestionAnsReportsWeightage/' +status + '/' + stateid + '/'+DistrictId +'/'+BlockId + '/'+ClusterId + '/'+ SurveyId+ 
        '/'+ userType + '/' + frmdate + '/' + toDate);
    }

    public getRejectionList(): Observable<any[]> {
        return this.http.get<any[]>(config.appUrl + '/QuestionAns/GetRejectionList');
    }

    public getQuestionImageList(quesId: number): Observable<QuestionAnsImageList[]> {
        return this.http.get<QuestionAnsImageList[]>(config.appUrl + '/QuestionAns/GetQuestionAnsImageList/' + quesId);
    }

    
    public getDashboardCount(stateId: number,districtId:number,blockId:number,clusterId:number,surveyId:number,
        userType:string,frmdate:string,toDate :string): Observable<any> {
        return this.http.get<any>(config.appUrl + '/QuestionAns/GetDashboardCount/' + stateId + '/' 
        + districtId + '/'+ blockId +'/' +clusterId + '/' +surveyId + '/' + userType+ '/' + frmdate + '/' + toDate);
    }
    public SetapproveRejAnswer(status: string,QuesAnsId:number): Observable<any> {
       // alert(status);
        alert(config.appUrl + '/UserApp/AnswersApproveRej/' + status + '/' 
        + QuesAnsId);
        return this.http.get<any>(config.appUrl + '/UserApp/AnswersApproveRej/' + status + '/' 
        + QuesAnsId );
    }

    public RejectAnswers(status: string,QuesAnsId:number,Reason:string): Observable<StateSaveResult> {
       
       let questionAns =new QuestionAns();
       questionAns.Status=status;
       questionAns.QuesAnsId=QuesAnsId;
       questionAns.RejectionText=Reason;
       
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/UserApp/RejectAnswer',
            JSON.stringify(questionAns),
            httpOptions
        );
        return resp;
    }



    public approveRej(questionAns: QuestionAns): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/UserApp/AnswersApproveRej',
            JSON.stringify(questionAns),
            httpOptions
        );
        return resp;
    }

  
    public downloadFile(schoolId: number,surveyId: number): Observable<HttpEvent<Blob>> {
            return this.http.request(new HttpRequest(
              'Post',
              `${config.appUrl+ '/QuestionAns/ExcelSchoolReports/' +schoolId + '/' + surveyId}`,
              null,
              {
                reportProgress: true,
                responseType: 'blob'
              }));
          }

        
    public getGraphData(stateId: number,districtId:number,blockId:number,Year:number,
        month:number,reportType:string,graphType:string,monthlist:string,userType:string): Observable<any> {
        return this.http.get<any>(config.appUrl + '/QuestionAns/GetGraphData/' + stateId + '/'
         + districtId + '/'+ blockId +'/' +Year + '/' +month + '/' + reportType +'/' + graphType + '/'+ monthlist+'/'+ userType);
    }

    
}