import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { School } from "../Models/school";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";

@Injectable()
export class SchoolDataService {
    constructor(private http: HttpClient) {
        school: School;
    }
    // public getSchools(schoolType : string): Observable<School[]> {
    //     return this.http.get<School[]>(config.appUrl + '/Schools/GetAllSchools/' + schoolType);
    // }
    public getSchools(schoolType : string,pageSizeSelected:number,pageIndex:number,sortColumn:string,sortOrder:string,searchValue:string): Observable<School[]> {
        if(searchValue=='')
            searchValue='-';
             
        return this.http.get<School[]>(config.appUrl + '/Schools/GetAllSchools/' + schoolType +'/' + pageSizeSelected +'/'+pageIndex+'/'+sortColumn+'/'+sortOrder+'/'+searchValue);
    }
    public getSchoolByUid(schoolUid: string): Observable<any[]> {
        return this.http.get<any[]>(config.appUrl + '/Schools/GetSchoolByUid/' + schoolUid);
    }

    public getSchoolById(id: number): Observable<School> {
        return this.http.get<School>(config.appUrl + '/Schools/' + id);
    }

    public GetSchoolMappingSurvey(id: number): Observable<any> {
        return this.http.get<any>(config.appUrl + '/Schools/GetSchoolMappingSurvey/' + id);
    }
    
    public Save(school: School): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/Schools',
            JSON.stringify(school),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<Object> {
        return this.http.delete<Object>(config.appUrl + '/Schools/' + id);


    }
}