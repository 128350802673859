import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RolemappingComponent } from "./rolemapping.component";
//import { CoordinateapprovalManageQuComponent } from "./coordinateapproval-manage-qu.component";


const routes: Routes = [
    { path: "", component: RolemappingComponent }
   // { path: "", component: SchoolreportComponent }        
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class RolemappingRoutingModule { }
