import { Component } from "@angular/core";

@Component({
  selector: "app-home-layout",
  template: `
    <!-- <div id="content-wrapper" class="d-flex flex-column">
    <router-outlet></router-outlet>
    </div> -->

    <div id="wrapper">
      <app-menu></app-menu>

      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <app-header></app-header>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class HomeLayoutComponent {}
