import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { RolemappingRoutingModule } from "./rolemapping-routing.module";
import { RolemappingComponent } from "./rolemapping.component";


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    ConfirmationPopoverModule.forRoot({
    confirmButtonType: 'danger' // set defaults here
    }),
    RolemappingRoutingModule,
    ],
  declarations: [
    RolemappingComponent
  ],
  exports: [
    RolemappingComponent, 
    RouterModule
  ],
  providers: [],
  entryComponents: [RolemappingComponent]
})
export class RolemappingModule {}
