import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Block } from "../Models/block";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";

@Injectable()
export class BlockDataService {
    constructor(private http: HttpClient) {
        block: Block;
    }
    public getBlocks(): Observable<Block[]> {
        return this.http.get<Block[]>(config.appUrl + '/Block');
    }
    public getBlockById(id: number): Observable<Block> {
        return this.http.get<Block>(config.appUrl + '/Block/' + id);
    }

    public getBlockByDistId(districtId: number): Observable<Block[]> {
        return this.http.get<Block[]>(config.appUrl + '/Block/GetBlockByDistId/' + districtId);
    }

    
    public Save(block: Block): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/block',
            JSON.stringify(block),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<StateSaveResult> {
        return this.http.delete<StateSaveResult>(config.appUrl + '/block/' + id);


    }
}