import { Component, Injectable, OnInit } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
//import { SchoolreportManageComponent } from './schoolreport-manage.component';
import { MessageService } from 'primeng/api';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import {Router,ActivatedRoute} from "@angular/router";
import { stringify, parse } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { SurveyDataService } from '../Services/SurveyDataService';
import {ExcelService} from '../Services/ExcelService';
import {ReportParameters} from '../Models/reportParameters';
import { CalendarModule } from 'primeng/calendar';
import {MatDatepickerModule, MatInputModule,MatNativeDateModule} from '@angular/material';
import { DatePipe } from '@angular/common';
import { ChartOptions, ChartType, ChartDataSets, ChartTitleOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label,Color } from 'ng2-charts';
import { IDropdownSettings  } from 'ng-multiselect-dropdown';
import { Title } from "@angular/platform-browser";
import{ MasterDataService } from '../Services/MasterDataService';

@Component({
  selector: "graphsreport.component",
  templateUrl: "./graphsreport.component.html"
})
@Injectable()

export class GraphsreportComponent implements OnInit {
 // public galleriaModule: GalleriaModule;
  isLoading = false;
  public blocks: Block[];
  public reportParameters : ReportParameters;
  public states: State[];
  public districts: District[];
  selectedItems = [];
  selectStates : any[];
  public years :any[];
  public barDataApproved : any[];  
 // public lineChartData: Array<number> =
  public barDataRejected : any[];
  public barDataUnchecked : any[];
  public displayBardata:any[]= [{
    data:'',
    label:''
   }];;
  public months: any[] = [{
    value:'',
    Text:''
   }];
  stateId:number;
  districtId:number;
  blockId:number;
  clusterId:number;
  status:string;
  public dataFromservice: any;
  userName:string;
  userType:string;
  message:string;
  readioSelected:string;
  selectedstateid:number;
  selectedDistrictId :number;
  selectedBlockId :number;
  selectedYear :number;
  selectedMonth :number;
  selectedReportType : string;
  SelectedStateText:string;
  SelectedDistrictText:string;
  SelectedBlockText:string;
  SelectedMonthText:string;
  selectedMonthIds:string;
  SelectedReportText:string;
  reportClock:string; 
  filterStateId:number=0;
  graphsSelections:any[]= [{
    value:'',
    Text:''
   }];
   dropdownSettings:IDropdownSettings;
    public pieChartOptions: ChartOptions = {
      responsive: true,
      legend: {
        position: 'top',
      },  
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      }
    };
    public pieChartLabels: Label[]=[];
    public pieChartData: number[]= [];

    public pieChartType: ChartType = 'pie';
    
    public pieChartLegend = true;
    public pieChartPlugins = [pluginDataLabels];
    public pieChartColors = [
      {
        backgroundColor: ['rgb(255, 255, 0)', 'rgb(0, 0, 255)', 'rgb(255, 192, 203)'],
      },
    ];

    public barChartOptions: ChartOptions = {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      scales: { xAxes: [{}], yAxes: [{}] },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    };

    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];  
   /* public barChartColors = [
      {
        backgroundColor: ['rgb(255, 255, 0)', 'rgb(0, 0, 255)', 'rgb(255, 192, 203)'],
      },
    ];*/

    public barChartColors: Color[] = [
      { backgroundColor: 'rgb(255, 255, 0)' },
      { backgroundColor: 'rgb(0, 0, 255)' },
      { backgroundColor: 'rgb(255, 192, 203)' },
    ];
    public barChartData: ChartDataSets[] = [];
    
  constructor(
    private excelService:ExcelService,
    private datePipe: DatePipe,
   // private modalService: BsModalService,
    private messageService: MessageService,
    public galleriaModule: GalleriaModule,
    public blockDataService : BlockDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService,
    public clusterDataService : ClusterDataService,
    public questionAnsDataService : QuestionAnsDataService,
    public surveyDataService :SurveyDataService,
    public activatedRoute :ActivatedRoute,
    public calendarModule : CalendarModule,
    private masterDataService: MasterDataService,
    private router: Router,
  ) {
    
    this.states = [];
    this.districts = []
     this.blocks=[];
    this.selectedstateid  =0;
    this.selectedDistrictId=0;
    this.selectedBlockId=0;
    this.selectedMonth=0;
    this.selectedYear=0;    
    this.selectedReportType="0";
    this.readioSelected="Pie";
   // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //this.userName = currentUser.UserId;
    //this.userType = currentUser.UserType;
    this.loadstates();
    this.years = [];
    let currentYear :number = new Date().getFullYear();
   // alert(currentYear-5);
    let counter:number=currentYear-5;

   while(counter<=currentYear)
   {
        this.years.push({Value: counter});
        counter =counter +1;
   }

    this.months=[];
    this.months.push({value:1 ,Text : "Jan"});                     
    this.months.push({value:2 ,Text : "Feb"});
    this.months.push({value:3 ,Text : "Mar"});
    this.months.push({value:4 ,Text : "Apr"});
    this.months.push({value:5 ,Text : "May"});
    this.months.push({value:6 ,Text : "Jun"});
    this.months.push({value:7 ,Text : "Jul"});
    this.months.push({value:8 ,Text :"Aug"});
    this.months.push({value:9 ,Text :"Sep"});
    this.months.push({value:10 ,Text :"Oct"});
    this.months.push({value:11 ,Text :"Nov"});
    this.months.push({value:12 ,Text :"Dec"});

    this.dropdownSettings  = {
      singleSelection: false,
      idField: 'value',
      textField: 'Text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.graphsSelections=[];
    this.graphsSelections.push({value:'ColumnChart' ,Text : "Column Chart"});
    this.graphsSelections.push({value:'Pie' ,Text : "Pie"});
    this.graphsSelections.push({value:'PieYear' ,Text : "Pie Chart Year wise"});
    this.readioSelected='ColumnChart';
    this.barChartData = [];
    this.reportClock=""
   }

  ngOnInit() {
    this.states = [];
    this.districts = [];
    this.blocks=[];  
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;   
    this.filterStateId = currentUser.StateId;

  }

  loadstates(): void {
    this.states = [];
    this.districts = [];
    this.blocks=[];
    this.selectedDistrictId=0;
    this.selectedBlockId=0;
    let statesList: State[];
    this.stateDataService.getStates().subscribe(res => {
    statesList = res;
    if(this.filterStateId>0)
    {
      this.states = statesList.filter(e=>e.StateId==this.filterStateId);      
	    this.selectedstateid =this.filterStateId;
      this.loaddistricts(this.filterStateId.toString());
      
    }
    else{this.states = statesList;}

  });
}

loaddistricts(value:string): void {
    this.districts = []
    this.stateId = parseInt(value);
    this.blocks=[];
    this.selectedDistrictId=0;
    this.selectedBlockId=0;
    //this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
      this.masterDataService.getDistrict(this.stateId).subscribe(res => {
    this.districts = res;
  });
}

loadblocks(value:string): void {
  this.selectedBlockId=0;
  this.blocks = [];
  this.districtId = parseInt(value);
  this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
  this.blocks = res;

});
}

onOptionsSelected(event:any){
  this.pieChartLabels = [];
  this.pieChartData= [];
  this.barChartLabels=[];
  this.barChartData=[];
  this.reportClock="";
  this.SelectedStateText ="";
  this.SelectedDistrictText =""; 
  this.SelectedBlockText="";
  this.SelectedMonthText="";
  
   this.loaddistricts(event.target.value);
  if(event.target.value!=0){
  this.SelectedStateText = event.target.options[event.target.options.selectedIndex].text;
  }
  
}

onDistrictSelected(event:any){
  this.pieChartLabels = [];
        this.pieChartData= [];
        this.barChartLabels=[];
        this.barChartData=[];
        this.reportClock="";
        this.SelectedDistrictText ="";
        this.SelectedBlockText="";
this.loadblocks(event.target.value);
if(event.target.value!=0){
  this.SelectedDistrictText = event.target.options[event.target.options.selectedIndex].text;
  }

}

onBlockSelected(event:any){
  this.SelectedBlockText="";
  this.pieChartLabels = [];
  this.pieChartData= [];
  this.barChartLabels=[];
  this.barChartData=[];
  this.reportClock="";
    if(event.target.value!=0){
    this.SelectedBlockText = event.target.options[event.target.options.selectedIndex].text;
    }
  }

  onMonthSelected(event:any){
        this.SelectedMonthText="";
        this.pieChartLabels = [];
        this.pieChartData= [];
        this.barChartLabels=[];
        this.barChartData=[];
        this.reportClock="";
      if(event.target.value!=0){
      this.SelectedMonthText = event.target.options[event.target.options.selectedIndex].text;
      }
    }

    onYearSelected(){
      this.SelectedMonthText="";
      this.pieChartLabels = [];
      this.pieChartData= [];
      this.barChartLabels=[];
      this.barChartData=[];
      this.reportClock="";
  }

  onItemSelect(item: any) {
    this.barChartLabels=[];
    this.barChartData=[];
    this.reportClock=""
    this.SelectedMonthText="";
    console.log(this.selectedItems);    
   }
 
   onSelectAll(items: any) {
    this.barChartLabels=[];
     this.barChartData=[];
     this.selectedItems =[];
     this.reportClock=""
     this.SelectedMonthText="";
     this.selectedItems=items;
   }

     onReportSelected(event:any){
      this.SelectedReportText="";
      this.pieChartLabels = [];
        this.pieChartData= [];
        this.barChartLabels=[];
        this.barChartData=[];
        this.reportClock="";
        this.SelectedMonthText="";

        if(event.target.value!=0){
        this.SelectedReportText = event.target.options[event.target.options.selectedIndex].text;
        }

      }

      onRadioReportSelected()
      {
        this.pieChartLabels = [];
        this.pieChartData= [];
        this.barChartLabels=[];
        this.barChartData=[];
        this.SelectedMonthText="";
        this.reportClock="";
      }

validate(): string {
  this.message = "";
  if (this.selectedstateid == 0) {
    this.message = "Please select State.";
          return this.message ;
  }

  if (this.selectedYear == 0) {
    this.message = "Please select Year.";
          return this.message ;
  }
  if (this.selectedMonth == 0 && this.readioSelected=='Pie') {
    this.message = "Please select Month.";
          return this.message ;
  }
  if(this.readioSelected=='ColumnChart')
  {
    if (this.selectedMonthIds.trim() == "" ) {
      this.message = "Please select Month.";
            return this.message ;
    }
  }
  if (this.selectedReportType == "0") {
    this.message = "Please select ReportType.";
          return this.message ;
  }
   
  return this.message;
}

selectedMonthwithComma():void{
  let count :number =0;
  this.selectedMonthIds ="";
  this.SelectedMonthText="";
  for(let result of this.selectedItems){
    if(count== 0)
    {
    this.selectedMonthIds = result.value.toString();
    this.SelectedMonthText= result.Text.toString();
  }else{
    this.selectedMonthIds = this.selectedMonthIds +',' + result.value;
    this.SelectedMonthText= this.SelectedMonthText +','+result.Text.toString();
  }
      count=count+1;
  }

}

loadGraphsReport(): void {
  this.reportClock="";
  if(this.readioSelected=='ColumnChart')
  {this.selectedMonthwithComma();}
  this.message="";
  this.message = this.validate();
  if (this.message.length <= 0) {
    this.reportClock="clicked";
  this.dataFromservice = [];

  if(this.filterStateId>0 && this.selectedstateid==0)
    {
      this.selectedstateid = this.filterStateId;
    }
   this.questionAnsDataService.getGraphData(this.selectedstateid,this.selectedDistrictId,this.selectedBlockId,this.selectedYear,
    this.selectedMonth,this.selectedReportType,this.readioSelected,this.selectedMonthIds,'B').subscribe(res => {
    this.dataFromservice = res;
    this.pieChartLabels = [];
    this.pieChartData= [];
     this.barChartLabels=[];
    // this.barChartData
     this.barChartData=[];
     const data0 :number[] =[] ;
     const data1 :number[]  =[];
     const data2 :number[]  =[];

    if(this.dataFromservice.length>0){
        //this.totalCount = this.data.length;
     if(this.readioSelected=='Pie' || this.readioSelected=='PieYear')
     {
        for(let i=0;i<this.dataFromservice.length;i++){
       this.pieChartLabels.push(this.dataFromservice[i].surveyStatus + '(' + this.dataFromservice[i].Recordcount +'%)');
       this.pieChartData.push(this.dataFromservice[i].Recordcount);
        }
      
     }
     else if(this.readioSelected=='ColumnChart' && this.selectedReportType=='SurveyStatus'){
      //this.barChartTitle = this.SelectedReportText;
      for(let i=0;i<this.dataFromservice.length;i++){

         if(i==0)
         {
           this.barChartLabels.push(this.dataFromservice[i].surveyMonthText);
         }
         else 
         {
           if(this.dataFromservice[i].surveyMonth!=this.dataFromservice[i-1].surveyMonth)
            this.barChartLabels.push(this.dataFromservice[i].surveyMonthText);
         }
     
         if(this.dataFromservice[i].surveyStatus=='Approved')
         { 
          data0.push(this.dataFromservice[i].Recordcount);
         }

         if(this.dataFromservice[i].surveyStatus=='Rejected')
         { 
          data1.push(this.dataFromservice[i].Recordcount);
         }
         if(this.dataFromservice[i].surveyStatus=='Unchecked')
         { 
          data2.push(this.dataFromservice[i].Recordcount);
         }

      }
      this.barChartData = [
        {
          "label": 'Approval',
          "data": data0
        },
        { 
          "label": 'Rejected',
          data: data1
        },
        { 
          "label": 'Unchecked',
          data: data2
        }
      ];
    
 
     }
     else if(this.readioSelected=='ColumnChart' && this.selectedReportType=='SchoolReg'){

      for(let i=0;i<this.dataFromservice.length;i++){
       this.barChartLabels.push(this.dataFromservice[i].MonthText);         
        data0.push(this.dataFromservice[i].Recordcount);         
      }
      this.barChartData = [
        {
          "label": 'School Registration',
          "data": data0
        }
      ];
    
 
     }

      
      }
      else{
        this.reportClock="";
        this.message = "No Data Found.";
      }
  });
}

}

downloadCanvas(event) {
  // get the `<a>` element from click event
  var anchor = event.target;
  // get the canvas, I'm getting it by tag name, you can do by id
  // and set the href of the anchor to the canvas dataUrl
  anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
  // set the anchors 'download' attibute (name of the file to be downloaded)
  anchor.download = "Graphs.png";
}

public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  console.log(event, active);
}

public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  console.log(event, active);
}

}
