import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AdminapprovalRoutingModule } from "./adminapproval-routing.module";
import { AdminApprovalManageComponent } from "./adminapproval-manage.component";
import { AdminapprovalManageQuComponent } from "./adminapproval-manage-qu.component"
import{ AdminapprovalComponent } from "./adminapproval.component"
import { GalleriaModule } from 'primeng/galleria';
import { ImageViewerModule } from "ngx-image-viewer";
import { DatePipe } from '@angular/common';
import {CalendarModule} from 'primeng/calendar';
import html2canvas from 'html2canvas'


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    CalendarModule,
    MessageModule,
    ConfirmationPopoverModule.forRoot({
    confirmButtonType: 'danger' // set defaults here

    }),
    AdminapprovalRoutingModule,
    GalleriaModule,
    ImageViewerModule.forRoot()
      ],
  declarations: [
    AdminapprovalComponent,
    AdminApprovalManageComponent,
    AdminapprovalManageQuComponent    
  ],
  exports: [
   // CoordinateapprovalComponent,
   AdminapprovalComponent,
    RouterModule
  ],
  providers: [MessageService,DatePipe]
})
export class AdminapprovalModule {}
