import { Component, Injectable, OnInit } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SchoolreportManageComponent } from './schoolreport-manage.component';
import { MessageService } from 'primeng/api';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { SchoolDataService } from '../Services/SchoolDataService';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { StateSaveResult } from '../Models/stateSaveResult';
import { Router, ActivatedRoute } from "@angular/router";
import { stringify } from 'querystring';
import { GalleriaModule } from 'primeng/galleria';
import { SurveyDataService } from '../Services/SurveyDataService';
import { ExcelService } from '../Services/ExcelService';
import { ReportParameters } from '../Models/reportParameters';
import { CalendarModule } from 'primeng/calendar';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { DatePipe } from '@angular/common';
import { MasterDataService } from '../Services/MasterDataService';

@Component({
  selector: "Schoolreport.component",
  templateUrl: "./Schoolreport.component.html"
})
@Injectable()
export class SchoolreportComponent implements OnInit {
  // public galleriaModule: GalleriaModule;
  isLoading = false;
  listTable;
  listFormatTableExists: boolean = false;
  public blocks: Block[];
  public reportParameters: ReportParameters;
  public states: State[];
  public clusters: Cluster[];
  public districts: District[];
  stateId: number;
  districtId: number;
  blockId: number;
  clusterId: number;
  status: string;
  SchoolId: number;
  SurveyId: number;
  fromDate: string;
  toDate: string;
  totalCount: number;
  public data: any;
  public report: any;
  userName: string;
  userType: string;
  filterStateId: number;
  public inputs: any[] = [{

    Serveyname: '',
    Status: '',
    StateName: '',
    BlockName: '',
    DistrictName: '',
    ClusterName: ''

  }]
  //bsModalRef: BsModalRef;
  surveys = [];

  selectedstatus: string; selectedstateid: number; selectedDistrictId: number;
  selectedBlockId: number;
  selectedClusterId: number; SelectedSurveyId: number;
  selectedtoDate: Date;
  selectedfrmDate: Date;
  setBackFlag: string;

  constructor(
    private excelService: ExcelService,
    private datePipe: DatePipe,
    // private modalService: BsModalService,
    private messageService: MessageService,
    public galleriaModule: GalleriaModule,
    public blockDataService: BlockDataService,
    public districtDataService: DistrictDataService,
    public stateDataService: StateDataService,
    public clusterDataService: ClusterDataService,
    public questionAnsDataService: QuestionAnsDataService,
    public surveyDataService: SurveyDataService,
    public activatedRoute: ActivatedRoute,
    public calendarModule: CalendarModule,
    private router: Router,
    private masterDataService: MasterDataService,
  ) {
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    this.selectedstatus = '0';
    this.selectedstateid = 0;
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.SelectedSurveyId = 0;

    this.SelectedSurvey = '';
    this.SelectedStstus = '';
    this.SelectedCluster = '';
    this.SelectedDis = '';
    this.SelectedState = '';
    this.SelectedBlock = '';

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;
    this.userType = currentUser.UserType;
    this.filterStateId = currentUser.StateId;

    this.selectedfrmDate = new Date();
    this.selectedtoDate = new Date();
    this.loadstates();
    this.loadsurveys();
    this.totalCount = 0;
  }

  ngOnInit() {
    //this.loadBlocks();
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    this.selectedfrmDate = new Date();
    this.selectedtoDate = new Date();

    let repParameter = JSON.parse(localStorage.getItem('repParameter'));
    //this.reportParameters=new ReportParameters();
    //localStorage.setItem('repParameter', JSON.stringify(this.reportParameters));
    this.setBackFlag = "";
    this.setBackFlag = this.activatedRoute.snapshot.queryParamMap.get("setBackFlag");

    if (this.setBackFlag == 'Back') {
      //alert('HI')
      this.selectedstatus = repParameter.status;
      this.selectedstateid = repParameter.stateid;
      this.loaddistricts(this.selectedstateid.toString());
      this.selectedDistrictId = repParameter.DistrictId;
      this.loadblocks(repParameter.DistrictId.toString());
      this.selectedBlockId = repParameter.BlockId;
      this.loadclusters(repParameter.BlockId.toString());
      this.selectedClusterId = repParameter.ClusterId;
      this.SelectedSurveyId = repParameter.SurveyId;
      let frmdate = new Date(Date.parse(repParameter.frmDate));
      this.selectedfrmDate = frmdate;
      let todate = new Date(Date.parse(repParameter.toDate));
      this.selectedtoDate = todate;

      // this.loadQuestionAnsList(this.selectedstatus,this.selectedstateid,this.selectedDistrictId,
      //   this.selectedBlockId,this.selectedClusterId,this.SelectedSurveyId,
      //   this.selectedfrmDate,this.selectedtoDate);
    }

  }

  loadsurveys(): void {
    // this.isLoading = true;
    this.surveys = [];
    this.surveyDataService.getSurveys(this.userType, 'A').subscribe(res => {
      this.surveys = res;
      //this.isLoading = false;
    });
  }

  loadstates(): void {
    this.states = [];
    this.districts = []
    this.clusters = [];
    this.blocks = [];
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    let statesList: State[];

    this.stateDataService.getStates().subscribe(res => {
      statesList = res;

      if (this.filterStateId > 0) {
        this.states = statesList.filter(e => e.StateId == this.filterStateId);
        this.selectedstateid = this.filterStateId;
        this.loaddistricts(this.filterStateId.toString());
      }
      else {
        this.states = statesList;
      }

    });
  }

  loaddistricts(value: string): void {
    this.districts = []
    this.stateId = parseInt(value);
    this.clusters = [];
    this.blocks = [];
    this.selectedDistrictId = 0;
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    //this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
    this.masterDataService.getDistrict(this.stateId).subscribe(res => {
      this.districts = res;
    });
  }
  // // loadQuestionAnsList(selectedStateId:number,selectedDistrictId:number,selectedBlockId:number,selectedClusterId:number,
  // //   selectedsurvey:number,selectedFrmDate:Date,selectedToDate:Date,selectedstatus:string
  // // ): void {
  // //   this.data = [];
  // //   this.totalCount = 0;
  // //   this.isLoading = true;

  // //   let dashboardData = {
  // //     "SurveyId": selectedsurvey,
  // //     "Stateid": selectedStateId,
  // //     "DistrictId": selectedDistrictId,
  // //     "Gender": selectedBlockId,
  // //     "GradeId": selectedClusterId,
  // //     "FromDate": selectedFrmDate,
  // //     "ToDate": selectedToDate,
  // //     "Status":selectedstatus
  // //   }
  // //   if (this.listFormatTableExists) {
  // //     // this.surveys = [];
  // //     this.data = [];
  // //     this.listTable.destroy();
  // //     this.listTable.clear();
  // //   }
  // //   this.questionAnsDataService.getquestionListSurvey(dashboardData).subscribe(res => {
  // //     this.data = res;
  // //     if (this.data.length == 0) {

  // //      // this.noData = true;
  // //       this.isLoading = false;

  // //     }
  // //     else {
  // //       // this.displayFlagSearch = this.data[0].TotalCount;
  // //       // this.totalCount = this.data[0].TotalCount;
  // //       // //alert(this.totalCount);
  // //       // this.setPage(this.pageIndex);
  // //      setTimeout(() => {          

  // //         this.listTable = $("#districtTable").DataTable({

  // //           // lengthMenu: [
  // //           //   [10, 20, 40, 17 - 1],
  // //           //   [10, 20, 40, 17, "All"],
  // //           // ],
  // //           // pageLength: 10,
  // //           // order: [[0, "desc"]],
  // //           destroy: true,


  // //         });
  // //         this.listFormatTableExists = true;
  // //         this.isLoading = false;
  // //     }, 0);
  // //     }

  // //   });
  // // }
  loadQuestionAnsList(selectedStateId:number,selectedDistrictId:number,selectedBlockId:number,selectedClusterId:number,
       selectedsurvey:number,selectedFrmDate:Date,selectedToDate:Date,selectedstatus:string): void {
    this.data = [];
     
    this.reportParameters = new ReportParameters();
    this.reportParameters.status = selectedstatus;
    this.reportParameters.stateid = selectedStateId;
    this.reportParameters.DistrictId = selectedDistrictId;
    this.reportParameters.BlockId = selectedBlockId;
    this.reportParameters.ClusterId = selectedClusterId;
    this.reportParameters.SurveyId = selectedsurvey;
    this.reportParameters.frmDate = selectedFrmDate;
    this.reportParameters.toDate = selectedToDate;

    localStorage.setItem('repParameter', JSON.stringify(this.reportParameters));
    //this.fromDate = this.datePipe.transform(selectedFrmDate, "yyyy-MM-dd");
    //this.toDate = this.datePipe.transform(selectedToDate, "yyyy-MM-dd");

    if (this.listFormatTableExists) {
      this.data = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    // if (this.filterStateId > 0 && selectedstateid == 0) {
    //   selectedstateid = this.filterStateId;
    // }

    this.isLoading = true;

    let dashboardData = {
      "SurveyId": selectedsurvey,
      "Stateid": selectedStateId,
      "DistrictId": selectedDistrictId,
      "Gender": selectedBlockId,
      "GradeId": selectedClusterId,
      "FromDate": selectedFrmDate,
      "ToDate": selectedToDate,
      "Status": selectedstatus
    }


   // this.questionAnsDataService.GetQuestionAnsReports(selectedstatus, selectedstateid, selectedDistrictId, selectedBlockId,
     // selectedClusterId, SelectedSurveyId, this.userType, this.fromDate, this.toDate).subscribe(res => {
      this.questionAnsDataService.getquestionListSurvey(dashboardData).subscribe(res => {
        this.data = res;
        setTimeout(() => {
          this.listTable = $("#districtTable").DataTable({
            lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
            pageLength: 12,
            order: [[0, "asc"]]
          });
          this.listFormatTableExists = true;
          this.isLoading = false;
        }, 0);

        this.isLoading = false;
        this.report = [];
        this.report.push({
          Question: '',
          Answer: '',
          AnsDateString: '',
          Status: '',
          RejeText: ''
        });
        this.totalCount = 0;
        if (this.data.length > 0) {
          this.totalCount = this.data.length;
          for (let i = 0; i < this.data.length; i++) {
            let statusText="";
            if(this.data[i].Status=="P")
            {
              statusText="Pending";
            }
          else  if(this.data[i].Status=="A")
            {
              statusText="Admin Approved";
            }
          else  if(this.data[i].Status=="R")
            {
              statusText="Admin Rejected";
            }

            this.report.push({
              UID: this.data[i].Email,
              Question: this.data[i].StudentName,
              Answer: this.data[i].SchoolName,
              AnsDateString: statusText,
              Status: this.data[i].RejectedQues,
              RejeText: this.data[i].RejeText,
            });
          }
        }
      });
  }
  SelectedSurvey: string;
  SelectedStstus: string; SelectedBlock: string; SelectedDis: string; SelectedCluster: string; SelectedState: string;
  //event handler for the select element's change event
  selectedSurveyValue(event: any) {
    this.SelectedSurvey = "";
    if (event.target.value != 0) {
      this.SelectedSurvey = event.target.options[event.target.options.selectedIndex].text;
    }
  }

  selectedStatusValue(event: any) {
    this.SelectedStstus = "";
    if (event.target.value != 0) {
      this.SelectedStstus = event.target.options[event.target.options.selectedIndex].text;
    }
  }

  selectedClusterValue(event: any) {
    this.SelectedCluster = "";
    if (event.target.value != 0) {
      this.SelectedCluster = event.target.options[event.target.options.selectedIndex].text;
    }
  }

  getTheColor(statusClr) {
    if (statusClr == "CR" || statusClr == "AR")
      return "red";
    else if (statusClr == "AA" || statusClr == "CA")
      return "#006400";

    // could be better written, but you get the idea
  }

  exportAsXLSX() {
    this.inputs = [];
    this.inputs.push({
      Serveyname: '',
      Status: '',
      StateName: '',
      DistrictName: ''
      //BlockName :'',
      //ClusterName :''
    });
    //console.log(this.SelectedDis)
    this.inputs.push({
      Serveyname: this.SelectedSurvey,
      Status: this.SelectedStstus,
      StateName: this.SelectedState,
      DistrictName: this.SelectedDis
      //BlockName :this.SelectedBlock,    
      //ClusterName :this.SelectedCluster
    });

    this.excelService.exportAsExcelFile(this.report, this.inputs, 'Report', ['User ID','Student Name', 'School Name', 'Status', 'Rejected Questions'], ['Survey Name', 'Status', 'State Name', 'City Name']);

  }

  onOptionsSelected(event: any) {
    this.loaddistricts(event.target.value);
    this.SelectedState = "";
    if (event.target.value != 0) {
      this.SelectedState = event.target.options[event.target.options.selectedIndex].text;
    }
  }

  onDistrictSelected(event: any) {
    this.loadblocks(event.target.value);
    this.SelectedDis = "";
    if (event.target.value != 0) {
      this.SelectedDis = event.target.options[event.target.options.selectedIndex].text;
    }
  }

  onBlockSelected(event: any) {
    this.loadclusters(event.target.value);
    this.SelectedBlock = "";
    if (event.target.value != 0) {
      this.SelectedBlock = event.target.options[event.target.options.selectedIndex].text;
    }
  }
  loadblocks(value: string): void {
    this.selectedBlockId = 0;
    this.selectedClusterId = 0;
    this.blocks = [];
    this.clusters = [];
    this.districtId = parseInt(value);
    this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
      this.blocks = res;

    });
  }

  loadclusters(value: string): void {
    this.selectedClusterId = 0;
    this.clusters = [];
    this.blockId = parseInt(value);
    this.clusterDataService.getClusterByblockId(this.blockId).subscribe(res => {
      this.clusters = res;

    });
  }


  onSelectMethod(event) {
    let d = new Date(Date.parse(event));
    // this.reportParameters.frmDate = d;//`${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
    this.selectedfrmDate = d;
  }

  onSelectMethodDate(event) {
    let d = new Date(Date.parse(event));
    //this.reportParameters.toDate = d;
    this.selectedtoDate = d;
    // alert(d);
  }

  public gotoProductDetailsV2(url: string, SchlId: number, surveyId: number) {

    const initialState = {
      title: "Approve / Reject",
      SchlId: SchlId,
      surveyId: surveyId,
    };
    var myurl = `${url}/${SchlId}/${surveyId}`;

    this.router.navigate(['/schoolreport-manage'], { queryParams: { SchlId: SchlId, surveyId: surveyId } });

    //this.router.navigate(['/schoolreport-manage'], { queryParams: { SchlId: SchlId ,surveyId :surveyId,
    //  stateIdFP:this.selectedstateid,districtID:this.selectedDistrictId,blockID:this.selectedBlockId,
    // clusterID:this.selectedClusterId,statusselected:this.selectedstatus,SelectedsurveyID:this.SelectedSurveyId} });
  }

}
