import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {RouterModule} from '@angular/router';

import { MenuComponent } from "././menu.component"

@NgModule({
    imports:[
        RouterModule,
        CommonModule,
    ],
    declarations:[
        MenuComponent
    ],
    exports:[
        MenuComponent,
        RouterModule
    ]
})

export class MenuModule{

}
