import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { SchoolManageComponent } from "./school-manage.component";
import { SchoolRoutingModule } from "./school-routing.module";
import { SchoolComponent } from './school.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PaginationModule } from 'ngx-bootstrap';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    SchoolRoutingModule,
    PaginationModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    SchoolComponent, 
    SchoolManageComponent
  ],
  exports: [
    SchoolComponent, 
    RouterModule
  ],
  providers: [MessageService],
  entryComponents: [SchoolManageComponent]
})
export class SchoolModule {}
