import { Component, Injectable, OnInit } from "@angular/core";
import { UserApp } from '../Models/userApp';
import { UserDataService } from "../Services/UserDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';
import { RoleMenuMapping } from '../Models/roleMenuMapping';

@Component({
  selector: "usersetting",
  templateUrl: "./rolemapping.component.html"
})
@Injectable()
export class RolemappingComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public userApp: UserApp[];
  bsModalRef: BsModalRef;
  //message: string;
  public roles : any[];
  public rolesMapping : any[];
  public rolesMappings : RoleMenuMapping[];
  selectedRole :number;
  message: string;
  userName:number;
  userType:string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public userDataService: UserDataService
  ) { 
    
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='User Access'); //("Block");  
     this.displayFlag =menul.Menuname;

     if(this.displayFlag =='User Access')
     {   
        this.selectedRole=0; 
        this.loadRoles();
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.userName = currentUser.UserId;
        this.userType = currentUser.UserType;
     }
  }

  ngOnInit() {
    //this.loadUserMappingData("0");
  }

  refresh() {
   // this.loadUserMappingData("0");
  }

  loadRoles():void{
    this.isLoading = true;
    this.selectedRole=0;
    this.userDataService.getRoleList().subscribe(res => {
      this.roles = res;
      this.isLoading = false;

    });
   
  }

  isCheck(status:string):boolean{
   // alert(status);
  if(status=="A")
  return true;
  else false;

  }

  onOptionsSelected(event:any){

    this.loadUserMappingData(event.target.value);
    
  }


  loadUserMappingData(roleId :string): void {
    this.isLoading = true;
    this.selectedRole = parseInt(roleId);
      //alert(this.selectedRoleid);
    this.rolesMapping = [];
    if (this.listFormatTableExists) {
      this.rolesMapping = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
   // alert(this.selectedRoleid);
    this.userDataService.getMenuRoleMapping(this.selectedRole).subscribe(res => {
      this.rolesMapping = res;
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[1, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onChange(event:any,index:number){
//alert(event.target.value);
//alert(this.rolesMapping[index].Status);
if(this.rolesMapping[index].Status=='I')
{
  this.rolesMapping[index].Status='A';
}
else if(this.rolesMapping[index].Status=='A')
{
  this.rolesMapping[index].Status='I';
}

//alert(this.rolesMapping[index].Status);
    //if(event.target.value=='True')
  //  this.rolesMapping[index].Status='A';
  }


  validate(): string {
    this.message = "";
    if (this.selectedRole == 0) {
      this.message = "Please select Role Name.";
            return this.message ;
    }
  }

  Save(): void{
    //alert('Hi');
//this.message =this.validate();
    //if (this.message.length <= 0) {
      //this.isSaving = true;
      let roleId :number;
      this.rolesMappings =[];
  for(let result of this.rolesMapping)
  {
    result.RoleId = this.selectedRole;
    let customObj = new RoleMenuMapping();
    customObj.RoleMenuId =result.RoleMenuId;
    customObj.MenuId =result.MenuId;
    customObj.RoleId =result.RoleId;
    customObj.CreatedBy =this.userName;
    customObj.Status =result.Status;
    
    this.rolesMappings.push(customObj);  
  }


  console.log(this.rolesMappings);

  this.userDataService.SaveRoleMapping(this.rolesMappings).subscribe(
    data => {
      let stateSaveResult: StateSaveResult = data;
      if (stateSaveResult.IsSuccessFull) {
        this.message = "";
      //  this.onClose.next(true);
       // this.bsModalRef.hide();
       this.message = "Saved";
      }
      else {
        this.message = stateSaveResult.ErrorMessage;
      }
     // this.isSaving = false;      
   },
    (error: any) => {
      this.message = `Error while saving School`;
    },
  );
//}
    
  }
  

  /*onAdd(){
     }

  onEdit(id : Number){
    
  }
*/
  
}
