import { Component,OnInit } from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router"

import { HeaderComponent } from "../Header/header.component";
import { User } from "../Models/user";
import { AuthenticationService } from '../Services/AuthenticationService';
import { first } from 'rxjs/operators';
import { UserApp } from "src/app/Models/userApp";

@Component({
    selector:"login",
    templateUrl:"./login.component.html",
    styleUrls:["./login.component.css"]
})

export class LoginComponent implements OnInit{
    comp: HeaderComponent;
    user:User;
    returnUrl: string;
    loading = false;
    message: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService){

    }

    ngOnInit(){
        this.authenticationService.logout();

        this.loading = false;
        this.message = "";
        this.user = new User();

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }

    login(){
        this.loading = true;
        this.message = "";

        if (this.user.username == "" || this.user.password == "") {
            this.message = "UserName or Password cannot be empty";
            this.loading = false;
            return;
        }

        // this.authenticationService.loginApp(this.user.username, this.user.password).subscribe(
        //     data => {
        //       let stateSaveResult1: UserApp = data;
        //       alert(data);
        //       console.log(data);
        //     //   if (stateSaveResult1.IsSuccessFull) {
               
        //     //   }
        //     //   else {
        //     //     this.message = stateSaveResult1.ErrorMessage;
        //     //   }
              
      
        //     }
        // );
        this.authenticationService.loginApp(this.user.username, this.user.password)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    //alert(error);
                    this.message = "Invalid user name or password.";
                    this.loading = false;
            });
    }
}
