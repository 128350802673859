import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MasterDataService } from "src/app/Services/MasterDataService";
import { StudentDataService } from "src/app/Services/StudentDataService";

@Component({
  selector: "app-student-edit",
  templateUrl: "./student-edit.component.html",
  styleUrls: ["./student-edit.component.css"],
  providers: [StudentDataService],
})
export class StudentEditComponent implements OnInit {
  sId: any;
  studentId: number;
  firstName: string;
  lastName: string;
  designation: any;
  schoolId: any;
  gradeId: any;
  academicYear: any;
  gender: any;
  mobileNo: string;
  mediumOfSchool: any;
  address1: string;
  address2: string;
  pincode: string;
  isMigrated: any;
  migrationState: string;
  email: string;
  communityAddress: string;

  designations = [];

  schoolIds = [];

  gradeIds = [];
  selectedGradeId = 0;
  selectedSchoolId = 0;
  selectedDesignation = "";
  selectedGender = "Male";

  genders = [
    { value: "Male", id: "Male" },
    { value: "Female", id: "Female" },
    { value: "Others", id: "Others" },
  ];

  academicYears = [
    { value: "2020-2021", id: "2020-2021" },
    { value: "2021-2022", id: "2021-2022" },
    { value: "2022-2023", id: "2022-2023" },
  ];

  mediumsOfSchool = [
    { value: "English", id: "English" },
    { value: "Hindi", id: "Hindi" },
  ];

  isMigrateds = [
    { value: "Yes", id: true },
    { value: "No", id: false },
  ];

  public isNoDatFound: Boolean = false;

  constructor(
    private studentDataService: StudentDataService,
    private router: Router,
    private route: ActivatedRoute,
    private masterDataService: MasterDataService
  ) {
    this.sId = this.route.snapshot.params.id;
    this.loadDesignationsData();
    this.loadSchoolsData();
    this.loadGradesData();
    this.loadStudentData();
  }

  public btnBack() {
    this.router.navigate(["/student"]);
  }

  ngOnInit() {
    this.loadDesignationsData();
    this.loadSchoolsData();
    this.loadGradesData();
    this.loadStudentData();
    // this.loadDesignationsData();
  }
  loadSchoolsData() {
    this.masterDataService.getSchools().subscribe((res) => {
      this.schoolIds = res;
      if (this.schoolId) {
        this.selectedSchoolId = this.schoolIds.filter(
          (itm) => itm.SchoolId == this.schoolId.SchoolId
        )[0].SchoolId;
      }
    });
  }

  loadGradesData() {
    this.masterDataService.getGrades().subscribe((res) => {
      this.gradeIds = res;
      if (this.gradeId) {
        this.selectedGradeId = this.gradeIds.filter(
          (itm) => itm.GradeId == this.gradeId.GradeId
        )[0].GradeId;
      }
    });
  }

  loadDesignationsData() {
    this.masterDataService.getDesignations().subscribe((res) => {
      this.designations = res;
      if (this.designation) {
        this.selectedDesignation = this.designations.filter(
          (itm) => itm.DesignationId == this.designation.DesignationId
        )[0].DesignationId;
      }
    });
  }
  loadStudentData() {
    this.studentDataService
      .getStudentByStudentIdveyById(this.sId)
      .subscribe((res) => {
        if (res) {
          this.isNoDatFound = false;
          this.studentId = res.StudentId;
          this.firstName = res.FirstName;
          this.lastName = res.LastName;
          this.mobileNo = res.MobileNo;
          this.address1 = res.Address1;
          this.address2 = res.Address2;
          this.pincode = res.PinCode;
          this.migrationState = res.MigrationState;
          this.email = res.Email;
          this.communityAddress = res.CommunityAddress;

          this.designation = this.designations.filter(
            (itm) => itm.DesignationId == res.DesignationId
          )[0];

          this.schoolId = this.schoolIds.filter(
            (itm) => itm.SchoolId == res.SchoolId
          )[0];

          this.gradeId = this.gradeIds.filter(
            (itm) => itm.GradeId == res.GradeId
          )[0];

          this.academicYear = this.academicYears.filter(
            (itm) => itm.id == res.AcademicYear
          )[0];
          this.gender = this.genders.filter((itm) => itm.id == res.Gender)[0];
          this.mediumOfSchool = this.mediumsOfSchool.filter(
            (itm) => itm.id == res.MediumOfSchool
          )[0];
          this.isMigrated = this.isMigrateds.filter(
            (itm) => itm.id == res.IsMigrated
          )[0];
        } else {
          this.isNoDatFound = true;
        }
      });
  }
  updateStudent(
    studentId: number,
    firstName: string,
    lastName: string,
    designation: any,
    schoolId: any,
    gradeId: any,
    academicYear: any,
    gender: any,
    mobileNo: number,
    mediumOfSchool: any,
    address1: string,
    address2: string,
    pincode: number,
    isMigrated: any,
    migrationState: string,
    email: string,
    communityAddress: string
  ) {
    let updatedData = {
      StudentId: studentId || 0,
      FirstName: firstName || "AAA",
      LastName: lastName || "LLL",
      DesignationId: this.selectedDesignation,
      SchoolId: this.selectedSchoolId,
      GradeId: this.selectedGradeId,
      AcademicYear: academicYear.id || "2022-2023",
      Gender: gender.id || "male",
      MobileNo: mobileNo || "9921192734",
      MediumOfSchool: mediumOfSchool.id || "English",
      Address1: address1 || "Address 1",
      Address2: address2 || "address 2",
      PinCode: pincode || "421006",
      IsMigrated: isMigrated.id,
      MigrationState: migrationState || "Orissa",
      Email: email || "a@a.com",
      CommunityAddress: communityAddress || "Community Address",
      ImagePath: "null",
    };

    this.studentDataService.updateStudent(updatedData).subscribe((res) => {
      this.router.navigate(["/student"]);
    });
  }

  onDesignationSelected(evt) {
    this.selectedDesignation = this.designations.filter(
      (item) => item.DesignationId == evt
    )[0].DesignationId;
  }

  onSchoolIdSelected(evt) {
    this.selectedSchoolId = this.schoolIds.filter(
      (item) => item.SchoolId == evt
    )[0].SchoolId;
  }

  onGradeIdSelected(evt) {
    console.log(evt);
    this.selectedGradeId = this.gradeIds.filter(
      (item) => item.GradeId == evt
    )[0].GradeId;
  }

  onAcademicYearSelected(evt) {
    console.log("evt", evt);
  }

  onGenderSelected(event) {
    this.selectedGender = event.value;
  }

  onMediumOfSchoolSelected(evt) {
    console.log("evt", evt);
  }

  onIsMigratedSelected(evt) {
    this.isMigrated = this.isMigrateds.filter((itm) => itm.id == evt.id)[0];
  }

  resetStudent() {
    this.loadStudentData();
  }
}
