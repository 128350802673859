import { Component, Injectable, OnInit } from "@angular/core";
import { Block } from '../Models/block';
import { ClusterDataService } from "../Services/ClusterDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClusterManageComponent } from './cluster-manage.component';
import { MessageService } from 'primeng/api';
import { Cluster } from '../Models/cluster';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: "cluster",
  templateUrl: "./cluster.component.html"
})
@Injectable()
export class ClusterComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public clusters: Cluster[];
  bsModalRef: BsModalRef;
  message: string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public clusterDataService: ClusterDataService
  ) { }

  ngOnInit() {

    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='Cluster'); //("Block");  
     this.displayFlag =menul.Menuname;
   
    if(this.displayFlag =='Cluster')
    {
      this.loadClusters();
    }
    else{
     // this.message ="Unauthorise Access";    
      alert("Unauthorise Access");    
    }
  }

  refresh() {
    this.loadClusters();
  }

  loadClusters(): void {
    this.isLoading = true;
    this.clusters = [];
    if (this.listFormatTableExists) {
      this.clusters = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.clusterDataService.getClusters().subscribe(res => {
      this.clusters = res;
      setTimeout(() => {
        this.listTable = $("#districtTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[0, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onAdd(){
    const initialState = {
      title: "Add a new Cluster",
      ClusterId:0
    };
    this.bsModalRef = this.modalService.show(ClusterManageComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadClusters();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit Cluster",
      ClusterId: id
    };
    this.bsModalRef = this.modalService.show(ClusterManageComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadClusters();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.clusterDataService.Delete(id).subscribe(
      data => {
        let stateSaveResult1: StateSaveResult = data;
        if (stateSaveResult1.IsSuccessFull) {
          this.message = "";
          this.loadClusters();
        }
        else {
          this.message = stateSaveResult1.ErrorMessage;
        }

      }
    );
  }
}
