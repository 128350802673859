import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from "./home.component";
import { HomeRoutingModule } from './home-routing.module';
import { DatePipe } from '@angular/common';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    CalendarModule,
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent, RouterModule],
  providers: [DatePipe]
})
export class HomeModule {}
