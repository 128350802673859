import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserApp  } from "../Models/userApp";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";
import { RoleMenuMapping } from '../Models/roleMenuMapping';
import { map } from 'rxjs/operators';

@Injectable()
export class UserDataService {
    constructor(private http: HttpClient) {
        userApp: UserApp;
    }
    public getUsers(): Observable<UserApp[]> {
        return this.http.get<UserApp[]>(config.appUrl + '/UserApp');
    }
    
    public getUserById(id: number): Observable<UserApp> {
        return this.http.get<UserApp>(config.appUrl + '/UserApp/' + id);
    }

    public getRoleList(): Observable<any> {
        return this.http.get<any>(config.appUrl + '/UserApp/GetRoleList');
    }

    
    public getRoleListByType(roleType:string): Observable<any> {
        return this.http.get<any>(config.appUrl + '/UserApp/GetRoleListByRoleType/' + roleType);
    }

    public Save(userApp: UserApp): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/UserApp',
            JSON.stringify(userApp),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<StateSaveResult> {
        return this.http.delete<StateSaveResult>(config.appUrl + '/UserApp/' + id);
    }

    public getMenuRoleMapping(roleId:number): Observable<any[]> {
            return this.http.get<any[]>(config.appUrl + '/UserApp/GetMenuRoleMapping/' + roleId);
        }
    
    public getMenuListFromRoleId(roleId:number): Observable<any[]> {
            return this.http.get<any[]>(config.appUrl + '/UserApp/GetMenuListFromRoleId/' + roleId);
        }  
        
        public getParentMenuListFromRoleId(roleId:number): Observable<any[]> {
            return this.http.get<any[]>(config.appUrl + '/UserApp/GetParentMenuListFromRoleId/' + roleId);
        }

        public SaveRoleMapping(roleMenuMapping: RoleMenuMapping[]): Observable<StateSaveResult> {
           
            let resp: Observable<StateSaveResult> = null;
            const httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json"
                })
            };
            console.log(JSON.stringify(roleMenuMapping));
            resp = this.http.post<StateSaveResult>(
                config.appUrl+ '/UserApp/SaveMenuRoleMapping',
                JSON.stringify(roleMenuMapping),
                httpOptions
            );
            return resp;


            
       }

        
    
    }