import { Component, OnInit } from '@angular/core';
import { Cluster } from '../Models/cluster';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { ClusterDataService } from '../Services/ClusterDataService';
import { StateSaveResult } from '../Models/stateSaveResult';


@Component({
  selector: 'cluster-manage',
  templateUrl: 'cluster-manage.component.html'
})

export class ClusterManageComponent implements OnInit {
  cluster: Cluster;
  public blocks: Block[];
  public states: State[];
  public districts: District[];
  title: string;
  id:number;
  ClusterId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;
  stateId:number;
  districtId:number;
  blockId:number;

  constructor(public bsModalRef: BsModalRef, 
    public blockDataService : BlockDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService,
    public clusterDataService : ClusterDataService){this.loadstates();//this.loaddistricts();this.loadblocks();
     }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();        
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = currentUser.UserId;

    if(this.ClusterId==0)
    {
      this.cluster = new Cluster();
      this.cluster.ClusterName = "";
      this.cluster.StateId=0;
      this.cluster.StateName="";
      this.cluster.DistrictName="";
      this.cluster.BlockName="";
      this.cluster.ClusterCoordinatorName="";
      this.cluster.ClusterCoordinatorContactNo="";
      this.cluster.DistrictId=0;
      this.cluster.BlockId=0;
      
    }
    else{
      this.fillClusterById();
    }
    
    
  }

  Clear() : void{
    this.message = "";
    this.onClose = new Subject();    
      this.cluster = new Cluster();
      this.cluster.ClusterName = "";
      this.cluster.StateId=0;
      this.cluster.StateName="";
      this.cluster.DistrictName="";
      this.cluster.BlockName="";
      this.cluster.ClusterCoordinatorName="";
      this.cluster.ClusterCoordinatorContactNo="";
      this.cluster.DistrictId=0;
      this.cluster.BlockId=0;
  }

  fillClusterById() {
    this.isLoading = true;
    this.cluster = new Cluster();
    this.clusterDataService.getClusterById(this.ClusterId).subscribe(res => {
      this.cluster = res;
      this.loaddistricts(this.cluster.StateId.toString());
      this.loadblocks(this.cluster.DistrictId.toString());
      this.isLoading = false;
    });
  }
  
  loadstates(): void {
    this.isLoading = true;
    this.states = [];
    this.districtId=0;
     this.stateDataService.getStates().subscribe(res => {
      this.states = res;
      this.states.sort(function (a, b) {
        var A = a.StateName.toUpperCase();
        var B = b.StateName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }
        
      });
      this.isLoading = false;      
    });
  }

  loaddistricts(value:string): void {   
     this.districts = [];
     this.isLoading = true;

     this.stateId = parseInt(value);
     if(this.cluster.ClusterId<=0)
     {
     this.blocks=[];
     this.districtId=0;
     }
     this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
     this.districts = res;
     this.isLoading = false;
     this.districts.sort(function (a, b) {
      var A = a.DistrictName.toUpperCase();
      var B = b.DistrictName.toUpperCase();
      let comparison = 0;
      if (A > B) {
        return  comparison = 1;
     } else if (A < B) {
        return comparison = -1;
      }
      
    });
    });
  }

    onOptionsSelected(event:any){
      this.loaddistricts(event.target.value);
      }

 onDistrictSelected(event:any){
  this.loadblocks(event.target.value);
  
}

  loadblocks(value:string): void {
    this.isLoading = true;

      this.blocks = [];
      this.districtId = parseInt(value);
      this.blockDataService.getBlockByDistId(this.districtId).subscribe(res => {
      this.blocks = res;
      this.isLoading = false;
      this.blocks.sort(function (a, b) {
        var A = a.BlockName.toUpperCase();
        var B = b.BlockName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }
        
      });
          
    });
  }

  


  validate(): string {
    this.message = "";
    if (this.cluster.ClusterName.trim() == "") {
      this.message = "Please enter Name.";
            return this.message ;
    }

    if (this.cluster.StateId == 0) {
      this.message = "Please select State.";
            return this.message ;
    }
    if (this.cluster.DistrictId == 0) {
      this.message = "Please select District.";
            return this.message ;
    }
    if (this.cluster.BlockId == 0) {
      this.message = "Please select Block.";
            return this.message ;
    }
     
    return this.message;
  }

  Save(): void{
    this.message = "";
    this.message = this.validate();
 
    if (this.message.length <= 0) {
      this.isSaving = false;
      this.cluster.CreatedBy=parseInt(this.userId);
      this.cluster.ModifiedBy=parseInt(this.userId);
      this.clusterDataService.Save(this.cluster).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
           // this.onClose.next(true);
           // this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;         
        },
        (error: any) => {
          this.message = `Error while saving Cluster`;
        },
      );
    }
    
  }
}
