import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoordinateTransportApprovalManageComponent } from "./coordinateTransportapproval-manage.component";
import { CoordinateTransportapprovalComponent } from "./coordinateTransportapproval.component";
//import { CoordinateapprovalManageQuComponent } from "./coordinateapproval-manage-qu.component";


const routes: Routes = [
    { path: "", component: CoordinateTransportApprovalManageComponent }
  //  { path: "", component: CoordinateapprovalComponent }        
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class CoordinateTransportapprovalRoutingModule { }
