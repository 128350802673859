import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { State } from "../Models/state";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";
import { Student } from "../Models/student";

@Injectable()
export class StateDataService {
    constructor(private http: HttpClient) {
        state: State;
    }

    public getTrainers(): Observable<Student[]> {
        return this.http.get<Student[]>(config.appUrl + 'Master/GetTrainers');
    }

    public getStates(): Observable<State[]> {
        return this.http.get<State[]>(config.appUrl + 'Master/GetStates');
    }
    public getStateById(id: number): Observable<State> {
        return this.http.get<State>(config.appUrl + '/state/' + id);
    }

    public Save(state: State): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/state',
            JSON.stringify(state),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<StateSaveResult> {
        return this.http.delete<StateSaveResult>(config.appUrl + '/state/' + id);


    }
}