import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { SchoolreportRoutingModule } from "./schoolreport-routing.module";
import { SchoolreportManageComponent } from "./schoolreport-manage.component";
import {SchoolManageQuComponent} from "../Reports/school-manage-qu.component"
//import { SchoolreportManageQuComponent } from "./coordinateapproval-manage-qu.component"
import{ SchoolreportComponent } from "./schoolreport.component"
import { GalleriaModule } from 'primeng/galleria';
import { ExcelService } from '../Services/ExcelService';
import {CalendarModule} from 'primeng/calendar';
import { DatePipe } from '@angular/common';
import { ImageViewerModule } from "ngx-image-viewer";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    DataTablesModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    CalendarModule,
    ConfirmationPopoverModule.forRoot({
    confirmButtonType: 'danger' // set defaults here
    }),
    SchoolreportRoutingModule,
    GalleriaModule,
    ImageViewerModule.forRoot()
      ],
  declarations: [
    SchoolreportComponent, 
    SchoolreportManageComponent,
    SchoolManageQuComponent
  ],
  exports: [
    SchoolreportComponent,
    SchoolreportManageComponent, 
    RouterModule
  ],
  providers: [MessageService,ExcelService,DatePipe],
  entryComponents: [SchoolManageQuComponent]
})
export class SchoolreportModule {}
