import { Component, Injectable, OnInit } from "@angular/core";
import { Survey } from "../Models/survey";
import { SurveyDataService } from "../Services/SurveyDataService";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MessageService } from "primeng/api";
import { StateSaveResult } from "../Models/stateSaveResult";
import { SurveyManageComponent } from "../Survey/survey-manage.component";
import { Student } from "../Models/student";
import { StudentDataService } from "../Services/StudentDataService";
import { StudentEditComponent } from "./student-edit/student-edit.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-student",
  templateUrl: "./student.component.html",
  styleUrls: ["./student.component.css"],
  providers: [StudentDataService],
})
export class StudentComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public surveys: Survey[];
  public students: any[];
  bsModalRef: BsModalRef;
  message: string;
  userName: string;
  userType: string;
  menuList: any[];
  displayFlag: string = "";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public surveyDataService: SurveyDataService,
    public studentDataService: StudentDataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.displayFlag = "";
    this.menuList = JSON.parse(localStorage.getItem("menuList"));
    let menul = this.menuList.find((e) => e.Menuname === "Survey"); //("Block");

    this.displayFlag = menul.Menuname;

    // this.loadSurveys();

    if (this.displayFlag == "Survey") {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.userName = currentUser.UserId;
      this.userType = currentUser.UserType;
      this.loadSurveys();
    }
  }

  refresh() {
    this.loadSurveys();
  }

  loadSurveys(): void {
    this.isLoading = true;
    // this.surveys = [];
    this.students = [];
    if (this.listFormatTableExists) {
      // this.surveys = [];
      this.students = [];
      this.listTable.destroy();
      this.listTable.clear();
    }

    this.studentDataService.getStudents().subscribe((res) => {
      this.students = res;
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          lengthMenu: [
            [10, 20, 40, 17 - 1],
            [10, 20, 40, 17, "All"],
          ],
          pageLength: 12,
          order: [[0, "desc"]],
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });

    //alert(surveyType);
    // this.surveyDataService.getSurveys(this.userType,'A').subscribe(res => {
    //   this.surveys = res;
    //   setTimeout(() => {
    //     this.listTable = $("#stateTable").DataTable({
    //       lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
    //       pageLength: 12,
    //       order: [[0, "desc"]]
    //     });
    //     this.listFormatTableExists = true;
    //     this.isLoading = false;
    //   }, 0);
    // });
  }

  onAdd() {
    this.router.navigate(["/studentAdd"]);
  }

  onEdit(id: Number) {
    const initialState = {
      title: "Edit Student",
      SurveyId: id,
    };
    console.log("initialState --->", initialState);
    this.router.navigate(["/studentEdit", id]);
  }

  onDelete(id: number) {
    // console.log("delete --->", id);
    this.surveyDataService.Delete(id).subscribe((data) => {
      let stateSaveResult1: StateSaveResult = data;
      if (stateSaveResult1.IsSuccessFull) {
        this.message = "";
        this.loadSurveys();
      } else {
        this.message = stateSaveResult1.ErrorMessage;
      }
    });
  }
}
