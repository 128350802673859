import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminTransportApprovalManageComponent } from "./adminTransportapproval-manage.component";
import { AdminTransportapprovalComponent } from "./adminTransportapproval.component";
//import { CoordinateapprovalManageQuComponent } from "./coordinateapproval-manage-qu.component";


const routes: Routes = [
    { path: "", component: AdminTransportApprovalManageComponent }
  //  { path: "", component: CoordinateapprovalComponent }        
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class AdminTransportapprovalRoutingModule { }
