import { OtherResources } from '../Models/OtherResources';

export class District {
    DistrictId:number;
    StateId:number;
    StateName:string;
    DistrictName: string;
    Comments: string;
    CollectorName: string;
    CollectorContactNo: string;
    ZPCEOName: string;
    ZPCEOContactNo: string;
    PrimaryDEOName: string;
    PrimaryDEOContactNo: string;
    SecondaryDEOName: string;
    SecondaryDEOContactNo: string;
    CivilSurgeonName: string;
    CivilSurgeonContactNo: string;
    DHOName: string;
    DHOContactNo: string;
    NTCPNodelOfficerName: string;
    NTCPNodalOfficerContactNo: string;
    RotaryClubName: string;
    RotaryClubContactNo: string;
    LionsClubName: string;
    LionsClubContactNo: string;
    OtherResources :OtherResources[];
    CreatedBy:number;
    ModifiedBy:number;
  }