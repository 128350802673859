import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { graphsreport } from "./graphsreport-manage.component";
import { GraphsreportComponent } from "./graphsreport.component";
//import { CoordinateapprovalManageQuComponent } from "./coordinateapproval-manage-qu.component";


const routes: Routes = [
    { path: "", component: GraphsreportComponent }
   // { path: "", component: SchoolreportComponent }        
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class GraphsreportRoutingModule { }
