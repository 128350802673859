import { Component, OnInit } from '@angular/core';
import { Question } from '../Models/question';
import { QuestionAns } from '../Models/questionAns';
import { QuestionAnsImageList } from '../Models/questionAnsImageList';
import { Subject } from 'rxjs';
import { StateSaveResult } from '../Models/stateSaveResult';
import { QuestionAnsDataService } from '../Services/QuestionAnsDataService';
import { QuestionDataService } from '../Services/QuestionDataService';
import { GalleriaModule } from 'primeng/galleria';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { parse } from 'querystring';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Router, ActivatedRoute } from "@angular/router";
import { stringify } from 'querystring';
//import htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { AnswerData } from '../Models/answerData';
declare var jquery: any;
declare var $: any;


@Component({
  selector: 'adminapproval-manage-qu',
  templateUrl: 'adminapproval-manage-qu.component.html',
  styleUrls: ['adminapproval-manage-qu.component.css']
})

export class AdminapprovalManageQuComponent implements OnInit {
  public rejections: any[];
  public questionAnsImageList: QuestionAnsImageList[];
  title: string;
  questionText: Question;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean = false;
  isLoading = false;
  QuesAnsId: number;
  questionId: number;
  quesAnsId: number;
  QuestionId: number;
  images: any[];
  questionAns: QuestionAns;
  //surveyId:number;
  //SchlId:number;
  surveyIdstr: string;
  quesAnsId1: number;
  SchlIdstr: string;
  readioSelected: number;
  rejectionId: number;
  SurveyId: number;
  StudentId: number;
  SchoolId: number;
  statusCode: string;
  orStatus: string;
  userName: number;
  menuList: any[];
  displayFlag: string = "";
  item: any;
  answerData: AnswerData[];
  //queList :QuestionAnsImageList;

  constructor(
    public questionAnsDataService: QuestionAnsDataService,
    public questionDataService: QuestionDataService,
    public galleriaModule: GalleriaModule,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.answerData = [];
    this.images = [];
    this.quesAnsId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("QuesAnsId"));

    this.questionId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("QuestionId"));

    this.message = "";
    this.onClose = new Subject();
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.UserId;

    this.displayFlag = "";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e => e.Menuname === 'Admin'); //("Block");
    this.displayFlag = menul.Menuname;
    if (this.displayFlag == 'Admin') {

      this.rejectionId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("RejectionId"));

      this.statusCode = (this.activatedRoute.snapshot.queryParamMap.get("StatusCode"));
      this.SchoolId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("SchoolId"));
      this.StudentId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("StudentId"));
      this.SurveyId = parseInt(this.activatedRoute.snapshot.queryParamMap.get("SurveyId"));
      this.orStatus = (this.activatedRoute.snapshot.queryParamMap.get("OStatus"));

      if (this.questionId != 0) {
        //this.fillData();
        this.fillSubQData();
        //this.fillImages();
      }
    }
  }

  fillData() {
    this.questionText = new Question();
    this.rejections = [];
    this.isLoading = true;
    this.questionDataService.getQuestionById(this.questionId, this.quesAnsId).subscribe(res => {
      this.questionText = res;
      this.isLoading = false;
    });

    /* this.questionAnsDataService.getRejectionList().subscribe(res => {
      this.rejections = res;

    });*/
    this.isLoading = false;
  }

  fillSubQData() {

    this.questionText = new Question();
    this.rejections = [];
    this.isLoading = true;
    this.questionDataService.GetQuestionsBySurveyAndQuestionId(this.SurveyId, this.StudentId, this.questionId).subscribe(res => {
      let element: any = res;
      element.selectedValue = '';
      element['firstImage'] = '';
      element['secondImage'] = '';
      element.isDisabled = false;

      if (element.StudentResponse.IsResponse == true) {
        element.isDisabled = true;
        // console.log("element.StudentResponse.ImagePath::"+element.StudentResponse.ImagePath);
        if (element.StudentResponse.ImagesResp.length > 0) {

          element['firstImage'] = (element.StudentResponse.ImagesResp[0] || element.StudentResponse.ImagesResp[0].ImagePath) ? element.StudentResponse.ImagesResp[0].ImagePath : '';
          this.images.push(element['firstImage']);
          // element['secondImage'] = (element.StudentResponse.ImagesResp[1] || element.StudentResponse.ImagesResp[1].ImagePath) ? element.StudentResponse.ImagesResp[1].ImagePath : '';
        }
        if (element.StudentResponse.ImagesResp.length > 1) {
          element['firstImage'] = (element.StudentResponse.ImagesResp[0] || element.StudentResponse.ImagesResp[0].ImagePath) ? element.StudentResponse.ImagesResp[0].ImagePath : '';
          element['secondImage'] = (element.StudentResponse.ImagesResp[1] || element.StudentResponse.ImagesResp[1].ImagePath) ? element.StudentResponse.ImagesResp[1].ImagePath : '';
          this.images.push(element['firstImage']);
          this.images.push(element['secondImage']);
        }
        if (element.QuestionType == 'SC') {
          element.QuestionOptionResponse.filter((item: any) => {
            if (item.AnswerId == element.StudentResponse.AnswerId) {
              element.selectedValue = item.AnswerName;
              if (element.ReferenceQuestions.length > 0) {
                for (let m = 0; m < element.ReferenceQuestions.length; m++) {
                  const innerSC = element.ReferenceQuestions[m];
                  if (innerSC.QuestionType == 'O') {
                    console.log("innerSC::" + JSON.stringify(innerSC.StudentResponse))
                    if (innerSC.StudentResponse.TextboxValue) {
                      let texArray = innerSC.StudentResponse.TextboxValue.split(",")
                      // texArray.forEach(text => {
                      //   $('.user-input-'+element.QuestionId).val(text)
                      // });
                      console.log("texArray::" + texArray.length)
                      for (let sc = 0; sc < texArray.length; sc++) {
                        let scTextValue = texArray[sc];
                        console.log("text:::" + scTextValue);
                        console.log("$('#user-inputinnerSC.QuestionId+sc)::+1" + $('#user-input-' + innerSC.QuestionId + "" + sc).val());
                        $('#user-input-' + innerSC.QuestionId + "" + sc).val(scTextValue);
                        this.answerData.push({ controlName: "user-input-" + innerSC.QuestionId + "" + sc, controlValue: scTextValue });
                        ;
                      }
                    }
                  }

                  if (innerSC.QuestionType == 'MC') {
                    console.log("innerSC.QuestionType:::" + innerSC.QuestionType);
                    if (innerSC.StudentResponse.AnswerId) {
                      let texArray = innerSC.StudentResponse.AnswerId.split(",")
                      for (let index = 0; index < texArray.length; index++) {
                        const innerMCValue = texArray[index];
                        console.log(`$("#mc-"+innerSC.QuestionId+"-"+innerMCValue)::` + $("#mc-" + innerSC.QuestionId + "-" + innerMCValue));
                        console.log("#mc-" + innerSC.QuestionId + "-" + innerMCValue);
                        console.log("current state: checked", $("#mc-" + innerSC.QuestionId + "-" + innerMCValue).is(':checked'));
                        $("#mc-" + innerSC.QuestionId + "-" + innerMCValue).attr('checked', true);
                        this.answerData.push({ controlName: "mc-" + innerSC.QuestionId + "-" + innerMCValue, controlValue: "true" });
                      }
                    }
                  }
                }
              }
            }
          })


        }
        if (element.QuestionType == 'O') {
          let texArray = element.StudentResponse.TextboxValue.split(",")
          // texArray.forEach(text => {
          //   $('.user-input-'+element.QuestionId).val(text)
          // });
          for (let sc = 0; sc < texArray.length; sc++) {
            let scTextValue = texArray[sc];
            console.log("text:::" + scTextValue);
            $('#user-input-' + element.QuestionId + "" + sc).val(scTextValue)
            this.answerData.push({ controlName: "user-input-" + element.QuestionId + "" + sc, controlValue: scTextValue });
            ;
          }
        }

        if (element.QuestionType == 'MC') {
          console.log("innerSC.QuestionType:::" + element.QuestionType);
          if (element.StudentResponse.AnswerId) {
            let texArray = element.StudentResponse.AnswerId.split(",")
            for (let index = 0; index < texArray.length; index++) {
              const innerMCValue = texArray[index];
              console.log(`$("#mc-"+innerSC.QuestionId+"-"+innerMCValue)::` + $("#mc-" + element.QuestionId + "-" + innerMCValue))
              $("#mc-" + element.QuestionId + "-" + innerMCValue).attr('checked', true)
              this.answerData.push({ controlName: "mc-" + element.QuestionId + "-" + innerMCValue, controlValue: "true" });
            }
          }
        }
      }
      this.item = element;
      console.log("subq", this.item);
    });

    /* this.questionAnsDataService.getRejectionList().subscribe(res => {
      this.rejections = res;

    });*/
    this.isLoading = false;
  }

  getValue(tag: string, questionId: any, answerId: any) {
    var name = tag + "-" + questionId + answerId;
    var checked = '';
    this.answerData.forEach(element => {
      if (element.controlName == name) {
        checked = element.controlValue;
      }
    });

    return checked;
  }

  fillImages() {
    this.questionAnsImageList = [];
    this.images = [];
    this.isLoading = true;
    this.questionAnsDataService.getQuestionImageList(this.quesAnsId).subscribe(res => {
      this.questionAnsImageList = res;
      this.images = [];
      for (let result of this.questionAnsImageList) {
        this.images.push(result.ImagePath);

      }

    });
    this.isLoading = false;
  }

  validate(status: string, rejectionIdform: number): string {
    this.message = "";
    if (status == 'AR' && (rejectionIdform <= 0 || (!isNumeric(rejectionIdform)))) {
      alert("Please select Rejection.");
      this.message = "Please Select Rejection.";
      return this.message;
    }
    return this.message;
  }

  public btnBack() {
    this.router.navigate(['/admineapproval-manage'], { queryParams: { QuesAnsId: this.QuesAnsId, StudentId: this.StudentId, SurveyId: this.SurveyId } });
  }

  onSave(status: string): void {
   
    this.message = "Status Changed";
    this.isSaving = true;
    if (status == 'A') {

      
      this.questionAnsDataService.SetapproveRejAnswer(status, this.quesAnsId).subscribe(
        data => {

          this.isSaving = false;
        },
        (error: any) => {
          this.message = `Error while saving Question`;
        },
      );
    }

    else if (status == 'R') {
      var rejectReason = (<HTMLInputElement>document.getElementById("rejectReason")).value
      if (rejectReason == "") {
        this.message = `Please enter rejection reason`;
      }
      else {
        this.questionAnsDataService.RejectAnswers(status, this.quesAnsId, rejectReason).subscribe(
          data => {

            this.isSaving = false;
          },
          (error: any) => {
            this.message = `Error while saving Question`;
          },
        );

      }

    }



  }

}
