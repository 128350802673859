export class Student {
  StudentId: number;
  FirstNam: string;
  LastName: string;
  DesignationId: number;
  SchoolId: number;
  GradeId: number;
  AcademicYear: string;
  Gender: string;
  MobileNo: number;
  MediumOfSchool: string;
  Address1: string;
  Address2: string;
  PinCode: number;
  IsMigrated: false;
  MigrationState: string;
  Email: string;
  CommunityAddress: string;
  ImagePath: string;
  TrainerName: string;
  }
