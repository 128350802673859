import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {RouterModule} from '@angular/router';

import { HeaderComponent } from "./header.component";

@NgModule({
    imports:[
        RouterModule,
        CommonModule,
    ],
    declarations:[
        HeaderComponent
    ],
    exports:[
        HeaderComponent,
        RouterModule
    ]
})

export class HeaderModule{

}