import { Component, OnInit } from '@angular/core';
import { Block } from '../Models/block';
import { District } from '../Models/district';
import { State } from '../Models/state';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { DistrictDataService } from '../Services/DistrictDataService';
import { StateDataService } from '../Services/StateDataService';
import { BlockDataService } from '../Services/BlockDataService';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: 'block-manage',
  templateUrl: 'block-manage.component.html'
})

export class BlockManageComponent implements OnInit {
  block: Block;
  public states: State[];
  public districts: District[];
  title: string;
  id:number;
  BlockId:number;
  stateId:number;
  closeBtnName: string;
  userId: string;
  message: string;
  hasEditRights: boolean;
  public onClose: Subject<boolean>;
  isSaving: boolean= false;
  isLoading = false;

  constructor(public bsModalRef: BsModalRef, 
    public blockDataService : BlockDataService,
    public districtDataService : DistrictDataService,
    public stateDataService : StateDataService) {this.loadstates();//this.loaddistricts();
     }

  ngOnInit() {
    this.message = "";
    this.onClose = new Subject();    
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = currentUser.UserId;

    if(this.BlockId==0)
    {
      this.block = new Block();
      this.block.BlockName = "";
      this.block.StateId=0;
      this.block.StateName="";
      this.block.DistrictName="";
      this.block.BEOName="";
      this.block.BEOContactNo="";
      this.block.ExtensionOfficerName="";
      this.block.ExtensionOfficerContactNo="";
      this.block.DistrictId=0;
      
    }
    else{
      this.fillBlockById();
    }
    
    
  }
  
  Clear() : void{
    this.message = "";
    this.onClose = new Subject(); 
    this.block = new Block();
    this.block.BlockName = "";
    this.block.StateId=0;
    this.block.StateName="";
    this.block.DistrictName="";
    this.block.BEOName="";
    this.block.BEOContactNo="";
    this.block.ExtensionOfficerName="";
    this.block.ExtensionOfficerContactNo="";
    this.block.DistrictId=0;
  }

  fillBlockById() {
    this.isLoading = true;
    this.block = new Block();
    this.blockDataService.getBlockById(this.BlockId).subscribe(res => {
      this.block = res;
      this.loaddistricts(this.block.StateId.toString()); 
      this.isLoading = false;
    });
  }
  
  loadstates(): void {
    this.isLoading = true;
    this.states = [];
     this.stateDataService.getStates().subscribe(res => {
      this.states = res;
      this.isLoading = false;    
      this.states.sort(function (a, b) {
        var A = a.StateName.toUpperCase();
        var B = b.StateName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }
        
      });  
    });
  }

  onOptionsSelected(event:any){
    this.loaddistricts(event.target.value);
    }
  loaddistricts(value:string): void {
    this.isLoading = true;
    this.districts = [];
    this.stateId = parseInt(value);
     this.districtDataService.getDistrictByStateId(this.stateId).subscribe(res => {
      this.districts = res;
      this.districts.sort(function (a, b) {
        var A = a.DistrictName.toUpperCase();
        var B = b.DistrictName.toUpperCase();
        let comparison = 0;
        if (A > B) {
          return  comparison = 1;
       } else if (A < B) {
          return comparison = -1;
        }
        
      });
      this.isLoading = false;      
    });
  }
 

  validate(): string {
    this.message = "";
    if (this.block.BlockName.trim() == "") {
      this.message = "Please enter Name.";
            return this.message ;
    }

    if (this.block.StateId == 0) {
      this.message = "Please select State.";
            return this.message ;
    }
    if (this.block.DistrictId == 0) {
      this.message = "Please select District.";
            return this.message ;
    }
   
    return this.message;
  }

  Save(): void{
    this.message = "";
    this.message = this.validate();
 
    if (this.message.length <= 0) {
      this.isSaving = false;
      this.block.CreatedBy=parseInt(this.userId);      
      this.block.ModifiedBy=parseInt(this.userId);

      this.blockDataService.Save(this.block).subscribe(
        data => {
          let stateSaveResult: StateSaveResult = data;
          if (stateSaveResult.IsSuccessFull) {
            this.message = "";
            this.onClose.next(true);
            this.bsModalRef.hide();
          }
          else {
            this.message = stateSaveResult.ErrorMessage;
          }
          this.isSaving = false;         
        },
        (error: any) => {
          this.message = `Error while saving District`;
        },
      );
    }
    
  }
}
