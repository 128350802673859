import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }
  // Excel exporter
  public exportAsExcelFile(json: any[],filterjson: any[], excelFileName: string, headersArray: any[],filterHeaderArray:any []): void {
    //Excel Title, Header, Data
    const header = headersArray;
    const filterHeader=filterHeaderArray;
   
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(excelFileName);
   
    let filterHeaderRow = worksheet.addRow(filterHeader);
    filterHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = { bold: true };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

      var res = JSON.parse(JSON.stringify(filterjson[1])); 
      var vals = Object.values(res);
      worksheet.addRow(vals);
      worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.font = { bold: true };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    let row;
    let arr=[];
    
    let vals1:any[];
    for (let i = 1; i < json.length; i++) {
      var res = JSON.parse(JSON.stringify(json[i])); 
       vals1 = Object.values(res);
       row = worksheet.addRow(vals1);
    }
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, excelFileName + '_export_' +  new Date().getMonth()+1+new Date().getDate()+new Date().getFullYear() + EXCEL_EXTENSION);
    })
  }
}