import { QuestionTypeAnsOption } from '../Models/questionTypeAnsOption';
export class Question {
    QuestionId:number;
    SurveyId:number;
    SurveyName:string;
    Question: string;
    QMarathi: string;
    QHindi: string;
    QuestionOrder:number;
    Status:string;
    Weightage:number;
    Comments: string;
    CreatedBy:number;
    ModifiedBy:number;
    ManualType:string;
    QuestionType:string;
    Answer:string;
    AnswerHindi:string;
    PlaceHolderNameHindi:string;
    PlaceHolderName:string;
    TextboxValue:string;
    RejectReason:string;
    questionTypeAnsOption : QuestionTypeAnsOption[];
  }