import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Survey } from "../Models/survey";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { Student } from "../Models/student";

@Injectable()
export class MasterDataService {
  constructor(private http: HttpClient) {

  }
  public getSchools(): Observable<any[]> {
      return this.http.get<any[]>(config.appUrl + '/Master/GetSchools');
  }

  public getGrades(): Observable<any[]> {
    return this.http.get<any[]>(config.appUrl + '/Master/GetGrades');
  }

  public getStates(): Observable<any[]> {
    return this.http.get<any[]>(config.appUrl + '/Master/GetStates');
  }

  // public getDistrict(): Observable<any[]> {
  //   return this.http.get<any[]>(config.appUrl + '/Master/GetDistrict');
  // }

  public getDistrict(stateId: any): Observable<any[]> {
    return this.http.get<any[]>(config.appUrl + '/Master/GetDistrict/' + stateId);
  }

  public getBlock(): Observable<any[]> {
    return this.http.get<any[]>(config.appUrl + '/Master/GetBlock');
  }

  public getCity(): Observable<any[]> {
    return this.http.get<any[]>(config.appUrl + '/Master/GetCity');
  }

  public getDesignations(): Observable<any[]> {
    return this.http.get<any[]>(config.appUrl + '/Master/GetDesignations');
  }

  public getDashboardCount(dashboardData: any): Observable<any[]> {
    let resp: Observable<any> = null;
    const httpOptions = {
      headers: new HttpHeaders({
          "Content-Type": "application/json"
      })
    };
    resp = this.http.post<any>(
      config.appUrl+ '/Master/GetDashboardCount',
      JSON.stringify(dashboardData),
      httpOptions
  );
  return resp;
    // return this.http.get<any[]>(config.appUrl + '/Master/GetDashboardCount');
  }
}
