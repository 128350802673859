import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/Http';
import { map } from 'rxjs/operators';
import config from '../config';
import { UserApp } from "src/app/Models/userApp";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(username: string, password: string) {
        return this.http.post<any>(`${config.AuthUrl}/authenticate`, { username, password })
            .pipe(map(user => {
                //alert("test");
                // login successful if there's a jwt token in the response
                if (user && user.Token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));
    }

    loginApp(username: string, password: string) {
        let isAdmin = true;
       return this.http.post<any>(`${config.AuthUrlApp}/authenticate`, { username, password,isAdmin })
            .pipe(map(user => {

                console.log(user);
                 if (user && user.Token) {
                    //alert(user.Token);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));

    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}
