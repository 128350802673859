import { Component, Injectable, OnInit } from "@angular/core";
import { UserApp } from '../Models/userApp';
import { UserDataService } from "../Services/UserDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserManageComponent } from './user-manage.component';
import { MessageService } from 'primeng/api';
import { StateSaveResult } from '../Models/stateSaveResult';

@Component({
  selector: "user",
  templateUrl: "./user.component.html"
})
@Injectable()
export class UserComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public userApp: UserApp[];
  bsModalRef: BsModalRef;
  message: string;
  menuList:any[];
  displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public userDataService: UserDataService
  ) { }

  ngOnInit() {
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    let menul = this.menuList.find(e=>e.Menuname==='User'); //("Block");  
    this.displayFlag =menul.Menuname;
   
    if(this.displayFlag =='User')
    {
      this.loadUsers();
    }
  }

  refresh() {
    this.loadUsers();
  }

  loadUsers(): void {
    this.isLoading = true;
    this.userApp = [];
    if (this.listFormatTableExists) {
      this.userApp = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
   // alert('Hi')
    this.userDataService.getUsers().subscribe(res => {
      this.userApp = res;
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[2, "desc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onAdd(){
    const initialState = {
      title: "Add a new User",
      UserId:0,
      Mode:"I"
    };
    this.bsModalRef = this.modalService.show(UserManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadUsers();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit User",
      UserId: id,
      Mode:"E"
    };
    this.bsModalRef = this.modalService.show(UserManageComponent, {
      class: "my-modal",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadUsers();
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  
}
