import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Question } from "../Models/question";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";

@Injectable()
export class QuestionDataService {
    constructor(private http: HttpClient) {
        question: Question;
    }
    public getQuestions(surveyType : string): Observable<Question[]> {
        return this.http.get<Question[]>(config.appUrl + '/Questions/GetQuestions/' + surveyType);
    }
    public getQuestionById(id: number,quesAnsId:number): Observable<Question> {

        return this.http.get<Question>(config.appUrl + '/UserApp/GetQuestions/' + id+'/'+quesAnsId);
    }
    public getQuestionByIdSchoolId(id: number,schoolId:number): Observable<Question> {
        return this.http.get<Question>(config.appUrl + '/Questions/GetQuestionsIdandSchoolId/' + id+'/'+schoolId);
    }

    public GetQuestionsBySurveyAndQuestionId(surveyId: number,studentId:number,questionId:number): Observable<Question> {
      return this.http.get<Question>(config.appUrl + '/Questions/GetQuestionsBySurveyAndQuestionId/' + surveyId +'/'+ studentId+'/'+ questionId);
    }

    public Save(question: Question): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/questions',
            JSON.stringify(question),
            httpOptions
        );
        return resp;
    }

    public SaveDynamic(question: Question): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/questions/SaveDynamic',
            JSON.stringify(question),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<Object> {
        return this.http.delete<Object>(config.appUrl + '/questions/' + id);


    }
}
