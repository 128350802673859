export class ReportParameters{
    status:string;
    stateid :number;
    DistrictId:number;
    BlockId :number;
    ClusterId :number;
    SurveyId :number;
    userType:string; 
    frmDate:Date;
    toDate:Date;
}
