import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cluster } from "../Models/cluster";
import { HttpClient, HttpHeaders } from "@angular/common/Http";
import config from '../config';
import { StateSaveResult } from "src/app/Models/StateSaveResult";

@Injectable()
export class ClusterDataService {
    constructor(private http: HttpClient) {
        cluster: Cluster;
    }
    public getClusters(): Observable<Cluster[]> {
        return this.http.get<Cluster[]>(config.appUrl + '/Cluster');
    }
    public getClusterById(id: number): Observable<Cluster> {
        return this.http.get<Cluster>(config.appUrl + '/Cluster/' + id);
    }

    public getClusterByblockId(blockId: number): Observable<Cluster[]> {
        return this.http.get<Cluster[]>(config.appUrl + '/Cluster/GetClusterByblockId/' + blockId);
    }

    
    public Save(cluster: Cluster): Observable<StateSaveResult> {
        let resp: Observable<StateSaveResult> = null;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        resp = this.http.post<StateSaveResult>(
            config.appUrl+ '/cluster',
            JSON.stringify(cluster),
            httpOptions
        );
        return resp;
    }

    public Delete(id: number): Observable<StateSaveResult> {
        return this.http.delete<StateSaveResult>(config.appUrl + '/cluster/' + id);


    }
}