export class UserApp {    
   UserId: number;
   RoleId: number;
   RoleName:string;
   FirstName : string;
   LastName : string;
   Email : string;
   Password : string;
   Comments : string;
   Status : string;
   CreatedBy: number;
   ModifiedBy: number;
   UserType:string;
   UserTypeText:string;
   StateId : number;
   DistrictId : number;
   UserName : string;
   IsAdmin:boolean;
  }