import { Component, Injectable, OnInit } from "@angular/core";
import { Question } from '../Models/question';
import { QuestionDataService } from "../Services/QuestionDataService";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuestionManageComponent } from './question-manage.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: "question",
  templateUrl: "./question.component.html"
})
@Injectable()
export class QuestionComponent implements OnInit {
  Id: number;
  isLoading: boolean = false;
  listTable;
  listFormatTableExists: boolean = false;
  public questions: Question[];
  bsModalRef: BsModalRef;
  userName:string;
  userType:string;
  menuList:any[];
    displayFlag:string ="";

  constructor(
    private modalService: BsModalService,
    private messageService: MessageService,
    public questionDataService: QuestionDataService
  ) { }

  ngOnInit() {    
    this.displayFlag ="";
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
     let menul = this.menuList.find(e=>e.Menuname==='Question'); //("Block");  
     this.displayFlag =menul.Menuname;

     if(this.displayFlag =='Question')
     {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.userName = currentUser.UserId;
        this.userType = currentUser.UserType;
        this.loadQuestions(this.userType);
     }
  }

  refresh() {
    this.loadQuestions(this.userType);
  }

  loadQuestions(userTypeData :string): void {
    this.isLoading = true;
    this.questions = [];
    if (this.listFormatTableExists) {
      this.questions = [];
      this.listTable.destroy();
      this.listTable.clear();
    }
    this.questionDataService.getQuestions(userTypeData).subscribe(res => {
      this.questions = res;
      setTimeout(() => {
        this.listTable = $("#stateTable").DataTable({
          lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
          pageLength: 12,
          order: [[1, "asc"],[2, "asc"]]
        });
        this.listFormatTableExists = true;
        this.isLoading = false;
      }, 0);
    });
  }

  onAdd(){
    const initialState = {
      title: "Add a new Question",
      QuestionId:0
    };
    this.bsModalRef = this.modalService.show(QuestionManageComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadQuestions(this.userType);
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onEdit(id : Number){
    const initialState = {
      title: "Edit Question",
      QuestionId: id
    };
    this.bsModalRef = this.modalService.show(QuestionManageComponent, {
      class: "modal-dialog modal-lg",
      initialState
    });

    this.bsModalRef.content.onClose.subscribe(result => {
      this.loadQuestions(this.userType);
    });

    this.bsModalRef.content.closeBtnName = "Close";
  }

  onDelete(id: number) {
    this.questionDataService.Delete(id).subscribe(
      data => {
        this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "Question deleted successfully!"
        });
        this.loadQuestions(this.userType);
      },
      (error: any) => {       
        if (error.status === 409) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `State cannot be deleted as it is referenced in some task parameter; Please remove the referance to delete it.`
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `Error while deleting the Question`
          });
        }
      }
    );
  }
}
